import { Activity, ActivityConnection, ActivityConnectionInput, SortDirectionEnum } from '../../../models/gen/graphql';
import { ConnectionDetails, QueryInputType, convertConnectionToDetails, handleError, queryInput } from '../../../utils/custom';

import { GraphApiResponse } from '../../core';
import { SearchActivityDocument } from '@/api/queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchActivityGraphApiResponse = GraphApiResponse<typeof SearchActivityDocument>;
export const searchActivityResponseSelector = (res: SearchActivityGraphApiResponse): ConnectionDetails<Activity> =>
  convertConnectionToDetails(res?.searchActivity?.activityConnection as ActivityConnection);

const [, runRefetchSearchActivity] = graphApi(SearchActivityDocument, {
  onError: (res: SearchActivityGraphApiResponse): void => handleError(res, { notification: { title: 'Search Activity' } }),
});

export const getActivityByTripId = async (tripId: string, timezoneOffset?: string): Promise<Activity[]> => {
  const input: ActivityConnectionInput = {
    first: 1000,
    after: null,
    query: [
      {
        tripId: queryInput(tripId),
        createdAt: queryInput(null, QueryInputType.DEFAULT, SortDirectionEnum.Desc),
      },
    ],
    offset: timezoneOffset,
  };

  const res = await runRefetchSearchActivity({ input });
  const conn = searchActivityResponseSelector(res);

  return conn.rows;
};
export const useGetActivityForTrip = createGraphApiHook(getActivityByTripId);
