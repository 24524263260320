import { TrackFlightTypeEnum, Trip } from '@/models/gen/graphql';
import { Datetime, getClasses, zeroPadFlightNumber } from '@/utils';
import React from 'react';
import { isUpcomingTrip } from '@/features/AirlineTrips/components/TripsTable/utils';
import ActualCell from '@/features/AirlineTrips/components/TripsTable/Cells/ActualTimeCell';
import { DATE_FE_FORMAT_SHORT } from '@/constants';
import LocationCell from '@/features/AirlineTrips/components/TripsTable/Cells/LocationCell';
import useAirlineTripTableState from '@/features/AirlineTrips/components/TripsTable/hook';
import useAirlineTripSettings, { AirlineColumnKeyEnum } from '@/features/AirlineTrips/components/TripSettingsModal/hook';

type AirlineTripsTableRowProps = {
  rowId: string;
  index: number;
  style?: React.CSSProperties;
};
const AirlineTripsTableRow = (
  { rowId, style, ...props }: AirlineTripsTableRowProps,
  ref: React.ForwardedRef<HTMLTableRowElement>
): React.JSX.Element => {
  const columns = useAirlineTripSettings(({ state }) => state.columns);
  const data = useAirlineTripTableState(({ state }) => state.trips.get(rowId) || ({} as Trip));

  const scheduledDatetime = new Datetime(data.scheduled);
  const scheduledUtcDatetime = new Datetime(data.scheduledUtc);
  const flightNumber = zeroPadFlightNumber(data.flightNumber);

  return (
    <div
      {...props}
      ref={ref}
      style={style}
      className={getClasses(
        'table-row',
        data.kind,
        data.type,
        data.status,
        data.isLate ? 'LATE' : '',
        isUpcomingTrip(scheduledUtcDatetime) ? 'UPCOMING' : '',
        data.state?.completion ? 'COMPLETED' : '',
        data.deletedAt ? 'DELETED' : '',
        data.curbsideAt ? 'CURBSIDE' : ''
      )}
    >
      {columns.get(AirlineColumnKeyEnum.TYPE) && <div className="table-cell w-md-fixed">{data.type || '--'}</div>}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_DATE) && (
        <div className="table-cell w-sm-grow">{scheduledDatetime.format(DATE_FE_FORMAT_SHORT)}</div>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_TIME) && <div className="table-cell w-sm-grow">{scheduledDatetime.time || '--'}</div>}
      {columns.get(AirlineColumnKeyEnum.ACTUAL) && (
        <div className="table-cell flex-column gap-1 w-sm-grow">
          <ActualCell
            actual={data.trackFlight?.actual}
            arrivalGate={data.trackFlight?.arrivalGate}
            arrivalTerminal={data.trackFlight?.arrivalTerminal}
            label={data.trackFlight?.label}
            kind={data.kind}
            scheduled={data.scheduled}
          />
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.AIRPORT_CODE) && <div className="table-cell w-md-fixed">{data.airportCode || '--'}</div>}
      {columns.get(AirlineColumnKeyEnum.IATA_CODE) && <div className="table-cell w-sm-fixed">{data.servicerIataAirlineCode || '--'}</div>}
      {columns.get(AirlineColumnKeyEnum.FLIGHT_NUMBER) && (
        <div
          className={`table-cell ${data.trackFlight?.type === TrackFlightTypeEnum.International ? 'bg-purple bg-opacity-15 text-dark' : undefined}`}
        >
          {data.loopName || flightNumber}
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.PILOT) && <div className="table-cell w-sm-fixed">{data.pilots || 0}</div>}
      {columns.get(AirlineColumnKeyEnum.ATTENDANT) && <div className="table-cell w-sm-fixed">{data.attendants || 0}</div>}
      {columns.get(AirlineColumnKeyEnum.PU_LOCATION) && (
        <div className="table-cell w-lg-grow">
          <LocationCell
            name={data.puLocation?.name}
            address={data.puLocation?.address}
            stateCode={data.puLocation?.stateCode}
            zipCode={data.puLocation?.zipCode}
            cityName={data.puLocation?.cityName}
            phoneNumber={data.puLocation?.phoneNumber}
            id={data.puLocationId}
          />
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.DO_LOCATION) && (
        <div className="table-cell w-lg-grow">
          <LocationCell
            name={data.doLocation?.name}
            address={data.doLocation?.address}
            stateCode={data.doLocation?.stateCode}
            zipCode={data.doLocation?.zipCode}
            cityName={data.doLocation?.cityName}
            phoneNumber={data.doLocation?.phoneNumber}
            id={data.doLocationId}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(React.forwardRef(AirlineTripsTableRow));
