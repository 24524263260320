import { useCallback, useEffect, useRef } from 'react';

import useDelayCallback from '@/hooks/useDelayCallback';

const DEFAULT_CLICK_DELAY = 500;
const useDebouncedClick = (onClick: (event: React.MouseEvent, clickCount: number, resetCount: () => void) => void) => {
  const clickCount = useRef(0);

  const [runClick] = useDelayCallback(onClick, DEFAULT_CLICK_DELAY);

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      // Increment the click count
      clickCount.current += 1;
      runClick(event, clickCount.current, (): void => {
        clickCount.current = 0;
      });
    },
    [runClick, clickCount.current]
  );

  // Clear the click count when the component unmounts
  useEffect((): (() => void) => {
    return () => (clickCount.current = 0);
  }, []);

  return handleClick;
};

export default useDebouncedClick;
