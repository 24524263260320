import { InvoiceFormatEnum, InvoiceTypeTypeEnum, TripStatusEnum } from '../../models/gen/graphql';
import React, { useRef } from 'react';
import { createNotification, isDateRangeWithinThreshold } from '@/utils';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import DateRangePicker from '@/components/DateRangePicker';
import EnumDropdown from '@/components/EnumDropdown';
import Filters from '../../components/Filters';
import FormButton from '../../components/FormButton';
import HasPermission from '@/components/HasPermission';
import { ParsedInvoiceInput } from '../../api/services/invoices';
import { Toast } from '@/models';
import equal from 'fast-deep-equal/es6/react';

type FiltersProps = {
  value: Partial<ParsedInvoiceInput>;
  onSubmit: (values: any) => Promise<any>;
};
type FilterSegmentProps = {
  values: {
    payerProviderId: string;
    iataAirlineCodes: string[];
    airports: string[];
    types: string[];
    startDatetime: string;
    endDatetime: string;
    format: string;
    tripStatus: string[];
  };
  onChange: any;
};
type FiltersValidityCheck = { payerProviderId: string };
const FormattedInvoiceTypeTypeEnum = Object.entries(InvoiceTypeTypeEnum).reduce(
  (acc, [key, value]) => ({ ...acc, [key.toUpperCase()]: value }),
  {}
);
const InvoicePreviewFilters = ({ value = {}, onSubmit }: FiltersProps): JSX.Element => {
  const lastQuery = useRef<Partial<ParsedInvoiceInput>>(value);

  const handleSubmit = (values: any): void => {
    if (!isDateRangeWithinThreshold(values?.startDatetime, values?.endDatetime, 30)) {
      createNotification('Please select a date range no more than 30 days.', Toast.Type.WARNING, 'Invalid Date Range');
      return;
    }
    lastQuery.current = values;
    onSubmit(values);
  };

  return (
    <Filters
      name="invoicePreviewFilters"
      onSubmit={handleSubmit}
      value={value}
      isValid={({ payerProviderId }: FiltersValidityCheck): boolean => !!payerProviderId}
      primary={({
        values: { payerProviderId, iataAirlineCodes, airports, types, startDatetime, endDatetime },
        onChange,
      }: FilterSegmentProps): JSX.Element => (
        <>
          <div className="InvoiceDateRange">
            <DateRangePicker
              name="dates"
              isDirty={startDatetime !== lastQuery.current.startDatetime || endDatetime !== lastQuery.current.endDatetime}
              isInvalid={!isDateRangeWithinThreshold(startDatetime, endDatetime, 30)}
              value={[startDatetime, endDatetime] as any}
              onChange={(event) => {
                const [startDatetime, endDatetime] = event.target.value?.split?.(' - ') || [null, null];
                onChange({ target: { name: 'startDatetime', value: startDatetime } });
                onChange({ target: { name: 'endDatetime', value: endDatetime } });
              }}
            />
          </div>
          <ClientDropdown
            name="payerProviderId"
            value={payerProviderId}
            isInvalid={!payerProviderId}
            onChange={(value) => onChange({ target: { name: 'payerProviderId', value } })}
            options={{ locale: { 'Select...': 'Client' } }}
            style={payerProviderId !== lastQuery.current.payerProviderId ? { borderColor: 'var(--bs-secondary)' } : undefined}
          />
          <AirlineIataDropdown
            name="iataAirlineCodes"
            value={iataAirlineCodes}
            onChange={(value) => onChange({ target: { name: 'iataAirlineCodes', value } })}
            options={{ locale: { 'Select...': 'Airline' } }}
            style={iataAirlineCodes !== lastQuery.current.iataAirlineCodes ? { borderColor: 'var(--bs-secondary)' } : undefined}
          />
          <AirportGroupDropdown
            name="airports"
            value={airports}
            onChange={(value) => onChange({ target: { name: 'airports', value } })}
            options={{ locale: { 'Select...': 'All Airports' } }}
            style={!equal(airports, lastQuery.current.airports) ? { borderColor: 'var(--bs-secondary)' } : undefined}
          />
          <EnumDropdown.Multi
            enum={FormattedInvoiceTypeTypeEnum}
            name="types"
            value={types}
            onChange={(value) => onChange({ target: { name: 'types', value } })}
            options={{ locale: { 'Select...': 'All Types' } }}
            style={!equal(types, lastQuery.current.types) ? { borderColor: 'var(--bs-secondary)' } : undefined}
          />
        </>
      )}
      alternate={(): JSX.Element => (
        <>
          <FormButton
            name="INVOICE_HISTORY"
            icon={<i className="sv sv-invoice-history {font-size:1.5rem;}" />}
            className="{white-space:nowrap!;}"
            variant="outline-gray"
            to="/invoices"
          >
            Invoice History
          </FormButton>
        </>
      )}
      secondary={({ values: { format, tripStatus }, onChange }: FilterSegmentProps): JSX.Element => (
        <>
          <EnumDropdown
            enum={{ 'Include Crew ID': InvoiceFormatEnum.Crew }}
            name="format"
            value={format}
            onChange={(value) => onChange({ target: { name: 'format', value } })}
            options={{ locale: { 'Select...': 'Without Crew ID' }, showClearButton: false }}
            style={format !== lastQuery.current.format ? { borderColor: 'var(--bs-secondary)' } : undefined}
          />
          <HasPermission name="allowTripStatusInvoiceFilter">
            <EnumDropdown
              enum={{ 'Exclude Cancelled': TripStatusEnum.Active }}
              name="tripStatus"
              value={tripStatus}
              onChange={(value) => onChange({ target: { name: 'tripStatus', value } })}
              options={{ locale: { 'Select...': 'All Statuses' } }}
              style={tripStatus !== lastQuery.current.tripStatus ? { borderColor: 'var(--bs-secondary)' } : undefined}
            />
          </HasPermission>
        </>
      )}
    />
  );
};

export default InvoicePreviewFilters;
