import React, { useCallback, useEffect, useRef } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableRow } from '@/components/VirtualTable';

import { Datetime } from '@/utils';
import EditModal from '@/components/EditModal/new';
import { Trip } from '@/models/gen/graphql';
import { createComponentState } from '@/state';
import { getClasses } from '@/utils/strings';
import useTrips from '@/hooks/useTrips';

type EditCombineModalState = {
  show?: boolean;
  loading?: boolean;
  tripId?: string;
  combineId?: string;
  scheduled?: string;
  servicerIataAirlineCode?: string;
  flightNumber?: number;
  selected?: string[];
  initSelected?: string[];
  unCombined?: string[];
  onSubmit?: (data: any) => Promise<void>;
  onHide?: () => void;
};
const initEditCombineModalState: EditCombineModalState = {
  show: false,
  loading: false,
  tripId: undefined,
  combineId: undefined,
  scheduled: undefined,
  servicerIataAirlineCode: undefined,
  flightNumber: undefined,
  selected: [],
  initSelected: [],
  unCombined: [],
  onSubmit: async (): Promise<void> => {},
  onHide: (): void => {},
};

export const useEditCombineModal = createComponentState(initEditCombineModalState);

// TODO: Find/use the type generated in GraphQL.
type UncombineTripsPayload = { id: string; combineId: [null]; driverId: [null]; vehicleId: [null] };

const EditCombineModal = (): JSX.Element => {
  const [state, setState] = useEditCombineModal(({ state, setState }) => [state, setState]);
  const {
    show = false,
    tripId = '',
    combineId = '',
    selected = [],
    initSelected = [],
    unCombined = [],
    onSubmit = async () => undefined,
    onHide = () => setState(initEditCombineModalState),
  } = state;
  const [tripState, { searchCombinableTrips, handleSubmitCombine, refetchCombinableTrips }, { loading }] = useTrips();
  const { combineResults: { rows = [], totalCount = 0 } = {} } = tripState;
  const lastTripId = useRef(null);

  const handleSubmit = useCallback(async (): Promise<void> => {
    const combineTripIds = state?.selected?.filter?.((id: string): boolean => !initSelected.includes(id)) || [];
    if (combineTripIds.length > 0) combineTripIds.push(tripId);
    const unCombinePayload =
      state?.unCombined?.map?.(
        (id: string): UncombineTripsPayload => ({
          id,
          combineId: [null],
          driverId: [null],
          vehicleId: [null],
        })
      ) || [];
    const payload = { updates: { combineTripIds, unCombinePayload }, ...state };
    await handleSubmitCombine(payload);
    onSubmit?.(state);
    setState({ show: false });
  }, [state, tripId, handleSubmitCombine, onSubmit, setState, initSelected]);

  const onSetCombine = (clickedId: string | string[]): void => {
    return setState((current: EditCombineModalState): EditCombineModalState => {
      let newSelected = [...selected];
      let newUncombined = [...unCombined];

      // Check if 'clickedId' is an array and matches the length of 'selected' for 'select all' or 'deselect all'
      const isSelectAll = Array.isArray(clickedId) && clickedId.length !== selected?.length;
      const isDeselectAll = Array.isArray(clickedId) && clickedId.length === selected?.length;
      if (isSelectAll) {
        // Select all items: Replace 'newSelected' with all ids, and clear 'newUncombined'
        newSelected = clickedId;
        newUncombined = initSelected.filter((id) => !clickedId.includes(id));
      } else if (isDeselectAll) {
        // Deselect all items: Clear 'newSelected', and reset 'newUncombined' to 'initSelected'
        newSelected = [];
        newUncombined = [...initSelected];
      } else if (typeof clickedId === 'string') {
        if (newSelected.includes(clickedId)) {
          // remove this item from the list of selected items
          newSelected = newSelected.filter((id: string): boolean => id !== clickedId);
          // only add item to uncombined list if the item was one of the original items
          if (initSelected.includes(clickedId) && !newUncombined.includes(clickedId)) newUncombined.push(clickedId);
          // if a non-selected item is clicked
        } else {
          // add the item to the list of selected items
          newSelected.push(clickedId);
          // only remove this item from uncombined list if it was one of the original items
          if (initSelected.includes(clickedId)) newUncombined = newUncombined.filter((id: string): boolean => id !== clickedId);
        }
      }
      return {
        ...current,
        selected: [...newSelected],
        unCombined: [...newUncombined],
      };
    });
  };

  const getCombinableTrips = useCallback(async (): Promise<void> => {
    const fn = lastTripId.current === tripId ? refetchCombinableTrips : searchCombinableTrips;
    lastTripId.current = tripId;
    const response = await fn([{ id: tripId }]);
    const selected = ((response?.rows || [])?.filter((node: Trip): boolean => (node.combineId || '') === combineId) || []).map(
      (node: Trip): string => node.id
    );
    if (selected.length) {
      setState(
        (current: EditCombineModalState): EditCombineModalState => ({
          ...current,
          selected,
          initSelected: selected,
        })
      );
    }
  }, [tripId, combineId, searchCombinableTrips, refetchCombinableTrips, setState]);

  useEffect((): void => {
    if (!tripId) return;
    getCombinableTrips();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const scheduled = state?.scheduled ? new Datetime(state?.scheduled).frontendDatetimeShort : '';
  const airlineCode = (state?.servicerIataAirlineCode || '').toUpperCase();
  const flightNumber = state?.flightNumber ? state?.flightNumber.toString().padStart(4, '0') : '';
  const title = `Combine Schedule - ${airlineCode}${flightNumber} ${scheduled}`;
  const icon = 'sv sv-combine';
  const options = {
    footer: {
      submitButtonText: 'Confirm',
    },
  };

  return (
    <EditModal
      className="editCombineModal"
      show={!!show}
      title={title}
      icon={icon}
      options={options}
      onHide={onHide}
      onSubmit={handleSubmit}
    >
      <VirtualTable
        header={{
          flightNumber: 'FLT',
          scheduled: 'TIME',
          pilots: 'PLT',
          attendants: 'FA',
        }}
        data={rows}
        selected={selected}
        loading={loading}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
          >
            <SelectCell onClick={onSetCombine} />
            <DynamicCell selector="flightNumber" className="text-center" width="calc(100% / 4)" />
            <DynamicCell
              selector="scheduled"
              className="text-center"
              width="calc(100% / 4)"
              render={({ value }: { value: string }): string => new Datetime(value).time}
            />
            <DynamicCell selector="pilots" className="text-center" width="calc(100% / 4)" />
            <DynamicCell selector="attendants" className="text-center" width="calc(100% / 4)" />
          </VirtualTableRow>
        )}
        dynamic
      />
      <div className="mt-3">
        <span>
          {selected.length || 0} of {totalCount || 0} trips selected
        </span>
      </div>
    </EditModal>
  );
};

export default EditCombineModal;
