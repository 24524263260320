import './styles.scss';

import React, { useCallback, useMemo } from 'react';
import SimpleTable, { SimpleTableProps } from '@/components/SimpleTable';
import TripsTableRow, { TripsTableRowEventHandlers } from '@/features/Trips/components/TripsTable/TripsTableRow';

import TripsTableHeader from '@/features/Trips/components/TripsTable/TripsTableHeader';

type TripsTableProps = TripsTableRowEventHandlers & Omit<SimpleTableProps, 'row' | 'header' | 'rowHeight'>;
const TripsTable = ({
  onEditTrip,
  onEditLocation,
  onEditFlag,
  onEditCommunication,
  onEditCompletion,
  onEditActivity,
  onEditFcr,
  onEditCombine,
  onEditRateReport,
  onEditRate,
  confirmIllegalCombines,
  rows,
  ...props
}: TripsTableProps) => {
  const Header = useMemo(() => <TripsTableHeader rows={rows} />, [rows]);
  const Row = useCallback(
    (props) => (
      <TripsTableRow
        onEditTrip={onEditTrip}
        onEditLocation={onEditLocation}
        onEditFlag={onEditFlag}
        onEditCommunication={onEditCommunication}
        onEditCompletion={onEditCompletion}
        onEditActivity={onEditActivity}
        onEditFcr={onEditFcr}
        onEditCombine={onEditCombine}
        onEditRateReport={onEditRateReport}
        onEditRate={onEditRate}
        confirmIllegalCombines={confirmIllegalCombines}
        {...props}
      />
    ),
    [
      onEditTrip,
      onEditLocation,
      onEditFlag,
      onEditCommunication,
      onEditCompletion,
      onEditActivity,
      onEditFcr,
      onEditCombine,
      onEditRateReport,
      onEditRate,
      confirmIllegalCombines,
    ]
  );
  return <SimpleTable className="TripsTable" rows={rows} header={Header} row={Row} {...props} />;
};

export default React.memo(TripsTable);
