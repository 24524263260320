import { Form } from 'react-bootstrap';
import Logger from '../../utils/logs';
import { useState } from 'react';

const log = Logger.of('ActiveSwitch');

type ActiveSwitchProps = {
  title: string;
  value: boolean;
  onHide?: () => void;
  onSubmit: (input: boolean) => Promise<void>;
};

const ActiveSwitch = ({ title, value, onHide, onSubmit }: ActiveSwitchProps): React.JSX.Element => {
  const [checked, setChecked] = useState(value);
  const [loading, setLoading] = useState(false);

  return (
    <Form.Switch
      role="switch"
      className="ActiveSwitch"
      checked={checked}
      disabled={loading}
      onChange={async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const isChecked = event?.target?.checked;
        setChecked(isChecked);
        setLoading(true);
        onSubmit(isChecked)
          .catch((err: Error): void => {
            setChecked(!isChecked);
            log.error(err).notify({ title });
          })
          .finally(() => {
            setLoading(false);
            onHide?.();
          });
      }}
    />
  );
};

export default ActiveSwitch;
