import Dropdown, { DropdownItem, DropdownMultiProps, DropdownProps } from '@/components/Dropdown';
import Field, { FieldProps } from '@/components/Field';
import { ReactNode, useMemo } from 'react';

export type EnumDropdownProps = { enum: Record<string, string> } & Omit<DropdownProps, 'items'>;
export type EnumDropdownMultiProps = { enum: Record<string, string> } & Omit<DropdownMultiProps, 'items'>;

const EnumDropdown = ({ enum: enumOptions, ...dropdownProps }: EnumDropdownProps): ReactNode => {
  const items = useMemo(
    (): DropdownItem[] =>
      Object.entries(enumOptions).map(
        ([label, value]: [string, string]): DropdownItem => ({
          label,
          value,
          keywords: [label, value],
        })
      ),
    [enumOptions]
  );

  return <Dropdown {...dropdownProps} items={items} />;
};
export const EnumDropdownMulti = ({ enum: enumOptions, ...dropdownProps }: EnumDropdownMultiProps): ReactNode => {
  const items = useMemo(
    (): DropdownItem[] =>
      Object.entries(enumOptions).map(
        ([label, value]: [string, string]): DropdownItem => ({
          label,
          value,
          keywords: [label, value],
        })
      ),
    [enumOptions]
  );
  return <Dropdown.Multi {...dropdownProps} items={items} />;
};
EnumDropdown.Multi = EnumDropdownMulti;

export default EnumDropdown;
export type EnumDropdownFieldProps = EnumDropdownProps & Partial<Omit<FieldProps, 'onChange'>>;
export const EnumDropdownField = ({ label, feedback, valid, required, ...enumDropdownProps }: EnumDropdownFieldProps): ReactNode => (
  <Field label={label} feedback={feedback} valid={valid} required={required}>
    <EnumDropdown {...enumDropdownProps} isValid={valid === true} isInvalid={valid === false} />
  </Field>
);
