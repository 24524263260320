import React, { useCallback, useMemo } from 'react';
import { SimpleTableHeaderProps, SortCell } from '@/components/SimpleTable';
import { SortDirectionEnum, Trip } from '@/models/gen/graphql';
import useTripSettings, { ColumnKeyEnum } from '@/features/Trips/components/TripSettingsModal/hook';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

const TripTableSelectAll = ({ rows }: SimpleTableHeaderProps): React.JSX.Element => {
  const trips = useTripTableState(({ state }) => state.trips);
  const selected = useTripTableState(({ state }) => state.selected);
  const setState = useTripTableState(({ setState }) => setState);

  const filteredTrips = useMemo(() => {
    const output: Map<string, Trip> = new Map();
    rows.forEach((rowId) => output.set(rowId, trips.get(rowId)));
    return output;
  }, [rows, trips]);

  const isSelectAll = useMemo(() => {
    if (selected.size !== filteredTrips.size) return false;
    for (const tripId of selected.keys()) {
      if (!filteredTrips.has(tripId)) return false;
    }
    return true;
  }, [filteredTrips, selected]);

  const handleChange = useCallback(
    () => setState((current) => ({ ...current, selected: isSelectAll ? new Map() : filteredTrips })),
    [filteredTrips, isSelectAll, setState]
  );

  return <input type="checkbox" checked={isSelectAll} onChange={handleChange} />;
};

const TripTableHeader = ({ rows }: SimpleTableHeaderProps): React.JSX.Element => {
  const columns = useTripSettings(({ state }) => new Map(state.columns));
  const sorting = useTripTableState(({ state }) => state.sorting);
  const onSort = useTripTableState(({ state }) => state.onSort);
  const onSortOnly = useTripTableState(({ state }) => state.onSortOnly);

  const columnSortMap = useMemo(
    () =>
      sorting.reduce((acc, curr) => {
        acc[curr.column] = curr.direction;
        return acc;
      }, {}) as Record<TripSortColumnEnum, SortDirectionEnum>,
    [sorting]
  );

  const handleSort = useCallback(
    (column: TripSortColumnEnum): React.MouseEventHandler<HTMLButtonElement> =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        // is user holding shift key
        if (event.shiftKey) return onSort(column);
        return onSortOnly(column);
      },
    [onSort, onSortOnly]
  );
  return (
    <>
      <div className="table-header-cell w-xs-fixed d-print-none">
        <TripTableSelectAll rows={rows} />
      </div>
      {columns.get(ColumnKeyEnum.FLAGS) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <div className="w-100 d-flex justify-content-center gap-2">
            <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
            <i className="sv sv-message2 fs-5" />
          </div>
        </div>
      )}
      {columns.get(ColumnKeyEnum.TYPE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.type)} direction={columnSortMap[TripSortColumnEnum.type]}>
            TYPE
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_DATE) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.latestScheduled)} direction={columnSortMap[TripSortColumnEnum.latestScheduled]}>
            DATE
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.SCHEDULED_TIME) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.latestScheduledUtc)} direction={columnSortMap.latestScheduledUtc}>
            SCH
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.ACTUAL) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.actual)} direction={columnSortMap[TripSortColumnEnum.actual]}>
            ACT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.AIRPORT_CODE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.airportCode)} direction={columnSortMap[TripSortColumnEnum.airportCode]}>
            CITY
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.IATA_CODE) && (
        <div className="table-header-cell  w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.servicerIataAirlineCode)} direction={columnSortMap.servicerIataAirlineCode}>
            AL
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.FLIGHT_NUMBER) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.flightNumber)} direction={columnSortMap[TripSortColumnEnum.flightNumber]}>
            FLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.PILOT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.pilots)} direction={columnSortMap[TripSortColumnEnum.pilots]}>
            PLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.ATTENDANT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell onSort={handleSort(TripSortColumnEnum.attendants)} direction={columnSortMap[TripSortColumnEnum.attendants]}>
            FA
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.DRIVER) && (
        <div className="table-header-cell w-md-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.driverId)} direction={columnSortMap[TripSortColumnEnum.driverId]}>
            DRV
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.VEHICLE) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.vehicleId)} direction={columnSortMap[TripSortColumnEnum.vehicleId]}>
            VAN
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.PU_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.puLocationId)} direction={columnSortMap[TripSortColumnEnum.puLocationId]}>
            P/U
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.DO_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.doLocationId)} direction={columnSortMap[TripSortColumnEnum.doLocationId]}>
            D/O
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.CLIENT) && (
        <div className="table-header-cell">
          <SortCell onSort={handleSort(TripSortColumnEnum.payerProviderId)} direction={columnSortMap[TripSortColumnEnum.payerProviderId]}>
            CLT
          </SortCell>
        </div>
      )}
      {columns.get(ColumnKeyEnum.COMPLETION) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-completion fs-5" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.ACTIVITY) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-bubble-dots fs-5" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.FCR) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-fcr fs-5" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.COMBINE) && (
        <div className="table-header-cell w-sm-fixed d-print-none">
          <i className="sv sv-combine-icon fs-5 text-secondary" />
        </div>
      )}
      {columns.get(ColumnKeyEnum.RATE) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(TripSortColumnEnum.rateAmount)} direction={columnSortMap[TripSortColumnEnum.rateAmount]}>
            RATE
          </SortCell>
        </div>
      )}
    </>
  );
};
export default React.memo(TripTableHeader);
