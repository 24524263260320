// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatList-Header {
  --bs-accordion-active-bg: transparent;
  --bs-accordion-border-color: transparent;
}
.ChatList-Header .accordion-button,
.ChatList-Header .accordion-button .collapsed {
  padding: 0.3rem;
  box-shadow: none;
}
.ChatList-Header .accordion-button::after {
  display: none;
}
.ChatList-Header .accordion-button:not(.collapsed) {
  color: black;
}

.ChatList-Title {
  text-align: left;
}
.ChatList-Title p {
  margin: 0;
}
.ChatList-Title .ChatList-Subtitle {
  max-width: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ChatList-Avatar img {
  border: var(--bs-border-width) solid var(--bs-secondary-subtle);
  width: 4rem;
  height: 4rem;
}

.ChatList-Actions .ActionsDropdown .btn {
  font-size: 2rem;
}
.ChatList-Actions .ActionsDropdown .btn:hover {
  color: var(--bs-secondary);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatList/ChatListHeader/styles.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,wCAAA;AACF;AAAE;;EAEE,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,aAAA;AAEJ;AAAE;EACE,YAAA;AAEJ;;AAEA;EACE,gBAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,kBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;;AAGE;EACE,+DAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAMI;EACE,eAAA;AAHN;AAIM;EACE,0BAAA;AAFR","sourcesContent":[".ChatList-Header {\n  --bs-accordion-active-bg: transparent;\n  --bs-accordion-border-color: transparent;\n}\n.ChatList-Header .accordion-button,\n.ChatList-Header .accordion-button .collapsed {\n  padding: 0.3rem;\n  box-shadow: none;\n}\n.ChatList-Header .accordion-button::after {\n  display: none;\n}\n.ChatList-Header .accordion-button:not(.collapsed) {\n  color: black;\n}\n\n.ChatList-Title {\n  text-align: left;\n}\n.ChatList-Title p {\n  margin: 0;\n}\n.ChatList-Title .ChatList-Subtitle {\n  max-width: inherit;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.ChatList-Avatar img {\n  border: var(--bs-border-width) solid var(--bs-secondary-subtle);\n  width: 4rem;\n  height: 4rem;\n}\n\n.ChatList-Actions .ActionsDropdown .btn {\n  font-size: 2rem;\n}\n.ChatList-Actions .ActionsDropdown .btn:hover {\n  color: var(--bs-secondary);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
