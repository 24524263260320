import {
  bindMethods,
  generateSlice,
  getLocalStorage,
  getResultFromState,
  parseStoredReduxState,
  replaceTokens,
  setLocalStorage,
} from 'utils';
import { useDispatch, useSelector } from 'react-redux';

import config from '../config.json';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// TODO: Expand on this type to make it more detailed where needed.
type AppState = {
  settings: any;
  page: Record<string, any>;
};

let env = 'local';
Object.entries(config.env || {}).forEach(
  ([name = '', domains = []]: [string, any]): string => (env = domains.includes(window.location.host) ? name : env)
);
window['__config__'] = Object.freeze({
  ...config,
  ...(window?.['__config__'] || {}),
  env: env,
  'client-api-url': replaceTokens(config['client-api-url'], { env }),
  'chat-api-url': replaceTokens(config['chat-api-url'], { env }),
});

const persistConfig = {
  key: 'app',
  version: 1,
  storage,
  blacklist: [],
};
const initAppState: AppState = {
  settings: {},
  page: {},
};
const appSlice = generateSlice('app', initAppState);

const useAppState = (selector: any = (fullState: AppState): AppState => fullState): [AppState, Function] => {
  const { _persist = {}, ...app } = useSelector((state: any): any => state?.app || {});
  const state = selector(app || {});
  const dispatch = useDispatch();

  const setState = bindMethods(
    (payload: any): any => dispatch(appSlice.actions.setState({ ...getResultFromState(payload, state), _persist })),
    dispatch
  );

  return [state, setState];
};

// This is a basic "hook" format for use in functions, outside of React Components.
const appState = (selector: any = (fullState: any): any => fullState): any => {
  const stored = getLocalStorage('persist:app', initAppState);
  const { _persist = {}, ...app } = parseStoredReduxState(stored);
  const state = selector(app || {});
  const setState = (payload: any): void => setLocalStorage('persist:app', { ...getResultFromState(payload, state), _persist });
  return [state, setState];
};

export { useAppState, appState };
export default persistReducer(persistConfig, appSlice.reducer);
