import {
  GetInvoiceResponse,
  InvoiceInput,
  InvoiceLineItemInput,
  InvoiceResponse,
  InvoiceTripInput,
  InvoiceTypeTypeEnum,
  RunPreviewInvoiceResponse,
  TripStatusEnum,
} from '@/models/gen/graphql';

import createGraphApiHook from '@/hooks/createGraphApiHook';
import getInvoice from './getInvoice';
import runPreviewInvoice from './runPreviewInvoice';

interface ParsedInvoiceResponseValues {
  airports: string[];
  iataAirlineCodes: string[];
  types: InvoiceTypeTypeEnum[];
  tripStatus: TripStatusEnum[];
  emails: string[];
  headers: { name: string }[];
  items: InvoiceLineItemInput[];
  trips: InvoiceTripInput[];
}

// Type for Parsed Invoice response
type ParsedInvoiceResponse = {
  [K in keyof InvoiceResponse]: K extends keyof ParsedInvoiceResponseValues ? ParsedInvoiceResponseValues[K] : InvoiceResponse[K];
};

export type ParsedInvoiceInput = {
  [K in keyof InvoiceInput]: K extends keyof ParsedInvoiceResponseValues ? ParsedInvoiceResponseValues[K] : InvoiceInput[K];
};

interface ParsedRunPreviewInvoiceResponseValues {
  output: ParsedInvoiceResponse;
}

// Type for Parsed Run Preview Invoice response
export type ParsedRunPreviewInvoiceResponse = {
  [K in keyof RunPreviewInvoiceResponse]: K extends keyof ParsedRunPreviewInvoiceResponseValues
    ? ParsedRunPreviewInvoiceResponseValues[K]
    : RunPreviewInvoiceResponse[K];
};

const convertGetInvoiceResponseToRunPreviewInvoiceResponse = (res: GetInvoiceResponse): RunPreviewInvoiceResponse => {
  if (!res) return;
  const output: RunPreviewInvoiceResponse = {
    ...res,
    summary: undefined,
    __typename: 'RunPreviewInvoiceResponse',
  };
  return output;
};
export const getInvoicePreview = async (input: number | InvoiceInput): Promise<RunPreviewInvoiceResponse> => {
  if (!input) return;
  if (typeof input === 'number') {
    const getInvoiceResponse = await getInvoice(input);
    return convertGetInvoiceResponseToRunPreviewInvoiceResponse(getInvoiceResponse);
  }

  return await runPreviewInvoice(input);
};

export const convertParsedInvoiceInputToInvoiceInput = (input: ParsedInvoiceInput): InvoiceInput => {
  const parsedInput: InvoiceInput = {
    ...input,
    tripStatus: (input?.tripStatus || []).map((status) => ({ status })),
    iataAirlineCodes: (input?.iataAirlineCodes || []).map((iataCode) => ({ iataCode })),
    airports: (input?.airports || []).map((code) => ({ code })),
    emails: (input?.emails || []).map((address) => ({ address })),
    headers: (input?.headers || []).map((header) => header),
    trips: (input?.trips || []).map(({ index, rate, tripId }) => ({ index, rate, tripId })),
    types: (input?.types || []).map((type) => ({ type: type || InvoiceTypeTypeEnum.Sch })),
  };
  return parsedInput;
};

export const parseGetInvoicePreviewResponse = (res: RunPreviewInvoiceResponse): ParsedRunPreviewInvoiceResponse => {
  if (!res) return {};
  const parsedResponse: ParsedRunPreviewInvoiceResponse = {
    ...res,
    output: {
      ...res?.output,
      airports: (res?.output?.airports || []).map(({ code }) => code),
      iataAirlineCodes: (res?.output?.iataAirlineCodes || []).map(({ iataCode }) => iataCode),
      emails: (res?.output?.emails || []).map(({ address }) => address),
      headers: (res?.output?.headers || []).map((header) => header),
      items: (res?.output?.items || []).map(({ lineItemTypeId, name, total }) => ({ lineItemTypeId, name, total })),
      types: (res?.output?.types || []).map(({ type }) => type),
      tripStatus: (res?.output?.tripStatus || []).map(({ status }) => status as any),
      invoice: {
        ...res?.output?.invoice,
        invoiced: res?.output?.invoice?.endDatetime || res?.output?.invoice?.invoiced,
      },
    },
  };
  return parsedResponse;
};

export const useGetInvoicePreview = createGraphApiHook(getInvoicePreview);
