import { useState } from 'react';

/* useSessionStorage
  Provides a hook-styled way to use the browser's SessionStorage API
  to store/retrieve data from the client's browser.

  It basically just provides a [state, setState] tuple in the same
  format as React's useState hook to allow for similar usage. All
  data stored/retrieved is automatically parsed and stored as a string
  that sessionStorage requires, and returned as a JSON Object.
*/

const useSessionStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(error);
      return initialValue;
    }
  });
  const getValue = () => {
    try {
      const item = window.sessionStorage.getItem(key);
      setStoredValue(item ? JSON.parse(item) : initialValue);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(error);
      return setStoredValue(initialValue);
    }
  };
  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      return valueToStore;
    } catch (error) {
      console.error(error);
      return storedValue;
    }
  };
  const deleteValue = () => {
    setStoredValue(undefined);
    window.sessionStorage.removeItem(key);
  };

  return [storedValue, setValue, deleteValue, getValue];
};

const getSessionStorage = (key, defaultValue) => {
  try {
    const value = window.sessionStorage.getItem(key);
    return value || defaultValue;
  } catch (error) {
    console.warn(error);
    return defaultValue;
  }
};

export default useSessionStorage;
export { getSessionStorage };
