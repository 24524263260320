import { Datetime, onEnter } from '../../utils';
import dayjs, { Dayjs } from 'dayjs';

import { CURRENT_DATE } from '../../constants';
import Filters from '../../components/Filters';
import { Form } from 'react-bootstrap';
import FormField from '../../components/FormField';
import React from 'react';
import WeekInput from '../../components/DateInput/WeekInput';
import useSession from '@/state/session';
import useUuid from '../../hooks/useUuid';
import RoleDropdown from '@/components/RoleDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';

type AvailabilityFiltersState = {
  search: string;
  view: dayjs.ManipulateType;
  city: string[];
  roleId: string;
  from: string;
  to: string;
  viewRequests: boolean;
};
const initAvailabilityFiltersState: AvailabilityFiltersState = {
  search: '',
  view: 'week',
  city: [],
  roleId: '',
  from: new Datetime(CURRENT_DATE.clone().startOf('week')).toString(),
  to: new Datetime(CURRENT_DATE.clone().endOf('week')).toString(),
  viewRequests: false,
};
const AvailabilityFilters = ({ onSubmit, onReset, onFilter, dayOfWeek }): JSX.Element => {
  const driverRoleId = useSession(({ state }) => state.config?.driverRoleId || '');
  const uuid = useUuid();
  return (
    <>
      <Filters
        name="availabilityFilters"
        onSubmit={onSubmit}
        onReset={onReset}
        value={{ ...initAvailabilityFiltersState, roleId: driverRoleId }}
        primary={({ values, onChange, setFilters }): JSX.Element => {
          const { roleId, city, from, to, search, view } = values;

          const onChangeWeek = (weekStart: Dayjs): void => {
            const temp = new Datetime(weekStart);
            const f = temp.clone().startOf('week');
            const t = temp.clone().endOf('week');

            if (f.asDayjs().isSame(from, 'day')) return;

            setFilters((current) => ({
              ...current,
              from: f.toString(),
              to: t.toString(),
            }));
            onSubmit({
              ...values,
              from: f.toString(),
              to: t.toString(),
              sortDate: f.clone().add(dayOfWeek, 'days').dateInput,
            });
          };

          const handleChange = (name: string, value: string): void => onChange({ target: { name, value } });

          return (
            <>
              <FormField
                name="weekInput"
                onChange={onChangeWeek}
                value={from}
                condensed
                options={{
                  group: { className: 'WeekInput' },
                  input: { as: WeekInput },
                }}
              />
              {/* v2: */}
              {/* <Select
                className="ms-2"
                name="view"
                onChange={onChange}
                value={view}
                disabled
              >
                <option value="week">Week View</option>
                <option value="day">Day View</option>
              </Select> */}
              <AirportGroupDropdown
                name="city"
                onChange={(value) => handleChange('city', value)}
                value={city}
                options={{ locale: { 'Select...': 'Airport' } }}
              />
              <RoleDropdown name="roleId" onChange={(value) => handleChange('roleId', value)} value={roleId} />
              <FormField
                name="search"
                prepend={<i className="sv sv-magnifier fs-4" />}
                onChange={onChange}
                placeholder="Search"
                onBlur={() => onFilter(values)}
                onKeyDown={onEnter(() => onFilter(values))}
                value={search}
                condensed
                options={{ input: { className: 'w-100' } }}
              />
            </>
          );
        }}
        alternate={({ values, onChange }): JSX.Element => {
          const { viewRequests } = values;
          return (
            <>
              <Form.Switch
                id={uuid}
                name="viewRequests"
                checked={viewRequests}
                onChange={(event) => {
                  onChange.toggle(event);
                  onSubmit({ ...values, viewRequests: event.target.checked });
                }}
                label="View Requests:"
                className="ActiveSwitch"
                reverse
              />
            </>
          );
        }}
        submitOnMount={true}
      />
    </>
  );
};

export default AvailabilityFilters;
