import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import useSession, { SessionUser } from '@/state/session';

import FormField from 'components/FormField';
import React from 'react';
import { Validation } from 'utils';
import useForm from 'hooks/useForm';
import useValidation from 'hooks/useValidation';

const initForm = {
  name: '',
};
const formValidator = new Validation.Validator({
  'name!': (value: string): Validation.Validity => ({
    valid: value?.length > 2,
    message: value?.length <= 2 ? 'Please enter a name at least 2 characters long.' : undefined,
  }),
});
const GlobalStateSandbox = (): JSX.Element => {
  const firstName = useSession(({ state }) => state.user?.firstName);
  const setState = useSession(({ setState }) => setState);

  const [form, onChange] = useForm({ ...initForm, name: firstName });
  const [valid, validity, dirty] = useValidation(formValidator, form);

  const onSubmit = (): void => {
    setState((current) => ({ ...current, user: { ...(current.user || ({} as SessionUser)), firstName: form?.name || '' } }));
  };

  return (
    <div className="d-flex flex-column gap-4">
      <Card>
        <Card.Body>
          <Card.Title>Actions</Card.Title>
          <FormField
            name="name"
            label="Name"
            value={form?.name}
            onChange={onChange}
            valid={validity?.name?.valid}
            placeholder="Name"
            feedback={validity?.name?.message}
            required
            inline
          />
          <Form.Group className="text-center mt-2">
            <Button variant="success" disabled={!valid} onClick={onSubmit}>
              Submit
            </Button>
          </Form.Group>
        </Card.Body>
      </Card>
      <Row gutter={12}>
        <Col span={12}>
          <Card>
            <Card.Body>
              <Card.Title>Global State</Card.Title>
              <pre className="m-0 p-2">{JSON.stringify(firstName, null, '  ')}</pre>
            </Card.Body>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Card.Body>
              <Card.Title></Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GlobalStateSandbox;
