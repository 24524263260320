import React, { useEffect, useMemo } from 'react';

import { Datetime } from '@/utils';
import DynamicContent from '@/components/DynamicContent';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { TripModalViewProps } from '@/features/Trips/components/TripModal';
import { useGetActivityForTrip } from '@/api/services/activity/SearchActivity';

const ActivityView = ({ data: trip, title }: TripModalViewProps) => {
  const [{ data, loading }, { fetch: getActivity }] = useGetActivityForTrip();

  useEffect(() => {
    if (!trip?.id) return;
    getActivity(trip?.id, trip?.offset);
  }, [trip, getActivity]);

  const activity: string[] = useMemo(() => {
    return data?.map(({ createdAt, message }) => {
      const fromNow = new Datetime(createdAt).asDayjs().utc(true).fromNow();
      const fullMessage = `${message} (${fromNow})`;
      return fullMessage;
    });
  }, [data]);

  return (
    <div className="w-100 h-100">
      {title && <DynamicContent.Title>Activity</DynamicContent.Title>}
      <LoadingBlur loading={loading} />
      {!loading && (
        <>
          {activity?.length === 0 && <p>No activity found.</p>}
          {activity?.length > 0 &&
            (activity || []).map((message: string, index: number) => (
              <div key={index} className="my-1">
                <small className="text-primary">{message}</small>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default ActivityView;
