import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails, queryInput } from '../../../utils/custom';
import { Stop, StopConnection, StopSearch, StopConnectionInput } from '../../../models/gen/graphql';
import { SearchStopDocument } from '@/api/queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

export type SearchStopGraphApiResponse = GraphApiResponse<typeof SearchStopDocument>;
export const searchStopResponseSelector = (res: SearchStopGraphApiResponse): ConnectionDetails<Stop> =>
  convertConnectionToDetails(res?.searchStops?.stopConnection as StopConnection);

const [, runRefetchSearchStop] = graphApi(SearchStopDocument, {
  onError: (res: SearchStopGraphApiResponse): void => handleError(res, { notification: { title: 'Search Stop' } }),
});

export const getStopByTripId = async (tripId: string): Promise<Stop[]> => {
  const input: StopConnectionInput = {
    first: 1000,
    after: null,
    query: [{ tripId: queryInput(tripId) }],
  };

  const res = await runRefetchSearchStop({ input });
  const conn = searchStopResponseSelector(res);

  return conn.rows;
};
export const useGetStopForTrip = createGraphApiHook(getStopByTripId);
