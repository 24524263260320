import useSession, { Config } from '@/state/session';
export type PermissionAuth = Omit<Config['auth'], '__typename'>;
const HasPermission = ({
  name,
  inverse,
  children = [],
}: {
  name: keyof PermissionAuth;
  inverse?: boolean;
  children?: any;
}): JSX.Element => {
  const found = useSession(({ state }) => state.config?.auth?.[name] || false);

  if (!found || (inverse !== undefined && found)) return null;
  return children;
};

HasPermission.check = (name: keyof PermissionAuth): boolean => useSession.getState().state.config?.auth?.[name] || false;

export default HasPermission;
