import { Container, ListGroup, Row } from 'react-bootstrap';
import { HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { Room, useChatState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatListContent from '@/components/ChatWidget/ChatList/ChatListContent';
import ChatListHeader from '@/components/ChatWidget/ChatList/ChatListHeader';
import ChatMessage from '@/components/ChatWidget/ChatMessage';
import useChatMethods from '@/hooks/useSocket/chat/useChatMethods';
import useViewport from '@/hooks/useViewport';
import useElementSize from '@/hooks/useElementSize';

type MessageCenterContentProps = HTMLAttributes<HTMLDivElement> & {
  room: Room;
};
const MessageCenterContent = ({ room, ...props }: MessageCenterContentProps) => {
  const [history, setState] = useChatState(({ state: { history }, setState }) => [history, setState]);
  const setHistory = useCallback((history: any) => setState((state) => ({ ...state, history })), [setState]);
  const { getHistory } = useChatMethods();
  const messagesEnd = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => messagesEnd.current.scrollIntoView({ behavior: 'instant', block: 'end' });
  const [{ content: { height: viewportHeight = 0 } = {} } = {}] = useViewport();
  const { ref: headerRef, height: headerHeight } = useElementSize();
  const { ref: footerRef, height: footerHeight } = useElementSize();

  useEffect(() => {
    setState((current) => ({ ...current, roomId: room?.id }));
    setHistory([]);
    getHistory({
      rooms: [room?.id],
      limit: 100,
      offset: 0,
    });
  }, [getHistory, room, setHistory, setState]);
  useEffect(() => {
    scrollToBottom();
  }, [history]);

  return (
    <Container {...props} fluid className="d-flex flex-column bg-white">
      <Row className="m-0">
        <ListGroup variant="flush">
          <ListGroup.Item className="bg-transparent" ref={headerRef}>
            <ChatListHeader.Active
              roomId={room?.id}
              name={room?.displayName}
              displayImageUrl={room?.displayImageUrl}
              servicerIataAirlineCode={room?.trip?.servicerIataAirlineCode}
              scheduledTime={room?.trip?.tripScheduled}
              unread={room?.unreadCount}
              roomType={room?.roomType}
            />
          </ListGroup.Item>
        </ListGroup>
      </Row>
      <Row className="align-items-start mx-4 h-100">
        <div
          className={`d-flex flex-column-reverse justify-content-start {max-height:${viewportHeight - headerHeight - footerHeight - 16}px;overflow:hidden|auto;}`}
        >
          {/* This needs to be first because the rows are reversed via css */}
          <div ref={messagesEnd} />
          {history?.map?.((message, index) => <ChatMessage message={message} key={index} />)}
        </div>
      </Row>
      <Row className="w-100" ref={footerRef}>
        <ChatListContent.Footer roomId={room?.id || ''} roomType={room?.roomType} />
      </Row>
    </Container>
  );
};

export default MessageCenterContent;
