import { FilterOption } from '@/models/gen/graphql';
import { GetCompaniesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetCompaniesGraphApiResponse = GraphApiResponse<typeof GetCompaniesDocument>;

const getCompaniesResponseSelector = (res: GetCompaniesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.companies as Array<FilterOption>;

const [runGetCompanies] = graphApi(GetCompaniesDocument, {
  onError: (res: GetCompaniesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Companies' } }),
});

export const getCompanies = async (): Promise<Array<FilterOption>> => {
  const res = await runGetCompanies();
  return getCompaniesResponseSelector(res);
};

export const useGetCompanies = createGraphApiHook(getCompanies);
