import './styles.scss';

import React, { ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import Portal from '@/components/Portal';
import { getClasses } from '@/utils';

export type QueryFiltersProps = { children?: ReactNode; portal?: HTMLElement | false } & React.HTMLAttributes<HTMLDivElement>;

const QueryFilters = ({ children, portal, ...divProps }: QueryFiltersProps): ReactNode => {
  const mounted = useRef<boolean>(false);
  const timer = useRef<NodeJS.Timeout>();
  const [container, setContainer] = useState<HTMLElement | false>();
  const content = useMemo(
    (): ReactNode => (
      <div {...divProps} className={getClasses('QueryFilters', divProps?.className)}>
        {children}
      </div>
    ),
    [children, divProps]
  );

  const handleMount = useCallback((): void => {
    if (mounted.current) return;
    const target = portal !== undefined ? portal : document.getElementById('PageFilters');
    if (target === false || target !== undefined) mounted.current = true;
    setContainer(target);
    timer.current = setTimeout(handleMount, 250);
  }, [portal]);

  useLayoutEffect((): (() => void) => {
    handleMount();
    return (): void => clearTimeout(timer.current);
  }, [handleMount]);

  if (container === false) return content;
  return container ? <Portal container={container}>{content}</Portal> : null;
};

export type QueryFiltersContainerProps = React.HTMLAttributes<HTMLDivElement>;
const QueryFiltersInput = (divProps: QueryFiltersContainerProps): ReactNode => (
  <div {...divProps} className={getClasses('QueryFilters-Input', divProps?.className)} />
);
QueryFilters.Input = QueryFiltersInput;
const QueryFiltersControl = (divProps: QueryFiltersContainerProps): ReactNode => (
  <div {...divProps} className={getClasses('QueryFilters-Control', divProps?.className)} />
);
QueryFilters.Control = QueryFiltersControl;

export default QueryFilters;
