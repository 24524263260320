import React, { useEffect } from 'react';
import { RunResetUserPasswordInput, User } from '../../models/gen/graphql';
import { Validation, createNotification } from '../../utils';

import EditModal from '../EditModal/new';
import PasswordInput from '../PasswordInput';
import { Toast } from '../../models';
import useForm from '../../hooks/useForm';
import { useRunResetUserPassword } from '@/api/services/users/runResetUserPasswordBulk';
import useValidation from '../../hooks/useValidation';

interface Props {
  show: boolean;
  data: User;
  onHide: () => void;
  onSubmit?: (input: RunResetUserPasswordInput[]) => void;
}

const resetPasswordValidator = new Validation.Validator({
  newPassword: (val: any): Validation.Validity => ({
    valid: !!val && Validation.isAlphanumeric(val) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    defaultValue: '',
  }),
});
const initEditUserPasswordModal = { newPassword: '' };
const EditUserPasswordModal = ({ show, data, onHide, onSubmit }: Props): JSX.Element => {
  const [form, onChange, setForm] = useForm(initEditUserPasswordModal);
  const { newPassword } = form;
  const [valid, validity] = useValidation(resetPasswordValidator, form);

  const [{ loading }, { fetch: runResetUserPassword }] = useRunResetUserPassword();

  const handleSubmit = async (): Promise<void> => {
    if (!data?.id) {
      return createNotification('Reset User Password: Cannot reset undefined user password.', Toast.Type.DANGER, 'Contact Support');
    } else {
      const triggerSubmit = onSubmit ? onSubmit : runResetUserPassword;
      const input: RunResetUserPasswordInput = { userIds: [data?.id], newPassword: form?.newPassword };
      await triggerSubmit([input]);
    }
    onHide();
  };

  useEffect(() => {
    if (show) return;
    setForm(initEditUserPasswordModal);
  }, [setForm, show]);

  return (
    <EditModal
      show={show}
      title={`Reset Password - ${data?.firstName} ${data?.lastName}`}
      icon="sv sv-rotation-lock"
      name="resetPassword"
      onHide={onHide}
      onSubmit={valid ? handleSubmit : false}
      loading={loading}
    >
      <PasswordInput label="New Password" value={newPassword} name="newPassword" onChange={onChange} valid={validity?.newPassword?.valid} />
    </EditModal>
  );
};

export default EditUserPasswordModal;
