import { CompletionCurrencyEnum, InvoiceTripCurrencyEnum, RateCurrencyEnum } from '@/models/gen/graphql';
import EnumDropdown, { EnumDropdownProps } from '@/components/EnumDropdown';
import Field, { FieldProps } from '@/components/Field';

import { ReactNode } from 'react';

type CurrencyDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  type?: 'rate' | 'completion' | 'invoice';
  name?: string;
};
const CurrencyDropdown = ({ type, name = 'currency', ...props }: CurrencyDropdownProps): ReactNode => {
  type CurrencySource = typeof RateCurrencyEnum | typeof CompletionCurrencyEnum | typeof InvoiceTripCurrencyEnum;
  let sourceEnum: CurrencySource = RateCurrencyEnum;
  switch (type) {
    case 'completion':
      sourceEnum = CompletionCurrencyEnum;
      break;
    case 'invoice':
      sourceEnum = InvoiceTripCurrencyEnum;
      break;
  }
  return <EnumDropdown {...props} enum={sourceEnum} name={name} />;
};

export const CurrencyDropdownField = ({
  label,
  feedback,
  valid,
  required,
  ...inputProps
}: Omit<FieldProps, 'children'> & CurrencyDropdownProps): ReactNode => {
  return (
    <Field label={label} feedback={feedback} valid={valid} required={required}>
      <CurrencyDropdown {...inputProps} />
    </Field>
  );
};

export default CurrencyDropdown;
