import './styles.scss';

import React, { useCallback, useMemo } from 'react';
import SimpleTable, { SimpleTableProps } from '@/components/SimpleTable';
import TripsTableRow from '@/features/AirlineTrips/components/TripsTable/TripsTableRow';

import TripsTableHeader from '@/features/AirlineTrips/components/TripsTable/TripsTableHeader';

type AirlineTripsTableProps = Omit<SimpleTableProps, 'row' | 'header' | 'rowHeight'>;
const AirlineTripsTable = ({ rows, ...props }: AirlineTripsTableProps) => {
  const Header = useMemo(() => <TripsTableHeader rows={rows} />, [rows]);
  const Row = useCallback((props) => <TripsTableRow {...props} />, []);
  return <SimpleTable className="TripsTable" rows={rows} header={Header} row={Row} {...props} />;
};

export default React.memo(AirlineTripsTable);
