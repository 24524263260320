import {
  Activity,
  ActivityTypeEnum,
  CreateActivityBulkInput,
  CreateActivityBulkResponse,
  CreateActivityInput,
} from '../../../models/gen/graphql';

import { CreateActivityBulkDocument } from '../../queries';
import { GraphApiResponse } from '../../core';
import Logger from '../../../utils/logs';
import { Validation } from '../../../utils';
import graphApi from '../..';
import useSession from '@/state/session';

/* PLEASE READ
  Unlike most services this service is used to create remarks on a trip.
  Since The input is a generic object typically this done on the BE.

  To use this service you must create a method that accepts the input of something that makes sense
   and handle the mapping from input to Activity on this layer.

  Nothing except the wrapped function should be exported from this service
 */

type CreateActivityBulkGraphApiResponse = GraphApiResponse<typeof CreateActivityBulkDocument>;

const log = Logger.of('service/createActivityBulk');

const createActivityValidator = new Validation.Validator({
  field: (value: string): Validation.Validity => ({ valid: !!value }),
  formerObjectId: (value: string): Validation.Validity => ({ valid: !!Validation.isValidUUID(value) }),
  formerValue: (value: any): Validation.Validity => ({ valid: undefined }), // TODO: always true since the former value could be anything
  message: (value: string): Validation.Validity => ({ valid: !!value }),
  objectId: (value: string): Validation.Validity => ({ valid: !!Validation.isValidUUID(value) }),
  subjectId: (value: string): Validation.Validity => ({ valid: !!Validation.isValidUUID(value) }),
  tripId: (value: string): Validation.Validity => ({ valid: !!Validation.isValidUUID(value) }),
  type: (value: ActivityTypeEnum): Validation.Validity => ({ valid: !!value }),
  value: (value: any): Validation.Validity => ({ valid: !!value }),
  via: (value: string): Validation.Validity => ({ valid: undefined }), // populated by BE
});

const createActivityBulkResponseSelector = (res: CreateActivityBulkGraphApiResponse): CreateActivityBulkResponse =>
  !res.errors ? (res?.createActivityBulk as CreateActivityBulkResponse) : undefined;

const [runCreateActivityBulk] = graphApi(CreateActivityBulkDocument);
const createActivityBulk = async (activities: Partial<Activity>[]): Promise<CreateActivityBulkResponse> => {
  const values: CreateActivityInput[] = activities.map(
    (activity: Activity): CreateActivityInput => createActivityValidator.create(activity)
  );
  const input: CreateActivityBulkInput = { values };

  const res = await runCreateActivityBulk({ input });
  return createActivityBulkResponseSelector(res);
};

export const createActivityForTrip = async (comments: Array<{ tripId: string; comment: string }>): Promise<CreateActivityBulkResponse> => {
  try {
    const userId = useSession.getState().state.user?.id || '';
    const activities: Array<Partial<Activity>> = comments.map(
      ({ tripId, comment }): Partial<Activity> => ({
        subjectId: userId,
        type: ActivityTypeEnum.Trip,
        message: comment,
        objectId: tripId,
        tripId,
      })
    );
    return await createActivityBulk(activities);
  } catch (err) {
    const message = err?.message || err;
    log.error('createActivityForTrip', message).notify({ title: 'Create Activity for Trip', message });
  }
};
