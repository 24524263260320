import { Datetime, Validation, createNotification, getProperty, setProperty } from '../utils';
import React, { useRef } from 'react';

import { ManipulateType } from 'dayjs';
import { Toast } from '../models';
import { isDateRangeWithinThreshold } from '../utils/dates';
import { stringify } from '../utils/objects';

type EventObject = {
  [key: string]: any;
  target?: {
    [key: string]: any;
    name?: string;
    value?: any;
    checked?: boolean;
  };
};

type DateRangeWithThresholdOptions = {
  unit?: ManipulateType;
  showNotification?: boolean;
  notificationText?: string;
};

interface OnChange {
  (event: EventObject): void;
  dateRange?(from: any, to: any): (event: EventObject) => void;
  dateRangeWithThreshold?(from: any, to: any, dayThreshold: number, options?: DateRangeWithThresholdOptions): (event: EventObject) => void;
  date?(event: EventObject): void;
  time?(event: EventObject): void;
  toggleInt?(event: EventObject): void;
  toggle?(event: EventObject): void;
  reset?(): void;
  int?(event: EventObject): void;
  bulk?(events: EventObject[]): void;
}
const useOnChange = <_, T>(setState: React.Dispatch<React.SetStateAction<T>>, defaultState?: T): OnChange => {
  const initState: React.MutableRefObject<T> = useRef(defaultState);
  const onChange = (event: EventObject): void => {
    const { name, value } = event.target;
    setState((current: T): T => ({ ...current, ...setProperty(current, name, value) }));
  };
  onChange.bulk = (events: EventObject[] = []): void => {
    setState((current: T): T => {
      const newValues = events.reduce((acc: T, event: EventObject): T => {
        const { name, value } = event.target;
        return setProperty(acc, name, value);
      }, stringify.parse(current));
      return { ...current, ...newValues };
    });
  };
  onChange.int = (event: EventObject): void => {
    const { name, value } = event?.target || event;
    const parsedValue = parseInt(value);
    const formatted = Validation.isNumber(parsedValue) ? parsedValue : undefined;
    setState((current: T): T => ({ ...current, ...setProperty(current, name, formatted) }));
  };
  onChange.float = (event: EventObject): void => {
    const { name, value } = event?.target || event;
    const parsedValue = parseFloat(value);
    const formatted = Validation.isNumber(parsedValue) ? parsedValue : '';
    setState((current: T): T => ({ ...current, ...setProperty(current, name, formatted) }));
  };
  onChange.toggle = (event: EventObject): void => {
    const { name } = event.target;
    setState((current: T): T => ({ ...current, ...setProperty(current, name, !getProperty(name, current)) }));
  };
  onChange.toggleInt = (event: EventObject): void => {
    const { name } = event.target;
    setState((current: T): T => ({ ...current, ...setProperty(current, name, getProperty(name, current) ? 0 : 1) }));
  };
  onChange.date = (event: EventObject): void => {
    const { name, value } = event.target;
    setState((current: T): T => {
      const current_val = getProperty(name, current);
      const result: string = value ? new Datetime(current_val || undefined).setDate(value).toString() : null;
      return { ...current, ...setProperty(current, name, result) };
    });
  };
  onChange.time = (event: EventObject): void => {
    const { name } = event.target;
    let { value } = event.target;
    // There is currently an issue with our existing TimeInput component since we're using a 3rd party library.
    // Its onBlur causes onChange to fire as well, but blur has no event, so this causes it to fire twice,
    // and the second time it has no value because of onBlur.
    if (!value) return setState((current: T): T => ({ ...current, ...setProperty(current, name, value) }));
    if (!value.includes(':')) {
      const input = value.padStart(4, 0);
      const hours = input.slice(0, 2);
      const minutes = input.slice(2, 4);
      value = `${hours}:${minutes}:00`;
    }
    // Default time values
    const [hours = '00', minutes = '00', seconds = '00'] = value.split(':');
    setState((current: T): T => {
      let current_val = getProperty(name, current);
      if (!Validation.isDate(current_val)) current_val = undefined;
      const result: string = value ? new Datetime(current_val || undefined).setTime(`${hours}:${minutes}:${seconds}`).toString() : null;
      return { ...current, ...setProperty(current, name, result) };
    });
  };
  onChange.dateRange =
    (fromName: string, toName: string): ((event: EventObject) => void) =>
    (event: EventObject): void => {
      const { value } = event.target;
      const [newFrom, newTo] = value.split(' - ');
      setState((current: T): T => {
        let newState = stringify.parse(current);
        newState = setProperty(newState, fromName, newFrom);
        newState = setProperty(newState, toName, newTo);
        return { ...current, ...newState };
      });
    };
  onChange.dateRangeWithThreshold =
    (fromName: string, toName: string, threshold: number, options: DateRangeWithThresholdOptions = {}): ((event: EventObject) => void) =>
    (event: EventObject): void => {
      const { unit = 'day' } = options;
      const { value } = event.target;
      const [newFrom, newTo] = value.split(' - ');
      if (!isDateRangeWithinThreshold(newFrom, newTo, threshold, unit)) {
        options?.showNotification &&
          createNotification(
            options?.notificationText || `Please select a date range less than ${threshold} ${unit}(s)`,
            Toast.Type.WARNING,
            'Invalid Date Range'
          );
        return;
      }
      setState((current: T): T => ({ ...current, [fromName]: newFrom, [toName]: newTo }));
    };
  onChange.reset = (): T => {
    setState(initState.current);
    return initState.current;
  };
  return onChange;
};

export default useOnChange;
export { OnChange, EventObject };
