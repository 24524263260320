import useSession, { SessionState } from '@/state/session';

import EditUsersModal from '.';
import Logger from '../../utils/logs';
import React from 'react';
import { User } from 'models/gen/graphql';
import { queryInput } from 'utils';
import { useSearchUsers } from '@/api/services/users/searchUsers';

interface EditProfileModalProps {
  show: boolean;
  onHide: () => any;
}

const log = Logger.of('EditProfileModal');

const EditProfileModal = ({ show = false, onHide }: EditProfileModalProps): JSX.Element => {
  const sessionUser = useSession(({ state }) => state.user);
  const setSession = useSession(({ setState }) => setState);
  const userSession = sessionUser || {};

  const [{ loading }, { refetch: searchUsers }] = useSearchUsers();

  const handleSubmit = async (): Promise<any> => {
    if (!sessionUser?.id || !show) return;
    const res = await searchUsers([{ id: queryInput([sessionUser?.id]) }], { pageSize: 1 });
    const user: User = res?.rows?.[0];
    if (!user) log.warn('handleSubmit: no user to update session, using previous user data.');
    setSession(
      (current: SessionState): SessionState => ({
        ...current,
        user: user || current.user,
      })
    );
  };

  return <EditUsersModal show={!!show} data={show ? userSession : undefined} onHide={onHide} onSubmit={handleSubmit} loading={loading} />;
};

export default React.memo(EditProfileModal);
