import './styles.scss';

import useTripSettings, { ColumnKeyEnum, TRIPS_SETTINGS_STORAGE_KEY } from '@/features/Trips/components/TripSettingsModal/hook';

import DynamicContent from '@/components/DynamicContent';
import { Form } from 'react-bootstrap';
import { properCase } from '@/utils';
import { useCallback } from 'react';

const TripSettingsModal = (): JSX.Element => {
  const setState = useTripSettings(({ setState }) => setState);
  const saveState = useTripSettings(({ saveState }) => saveState);
  const show = useTripSettings(({ state }) => state.show);
  const remember = useTripSettings(({ state }) => state.remember);
  const columns = useTripSettings(({ state }) => state.columns);
  const onToggle = useTripSettings(({ state }) => state.onToggleColumn);

  const handleToggle = useCallback(
    (key: ColumnKeyEnum): (() => void) =>
      (): void =>
        onToggle(key),
    [onToggle]
  );

  const handleHideSettings = (): void => {
    setState((current) => ({ ...current, show: false }));
    if (remember) saveState();
    else localStorage.removeItem(TRIPS_SETTINGS_STORAGE_KEY);
  };

  const onToggleRemember = (): void => {
    setState((current) => ({ ...current, remember: !current.remember }));
  };

  return (
    <DynamicContent className="PageSettings" show={show} onHide={handleHideSettings} modal drawer as="drawer">
      <DynamicContent.Title>Settings</DynamicContent.Title>
      <div>
        <Form.Check onChange={onToggleRemember} label="Remember" checked={remember} />
      </div>
      <div className="my-2 p-2">
        {Array.from(columns?.entries()).map(
          ([key, value]: [ColumnKeyEnum, boolean]): JSX.Element => (
            <Form.Switch name={key} label={columnKeyToDisplayName(key)} checked={value || false} onChange={handleToggle(key)} key={key} />
          )
        )}
      </div>
    </DynamicContent>
  );
};

const columnKeyToDisplayName = (key: ColumnKeyEnum): string => {
  let words: string[];
  switch (key) {
    case ColumnKeyEnum.SCHEDULED_DATE:
      return 'Date';
    case ColumnKeyEnum.SCHEDULED_TIME:
      return 'Time';
    default:
      words = key.match(/([A-Z]+(?![a-z])|[A-Z]?[a-z]+)/g);
      if (!words) return properCase(key);
      return words.map((word) => (/^[A-Z]+$/.test(word) ? word : properCase(word))).join(' ');
  }
};

export default TripSettingsModal;
