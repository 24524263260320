import './styles.scss';

import React, { ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { Button } from 'react-bootstrap';
import Portal from '@/components/Portal';
import { Resizable } from 'react-resizable';
import Tippy from '@tippyjs/react';
import { getClasses } from '@/utils';
import useViewport from '@/hooks/useViewport';

export type FooterDrawerProps = {
  show: unknown;
  className?: string;
  onClose?: () => void;
  title?: ReactNode;
  initHeight?: number;
  footer?: () => JSX.Element;
  hideMinimize?: boolean;
  children?: React.ReactNode;
};

const FooterDrawer = ({
  show,
  className,
  onClose,
  title,
  initHeight,
  footer: Footer,
  hideMinimize,
  children,
}: FooterDrawerProps): JSX.Element => {
  const [{ width: viewportWidth, height: viewportHeight }] = useViewport();
  const [heightPercentage, setHeightPercentage] = useState<number>(50);
  const lastHeightPercentage = useRef<number>(50);
  const headerRef = useRef<HTMLDivElement>(null);
  const height = Math.max(headerRef.current?.offsetHeight || 0, (viewportHeight * heightPercentage) / 100);
  const classes = getClasses('FooterDrawer', className, show ? 'show' : undefined);

  const onResize = (_event: SyntheticEvent, { size }: { size: { height: number } }): void =>
    setHeightPercentage((size.height / viewportHeight) * 100);
  const onMinMax = (): void => {
    if (height > headerRef.current?.offsetHeight || 0) {
      lastHeightPercentage.current = heightPercentage;
      setHeightPercentage(0);
    } else setHeightPercentage(lastHeightPercentage.current);
    window.dispatchEvent(new CustomEvent('resize'));
  };

  useEffect((): void => {
    if (!show) return setHeightPercentage(0);
    setHeightPercentage(initHeight ? (initHeight / viewportHeight) * 100 : 50);
  }, [show, initHeight, viewportHeight]);

  return (
    <Portal container={document.getElementById('RouteContent-Footer')}>
      <Resizable
        width={viewportWidth}
        height={height}
        minConstraints={[viewportWidth, headerRef.current?.offsetHeight || 0]}
        maxConstraints={[viewportWidth, viewportHeight]}
        onResize={onResize}
        onResizeStop={() => window.dispatchEvent(new CustomEvent('resize'))}
        resizeHandles={['n']}
      >
        <div className={classes} style={{ maxHeight: `${height}px` }}>
          <div className="FooterDrawer-Header" ref={headerRef}>
            <div className="FooterDrawer-Title">{title}</div>
            {hideMinimize === undefined && (
              <Tippy content={height > headerRef.current?.offsetHeight || 0 ? 'Hide' : 'Show'}>
                <Button variant="icon" className="FooterDrawer-Minimize" onClick={onMinMax}>
                  <i className="fa fa-minus" />
                </Button>
              </Tippy>
            )}
            {onClose && (
              <Tippy content="Close">
                <Button variant="icon" className="FooterDrawer-Close" onClick={onClose}>
                  <i className="fa fa-times" />
                </Button>
              </Tippy>
            )}
          </div>
          <div className="FooterDrawer-Content">{children}</div>
          {Footer && (
            <div className="FooterDrawer-Footer">
              <Footer />
            </div>
          )}
        </div>
      </Resizable>
    </Portal>
  );
};

export default FooterDrawer;
