// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatWidget {
  --bs-secondary-border-subtle: #eae2da;
}
.ChatWidget .accordion-item:only-child {
  border: 0;
}
.ChatWidget .accordion-item:last-child {
  border-bottom: 0;
}
.ChatWidget .accordion-item {
  border: 0;
  border-bottom: 1px solid var(--bs-secondary-border-subtle);
}

.ChatWidget {
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 45vw;
  max-width: 400px;
  right: 50px;
  z-index: 1000;
  animation: slideIn 0.5s ease;
}
.ChatWidget p {
  margin: 0;
}
@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.ChatWidget-Unread {
  position: relative;
}
.ChatWidget-Unread .badge {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
.ChatWidget-Unread .sv-inbox {
  font-size: 24px;
}
.ChatWidget-Unread a {
  color: white;
  text-decoration: none;
}

.ChatWidget-Header {
  color: var(--bs-light);
  background-color: var(--bs-primary);
  letter-spacing: 0.3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/styles.scss"],"names":[],"mappings":"AAAA;EAEE,qCAAA;AAAF;AAEE;EACE,SAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;AAEE;EACE,SAAA;EACA,0DAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,eAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;EACA,WAAA;EACA,aAAA;EAKA,4BAAA;AALF;AAEE;EACE,SAAA;AAAJ;AAGE;EACE;IACE,2BAAA;EADJ;EAGE;IACE,wBAAA;EADJ;AACF;;AAKA;EACE,kBAAA;AAFF;AAGE;EACE,kBAAA;EACA,YAAA;EACA,cAAA;AADJ;AAGE;EACE,eAAA;AADJ;AAGE;EACE,YAAA;EACA,qBAAA;AADJ;;AAKA;EACE,sBAAA;EACA,mCAAA;EACA,sBAAA;AAFF","sourcesContent":[".ChatWidget {\n  --bs-secondary-border-subtle: #eae2da;\n}\n.ChatWidget .accordion-item:only-child {\n  border: 0;\n}\n.ChatWidget .accordion-item:last-child {\n  border-bottom: 0;\n}\n.ChatWidget .accordion-item {\n  border: 0;\n  border-bottom: 1px solid var(--bs-secondary-border-subtle);\n}\n\n.ChatWidget {\n  overflow: hidden;\n  position: fixed;\n  bottom: 0;\n  width: 45vw;\n  max-width: 400px;\n  right: 50px;\n  z-index: 1000;\n  animation: slideIn 0.5s ease;\n}\n.ChatWidget p {\n  margin: 0;\n}\n@keyframes slideIn {\n  from {\n    transform: translateY(100%);\n  }\n  to {\n    transform: translateY(0);\n  }\n}\n\n.ChatWidget-Unread {\n  position: relative;\n}\n.ChatWidget-Unread .badge {\n  position: absolute;\n  top: -0.5rem;\n  right: -0.5rem;\n}\n.ChatWidget-Unread .sv-inbox {\n  font-size: 24px;\n}\n.ChatWidget-Unread a {\n  color: white;\n  text-decoration: none;\n}\n\n.ChatWidget-Header {\n  color: var(--bs-light);\n  background-color: var(--bs-primary);\n  letter-spacing: 0.3rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
