import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';

import { Button } from 'react-bootstrap';
import DateRangePicker from '@/components/DateRangePicker';
import { Datetime, onEnter } from '@/utils';
import QueryFilters from '@/components/QueryFilters';
import { createComponentQueryState } from '@/state';
import usePopState from '@/hooks/usePopState';
import Input from '@/components/Input';
import { useChatState } from '@/hooks/useSocket/chat/useChatRooms';
import ClientDropdown from '@/components/ClientDropdown';

export type MessageCenterFilters = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string;
  keyword?: string;
};
export type MessageCenterFiltersProps = { value?: MessageCenterFilters; onSubmit: (values: MessageCenterFilters) => void };

type MessageCenterFiltersState = {
  startDatetime?: string;
  endDatetime?: string;
  userProviderId?: string;
  keyword?: string;
};
const initMessageCenterFiltersState: MessageCenterFiltersState = {
  startDatetime: TODAY,
  endDatetime: TODAY_EOD,
  userProviderId: null,
  keyword: null,
};

export const useMessageCenterFilters = createComponentQueryState(initMessageCenterFiltersState);

const MessageCenterFilters = ({ onSubmit }: MessageCenterFiltersProps): ReactNode => {
  const state = useMessageCenterFilters(({ state }) => state);
  const setState = useMessageCenterFilters(({ setState }) => setState);
  const saveState = useMessageCenterFilters(({ saveState }) => saveState);
  const rehydrateState = useMessageCenterFilters(({ rehydrate }) => rehydrate);
  const [loading, setChatState] = useChatState(({ state, setState }) => [
    [state.rooms?.ALL_CHAT, state.rooms?.MY_CHAT].includes(null),
    setState,
  ]);

  const { startDatetime, endDatetime, userProviderId, keyword } = state;
  const [lastState, setLastState] = useState<MessageCenterFiltersState>(state);

  const handleReset = useCallback((): void => {
    const startDatetime = new Datetime().startOf('day').toString();
    const endDatetime = new Datetime().endOf('day').toString();
    setState({ ...initMessageCenterFiltersState, startDatetime, endDatetime });
    setLastState({ ...initMessageCenterFiltersState, startDatetime, endDatetime });
    saveState();
    onSubmit({
      ...initMessageCenterFiltersState,
      userProviderId: null,
      startDatetime,
      endDatetime,
      keyword: null,
    });
  }, [setState, setLastState, saveState, onSubmit]);
  const handleChange = useCallback(
    (name: string): ((value: string | string[]) => void) =>
      (value: string | string[]): void => {
        setState(
          (current: MessageCenterFiltersState): MessageCenterFiltersState => ({
            ...current,
            [name]: value,
          })
        );
      },
    [setState]
  );
  const handleSubmit = useCallback((): void => {
    // get the latest state from closure to ensure it's always accurate
    const { state } = useMessageCenterFilters.getState();
    setChatState((current) => ({ ...current, rooms: { ...current.rooms, ALL_CHAT: null, MY_CHAT: null } }));
    saveState();
    setLastState(state);
    onSubmit(state);
  }, [setChatState, saveState, onSubmit]);

  const handlePopState = useCallback(() => rehydrateState().then(handleSubmit), [rehydrateState, handleSubmit]);
  usePopState(handlePopState);

  useLayoutEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QueryFilters>
      {/* TODO: This component needs to be deprecated, and replaced with a new one following our new component models. */}
      <QueryFilters.Control>
        <DateRangePicker
          name="dates"
          value={[startDatetime, endDatetime] as any}
          onChange={(event) => {
            const { value } = event?.target || {};
            const [start, end] = value.split(' - ');
            handleChange('startDatetime')(start);
            handleChange('endDatetime')(end);
          }}
          isDirty={lastState.startDatetime !== startDatetime || lastState.endDatetime !== endDatetime}
        />
      </QueryFilters.Control>
      <QueryFilters.Input>
        <ClientDropdown
          value={userProviderId}
          onChange={handleChange('userProviderId')}
          options={{ locale: { 'Select...': 'Airline' } }}
          className={lastState.userProviderId !== userProviderId ? '{border-color:#ff9e16!;}>input' : ''}
        />
      </QueryFilters.Input>
      <QueryFilters.Control>
        <Button variant="success" className="border-white" onClick={handleSubmit} disabled={loading}>
          {(loading && <i className="fa fa-spinner fa-pulse" />) || 'Go'}
        </Button>
        <Button variant="outline-light" onClick={handleReset}>
          Reset
        </Button>
      </QueryFilters.Control>
      <QueryFilters.Input>
        <Input
          type="text"
          value={keyword || ''}
          onChange={handleChange('keyword')}
          className={(lastState.keyword || '') !== (keyword || '') ? '{border-color:#ff9e16!;}>input' : ''}
          placeholder="Search"
          onKeyDown={!loading ? onEnter(handleSubmit) : undefined}
        />
      </QueryFilters.Input>
    </QueryFilters>
  );
};

export default MessageCenterFilters;
