import { useEffect, useState } from 'react';

import { Alias } from '@/models/gen/graphql';
import LoadingSpinner from '@/components/LoadingSpinner';
import React from 'react';
import Tooltip from '@/components/Tooltip';
import { parsePhoneNumber } from '@/utils';
import useDelayCallback from '@/hooks/useDelayCallback';
import { useGetAliasForLocation } from '@/api/services/locations/searchLocations';

type LocationCellProps = {
  name: string;
  address: string;
  cityName: string;
  stateCode: string;
  zipCode: string;
  id: string;
  phoneNumber: string;
} & React.HTMLAttributes<HTMLButtonElement>;
const LocationCell = ({ name, address, cityName, stateCode, zipCode, id, phoneNumber }: LocationCellProps): React.JSX.Element => {
  const street = `${address}, ${cityName}, ${stateCode} ${zipCode}`;
  return (
    <>
      <Tooltip
        content={
          <>
            <div className="text-center">{name}</div>
            <div className="text-center">{street}</div>
            <div className="text-center">{parsePhoneNumber(phoneNumber, ' ')}</div>
          </>
        }
        placement="left"
      >
        <Tooltip content={<small>Click to edit location</small>}>
          <button>{name || '--'}</button>
        </Tooltip>
      </Tooltip>
      <LocationAliasTooltip locationId={id} />
    </>
  );
};

const LocationAliasTooltip = ({ locationId }: { locationId: string }): React.JSX.Element => {
  const [{ data, loading }, { fetch: getAliasForLocation }] = useGetAliasForLocation();
  const { rows: aliases = [] } = data || {};
  const [called, setCalled] = useState(false);

  const [handleMouseEnter, handleMouseLeave] = useDelayCallback((): void => {
    setCalled(true);
    getAliasForLocation(locationId);
  }, 250);

  useEffect((): (() => void) => {
    return (): void => setCalled(false);
  }, []);

  return (
    <Tooltip
      content={
        <>
          {/* display loading spinner if the request is not called */}
          {(!called || loading) && <LoadingSpinner size="sm" />}
          {called && !loading && !aliases?.length && <strong>No Aliases</strong>}
          {called && !loading && !!aliases?.length && (
            <>
              <div>
                <strong>Aliases:</strong>
                <strong>({aliases?.length})</strong>
                <span> - Click to Copy</span>
              </div>
              <ul className="m-0">{aliases?.map?.(({ name }: Alias, a: number): JSX.Element => <li key={a}>{name}</li>)}</ul>
            </>
          )}
        </>
      }
      placement="right"
    >
      <button onClick={aliases?.length ? (): Promise<void> => navigator.clipboard.writeText(aliases?.join('\n') || '') : undefined}>
        <i
          className="fa fa-circle-info pointer d-print-none ms-2 {opacity:0.4;}"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </button>
    </Tooltip>
  );
};

export default React.memo(LocationCell);
