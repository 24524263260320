import { FilterOption } from '@/models/gen/graphql';
import { GetRolesDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetRolesGraphApiResponse = GraphApiResponse<typeof GetRolesDocument>;

const getRolesResponseSelector = (res: GetRolesGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.roles as Array<FilterOption>;

const [runGetRoles] = graphApi(GetRolesDocument, {
  onError: (res: GetRolesGraphApiResponse): void => handleError(res, { notification: { title: 'Get Roles' } }),
});

export const getRoles = async (): Promise<Array<FilterOption>> => {
  const res = await runGetRoles();
  return getRolesResponseSelector(res);
};

export const useGetRoles = createGraphApiHook(getRoles);
