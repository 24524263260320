import { applyChange, observableDiff } from 'deep-diff';
import { decrypt, encrypt } from '@/utils/strings';

import { Validation } from './validations';

export const getProperty = (
  key: string = '',
  data: any = {},
  defaultValue?: any,
  nullCheck: (val: unknown) => boolean = Validation.isNil
): any => {
  if (typeof data !== 'object') {
    try {
      data = JSON.parse(data);
    } catch (err) {
      console.warn(err.message || err);
    }
  }
  const sections = key.split('|');
  let s;
  let result;
  for (s = 0; s < sections.length; s++) {
    const parts: string[] = sections[s].split('.');
    let i: number;
    let property: any = data;
    for (i = 0; i < parts.length; i++) {
      const part: string = (parts || [])[i];
      const literal: string[] = part.match(/\(([^)]+)\)/);
      if (!!literal && !!literal[1]) {
        result = literal[1];
        break;
      }
      if (Array.isArray(property) && part.startsWith('-')) {
        property = property.at(parseInt(part));
      } else {
        property = (property || {})[part];
      }
      result = property;
    }
    if (!nullCheck(result)) break;
  }
  if (nullCheck(result)) return defaultValue;
  return result;
};
export const setProperty = <T = any, U = any>(obj = {} as U, key: string = 'undefined', value: T): U => {
  try {
    obj = JSON.parse(JSON.stringify(obj));
  } catch (err) {
    return obj;
  }
  let result: any = { ...obj };
  if (Array.isArray(obj)) result = [...obj];
  const keys: string[] = key.split('.');
  const [head, ...next]: string[] = keys;
  if (next.length === 0) {
    result[head] = value;
  } else {
    result[head] = setProperty(result[head] || {}, next.join('.'), value);
  }
  return result;
};
export const getDiff = (original: any, update: any): any => {
  let fullChange: any = stringify.parse(original);
  const changes: any = [];
  let partial: any = {};
  observableDiff(fullChange, update, (change) => {
    changes.push(change);
    try {
      applyChange(fullChange, update, change);
    } catch (err) {
      // This is to handle an error in the deep-diff library due to the way that it
      // handles applying changes on Arrays. There is no fix, other than to catch it
      // and handle it ourselves.
      const { path, rhs } = change;
      fullChange = setProperty(fullChange, path.join('.'), rhs);
    }
  });
  changes.forEach((change) => {
    const { path, rhs } = change;
    partial = setProperty(partial, path.join('.'), rhs);
  });
  return [partial, fullChange, original];
};

/**
 * This function takes an input 'x' and converts it into a string representation.
 * It follows certain rules:
 * - If 'x' is null or has been seen before, it returns the string 'null'.
 * - If 'x' is a function, it returns its string representation.
 * - If 'x' is not an object, it uses JSON.stringify to convert it into a string.
 * - If 'x' has a toJSON method, it recursively calls 'stringify' on the result of toJSON.
 * - If 'x' is an array, it iterates over each element and recursively calls 'stringify' on each element, then joins them with commas and wraps them in square brackets.
 * - If 'x' is an instance of the File or Blob constructors, it returns the string 'null'.
 * - If 'x' is an object, it sorts the keys of 'x' and recursively calls 'stringify' on each value, then joins them with colons and wraps them in curly braces.
 * - If 'x' doesn't have any keys and has a constructor that is not Object, it generates a random key and returns the result of recursively calling 'stringify' on an object with the key as '__key'.
 */

const stringify = (x: any): string => {
  // Create a set to keep track of seen values
  const seen = new Set();
  // Create a map to cache values
  const cache = new Map();

  // Function to convert a value to a string
  const stringifyValue = (value: any): string => {
    // If the value is null or has been seen before, return 'null'
    if (value === null || seen.has(value)) {
      return 'null';
    }
    // If the value is a function, return its string representation
    else if (typeof value === 'function') {
      return value.toString().replace(/\s+/g, ' ') || '';
    }
    // If the value is not an object, return its JSON string representation
    else if (typeof value !== 'object') {
      return JSON.stringify(value) || '';
    }
    // If the value has a 'toJSON' method, call it recursively
    else if (value.toJSON) {
      return stringifyValue(value.toJSON());
    }
    // If the value is an array, map each element to its string representation and join them with commas
    else if (Array.isArray(value)) {
      const out = value.map(stringifyValue).join(',');
      return `[${out}]`;
    }
    // If the value is an instance of File or Blob, return 'null'
    else if (
      (FileConstructor !== NoopConstructor && value instanceof FileConstructor) ||
      (BlobConstructor !== NoopConstructor && value instanceof BlobConstructor)
    ) {
      return 'null';
    }
    // For other objects, sort the keys, stringify each key-value pair, and join them with commas
    else {
      const keys = Object.keys(value).sort();
      // If the object is empty and has a constructor other than Object, generate a unique key and return its string representation
      if (!keys.length && value.constructor && value.constructor !== Object) {
        const key = cache.get(value) || Math.random().toString(36).slice(2);
        cache.set(value, key);
        return stringifyValue({ __key: key });
      }
      // Add the current value to the set of seen values
      seen.add(value);
      // Stringify each key-value pair and filter out empty strings, then join them with commas
      const out = keys
        .map((key: string): string => {
          const result = stringifyValue(value[key]);
          if (result) {
            return `${stringifyValue(key)}:${result}`;
          }
          return '';
        })
        .filter(Boolean)
        .join(',');
      // Remove the current value from the set of seen values
      seen.delete(value);
      return `{${out}}`;
    }
  };

  // Return the string representation of the input value
  return stringifyValue(x);
};

stringify.compare = (x: any, y: any): boolean => stringify(x) === stringify(y);
stringify.parse = (x: any): any => {
  try {
    return JSON.parse(stringify(x));
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
export { stringify };

class NoopConstructor {}
const FileConstructor = typeof File !== 'undefined' ? File : NoopConstructor;
const BlobConstructor = typeof Blob !== 'undefined' ? Blob : NoopConstructor;

export const parseObj = (obj) => {
  if (!obj || typeof obj !== 'object') return obj;
  const { children = [], values = [], ...input } = obj;
  const result = Array.isArray(obj) ? [] : {};
  Object?.entries?.(input).forEach(([key, value]) => {
    if (!!key && value !== undefined && value !== null && value !== '') result[key] = parseObj(value);
  });
  if (children?.length) result['children'] = children?.map?.((child) => parseObj(child));
  if (values?.length) {
    result['values'] = {};
    values?.forEach?.(({ key, value }) => {
      result['values'][key] = value;
    });
  }
  const isObjectMap = Array.isArray(obj) && !obj.map(({ key }) => key !== undefined).includes(false);
  if (isObjectMap) {
    const result = {};
    obj?.map?.(({ key, value = '', name, description }) => {
      result[key] = { name, description, value: parseObj(value) };
    });
    return result;
  }
  return result;
};

export const encryptObj = (obj: Record<string, unknown>): string => {
  try {
    return encodeURIComponent(encrypt(JSON.stringify(obj)));
  } catch (err) {
    console.error('Failed to encrypt token:', err);
  }
};
export const decryptObj = (token: string): Record<string, unknown> => {
  try {
    if (!token) return {};
    return JSON.parse(decrypt(token));
  } catch (err) {
    console.error('Failed to parse decrypted token:', err);
  }
};

export const formatObjectToModel = (originObj: Record<string, unknown>, model: Record<string, unknown>): Record<string, unknown> => {
  // NOTE: 'undefined' values will be removed from object/model during stringification, use 'null' instead
  originObj = stringify.parse(originObj);
  model = stringify.parse(model);
  if (!Object.keys(originObj || {}) || !Object.keys(model || {})) return {};
  return Object.keys(originObj).reduce((acc, fieldName) => {
    if (Object.keys(model).includes(fieldName)) acc[fieldName] = originObj[fieldName];
    return acc;
  }, model);
};

export const findDeepestChildrenLevel = <T>(array: T[], item: string): number => {
  const helper = (objects: T[], depth: number): number => {
    let maxDepth = depth;
    objects.forEach((obj) => {
      const searchedItem = obj[item];
      if (searchedItem) {
        if (Array.isArray(searchedItem)) {
          const childrenDepth = helper(searchedItem, depth + 1);
          if (childrenDepth > maxDepth) {
            maxDepth = childrenDepth;
          }
        } else if (searchedItem === null || searchedItem === undefined) {
          const childrenDepth = depth + 1;
          if (childrenDepth > maxDepth) {
            maxDepth = childrenDepth;
          }
        }
      }
    });
    return maxDepth;
  };
  return helper(array, 1);
};

// TODO: Type this out
export const getValuesAtDepth = (data: any[], targetDepth: number, currentDepth: number = 1): any => {
  const result: { [key: string]: any[] } = {};

  if (!Array.isArray(data)) {
    console.error('Data is not an array:', data);
    return result;
  }

  if (currentDepth === targetDepth) {
    data.forEach((item) => {
      if (item.values && item.children) {
        const parentGroup = item.values.group;
        result[parentGroup] = item.children.map((child) => child.values);
      }
    });
  } else {
    data.forEach((item) => {
      if (item.children) {
        const childValues = getValuesAtDepth(item.children, targetDepth, currentDepth + 1);
        Object.keys(childValues).forEach((key) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key] = result[key].concat(childValues[key]);
        });
      }
    });
  }

  return result;
};
