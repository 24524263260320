import { FilterOption } from '@/models/gen/graphql';
import { GetImportProviderDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetImportProviderGraphApiResponse = GraphApiResponse<typeof GetImportProviderDocument>;

const getImportProviderResponseSelector = (res: GetImportProviderGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.importProvider as Array<FilterOption>;

const [runGetImportProvider] = graphApi(GetImportProviderDocument, {
  onError: (res: GetImportProviderGraphApiResponse): void => handleError(res, { notification: { title: 'Get Import Provider' } }),
});

export const getImportProvider = async (): Promise<Array<FilterOption>> => {
  const res = await runGetImportProvider();
  return getImportProviderResponseSelector(res);
};

export const useGetImportProvider = createGraphApiHook(getImportProvider);
