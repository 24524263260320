import { Button, Col, Row } from 'react-bootstrap';
import { Datetime, createNotification } from '@/utils';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import EditModal from '@/components/EditModal/new';
import { RunRateOverrideInput } from '@/models/gen/graphql';
import { Toast } from '@/models';
import applyRateOverride from '@/api/services/rates/runRateOverride';
import getRateOverridePreview from '@/api/services/rates/runRateOverridePreview';

export type OverrideModalState = {
  loading: boolean;
  totalTrips: number;
  totalAmount: number;
  newAmount: number;
};
export type OverrideModalProps = { data: RunRateOverrideInput; onHide: () => void; onCancel: () => void };

const OverrideModal = ({ data, onHide, onCancel }: OverrideModalProps): ReactNode => {
  const [{ loading, totalTrips, totalAmount, newAmount }, setState] = useState<OverrideModalState>({
    loading: true,
    totalTrips: undefined,
    totalAmount: undefined,
    newAmount: undefined,
  });

  const handleGetOverridePreview = useCallback(
    async (data: RunRateOverrideInput): Promise<void> => {
      try {
        const { currency: _c, rateGroupId: _r, ...payload } = data;
        const result = await getRateOverridePreview({
          ...payload,
          startDatetime: new Datetime(payload?.startDatetime)?.toString(),
          endDatetime: new Datetime(payload?.endDatetime)?.endOf('day')?.toString(),
        });
        const { totalTrips, totalAmount, newAmount } = result || {};
        if (!totalTrips) throw new Error('No trips found.');
        setState((current: OverrideModalState): OverrideModalState => ({ ...current, loading: false, totalTrips, totalAmount, newAmount }));
      } catch (err) {
        createNotification('Nothing to override', Toast.Type.WARNING, 'Override Rates');
        console.error(err.message || err);
        onHide();
      }
    },
    [onHide]
  );

  const handleSubmit = async (): Promise<void> => {
    setState((current: OverrideModalState): OverrideModalState => ({ ...current, loading: true }));
    try {
      await applyRateOverride({
        ...data,
        startDatetime: new Datetime(data?.startDatetime)?.toString(),
        endDatetime: new Datetime(data?.endDatetime)?.endOf('day')?.toString(),
      });
    } catch (err) {
      createNotification('Could not override rate', Toast.Type.DANGER, 'Override Rates');
      console.error(err.message || err);
    } finally {
      onHide();
    }
  };

  useEffect((): void => {
    if (!data) return;
    handleGetOverridePreview(data);
  }, [data, handleGetOverridePreview]);

  return (
    <EditModal
      show={!!data}
      name="overrideRatesModal"
      title={'Override Rates'}
      loading={loading}
      options={{
        Footer: (
          <Row>
            <Col>
              <Button className="w-100" variant="secondary" onClick={onCancel}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button className="w-100" variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        ),
      }}
    >
      {!loading && !!totalTrips && (
        <div className="text-danger">
          Warning! You’re about to override the rate for {totalTrips || 0} Trips with total value of ${totalAmount || '0.00'}. The new total
          value will be ${newAmount || '0.00'}. Are you sure?
        </div>
      )}
    </EditModal>
  );
};

export default OverrideModal;
