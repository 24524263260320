import { ReactNode, useState } from 'react';
import useSession, { SessionUser } from '@/state/session';

import { Button } from 'react-bootstrap';
import EditProfileModal from '@/components/EditUsersModal/EditProfileModal';
import ImageDisplay from '@/components/ImageDisplay';
import { titleCase } from '@/utils';

const NavigationMenuHeader = (): ReactNode => {
  const user = useSession(({ state }) => state.user || ({} as SessionUser));
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div className="d-flex gap-2">
      <ImageDisplay src={user?.avatar} />
      <div className="d-flex flex-column flex-grow-1">
        <small>{`${user.firstName || ''} ${user.lastName || ''}`}</small>
        <small className="fs-6">
          <em>{titleCase(user.role?.name)}</em>
        </small>
        <Button className="p-0 text-start" variant="text" onClick={() => setOpen(true)}>
          Edit Profile
        </Button>
      </div>
      <EditProfileModal show={open} onHide={() => setOpen(false)} />
    </div>
  );
};

export default NavigationMenuHeader;
