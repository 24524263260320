import './styles.scss';

import { Datetime, Validation } from '../../utils';
import EditAvailabilityPopover, { AvailabilityAction } from '../EditAvailabilityPopover';
import React, { ComponentProps, ReactNode, useMemo, useState } from 'react';
import { User, UserAvailability, UserAvailabilityTypeEnum } from 'models/gen/graphql';

import DeleteAvailabilityConfirmationModal from '../DeleteAvailabilityConfirmationModal';
import { getClasses } from '../../utils/strings';

type State = {
  deleting: boolean;
  availabilityEndsNextDay: boolean;
  deleteAvailability: UserAvailability;
  availabilities: UserAvailability[];
  previousDayAvailabilities: UserAvailability[];
  fillDayAvailabilities: UserAvailability[];
};
type Props = {
  date: string;
  user: User;
  index: number;
  refetchParent?: Function;
  setUserData?: (action: Partial<AvailabilityAction>) => void;
};

const initEditAvailabilityState: State = {
  deleting: false,
  availabilityEndsNextDay: false,
  deleteAvailability: undefined,
  availabilities: [],
  previousDayAvailabilities: [],
  fillDayAvailabilities: [],
};

const AvailabilityDisplay = ({
  date: initDate,
  index,
  user,
  refetchParent = async (): Promise<void> => {},
  setUserData = (): void => {},
}: Props): JSX.Element => {
  // Init State
  const [state, setState] = useState(initEditAvailabilityState);
  const { deleting, deleteAvailability } = state;

  // state Handler
  const onHide = (): void => {
    setState((current: State): State => ({ ...current, deleting: !current.deleting, deleteAvailability: undefined }));
    refetchParent();
  };
  const onCancel = (): void => {
    setState((current: State): State => ({ ...current, deleting: !current.deleting, deleteAvailability: undefined }));
  };

  const date = new Datetime(initDate).add(index, 'days').dateInput;
  const dateIsBeforeToday = new Datetime(date).asDayjs().isBefore(new Datetime().asDayjs(), 'day');

  const { availabilities, previousDayAvailabilities, fillDayAvailabilities, availabilityEndsNextDay } = useMemo(() => {
    let availabilityEndsNextDay = false;
    const availabilities = [];
    const previousDayAvailabilities = [];
    const fillDayAvailabilities = [];

    for (let i = 0; i < user.availability.length; i++) {
      const availability = user.availability[i];
      const startDateTime = new Datetime(availability.startDate).setTime(availability.startTime);
      const endDateTime = new Datetime(availability.endDate).setTime(availability.endTime);

      // if the availability spans over day
      if (startDateTime.asDayjs().isBefore(`${date} 00:00:00`) && endDateTime.asDayjs().isAfter(`${date} 23:59:59`)) {
        fillDayAvailabilities.push(availability);
        continue;
      }

      // If the availability is previous day
      if (startDateTime.asDayjs().isBefore(date) && endDateTime.asDayjs().isSame(date, 'day')) {
        previousDayAvailabilities.push(availability);
        continue;
      }

      // If the availability is the same day
      if (startDateTime.asDayjs().isSame(date, 'day')) {
        if (endDateTime.asDayjs().isAfter(`${date} 23:59:59`)) {
          availabilityEndsNextDay = true;
        }
        availabilities.push(availability);
        continue;
      }
    }
    return { availabilities, previousDayAvailabilities, fillDayAvailabilities, availabilityEndsNextDay };
  }, [user.availability, date]);

  const hasAvailabilities = !!availabilities.length;
  const hasPreviousDayAvailabilities = !!previousDayAvailabilities.length;
  const hasFillDayAvailabilities = !!fillDayAvailabilities.length;
  const totalAvailabilityCount = availabilities.length + previousDayAvailabilities.length + fillDayAvailabilities.length;

  const classes = useMemo((): string => {
    return getClasses(
      'AvailabilityDisplay',
      hasFillDayAvailabilities || (availabilityEndsNextDay && hasPreviousDayAvailabilities)
        ? 'extend'
        : hasPreviousDayAvailabilities
          ? 'extend-left'
          : availabilityEndsNextDay
            ? 'extend-right'
            : undefined,
      !dateIsBeforeToday ? 'show-create-icon' : undefined
    );
  }, [hasFillDayAvailabilities, availabilityEndsNextDay, hasPreviousDayAvailabilities, dateIsBeforeToday]);

  const fullBlocks = useMemo(
    (): ReactNode =>
      fillDayAvailabilities.map(
        (availability: UserAvailability, idx: number): JSX.Element => (
          // Edit Previous Day Availability
          <EditAvailabilityPopover
            key={`fillDay_${idx}`}
            onHide={(): void => refetchParent()}
            onCancel={onCancel}
            data={{
              availability,
              user,
            }}
            date={date}
            onDelete={(): void => {
              setState((current: State): State => ({ ...current, deleting: true, deleteAvailability: availability }));
            }}
            setUserData={setUserData}
          >
            <AvailabilityDisplayText
              approved={Validation.isNil(availability?.approved)}
              startDate={availability?.startDate}
              startTime={availability?.startTime}
              endDate={availability?.endDate}
              endTime={availability?.endTime}
              type={availability?.type}
              repeatUntil={availability?.repeatUntil}
              date={date}
              fillDay
              className={`{height:calc(100%|/|${totalAvailabilityCount});}`}
            />
          </EditAvailabilityPopover>
        )
      ),
    [date, fillDayAvailabilities, refetchParent, totalAvailabilityCount, user]
  );
  const pastBlocks = useMemo(
    (): ReactNode =>
      previousDayAvailabilities.map(
        (availability: UserAvailability, idx: number): JSX.Element => (
          // Edit Previous Day Availability
          <EditAvailabilityPopover
            key={`previousDay_${idx}`}
            onHide={(): void => refetchParent()}
            onCancel={onCancel}
            data={{
              availability,
              user,
            }}
            date={date}
            onDelete={(): void => {
              setState((current: State): State => ({ ...current, deleting: true, deleteAvailability: availability }));
            }}
            setUserData={setUserData}
          >
            <AvailabilityDisplayText
              approved={Validation.isNil(availability?.approved)}
              startDate={availability?.startDate}
              startTime={availability?.startTime}
              endDate={availability?.endDate}
              endTime={availability?.endTime}
              type={availability?.type}
              repeatUntil={availability?.repeatUntil}
              date={date}
              previousDay
              className={`{height:calc(100%|/|${totalAvailabilityCount});}`}
            />
          </EditAvailabilityPopover>
        )
      ),
    [date, previousDayAvailabilities, refetchParent, totalAvailabilityCount, user]
  );
  const availabilityBlocks = useMemo(
    (): ReactNode =>
      availabilities.map(
        (availability: UserAvailability, idx: number): JSX.Element => (
          // Edit Day Availability
          <EditAvailabilityPopover
            key={`availability_${idx}`}
            onHide={(): void => refetchParent()}
            onCancel={onCancel}
            data={{ availability, user }}
            date={date}
            onDelete={(): void => {
              setState((current: State): State => ({ ...current, deleting: true, deleteAvailability: availability }));
            }}
            setUserData={setUserData}
          >
            <AvailabilityDisplayText
              approved={Validation.isNil(availability?.approved)}
              startDate={availability?.startDate}
              startTime={availability?.startTime}
              endDate={availability?.endDate}
              endTime={availability?.endTime}
              type={availability?.type}
              repeatUntil={availability?.repeatUntil}
              date={date}
              className={`{height:calc(100%|/|${totalAvailabilityCount});}`}
            />
          </EditAvailabilityPopover>
        )
      ),
    [availabilities, date, refetchParent, totalAvailabilityCount, user]
  );
  const content = useMemo(
    (): ReactNode => (
      <div className={classes}>
        {hasFillDayAvailabilities || hasPreviousDayAvailabilities || hasAvailabilities ? (
          <>
            {!dateIsBeforeToday && (
              // Plus button Create availability Display in Alert
              <EditAvailabilityPopover
                onHide={(): void => refetchParent()}
                onCancel={onCancel}
                data={{ user }}
                date={date}
                onDelete={undefined}
                setUserData={setUserData}
              >
                <i className="sv sv-plus-square" />
              </EditAvailabilityPopover>
            )}
            {fullBlocks}
            {pastBlocks}
            {availabilityBlocks}
          </>
        ) : !dateIsBeforeToday ? (
          <EditAvailabilityPopover
            onHide={(): void => refetchParent()}
            onCancel={onCancel}
            data={{ user }}
            date={date}
            onDelete={undefined}
            setUserData={setUserData}
          >
            <i className="sv sv-plus-square sv-2x" />
          </EditAvailabilityPopover>
        ) : null}
      </div>
    ),
    [
      availabilityBlocks,
      classes,
      date,
      dateIsBeforeToday,
      fullBlocks,
      hasAvailabilities,
      hasFillDayAvailabilities,
      hasPreviousDayAvailabilities,
      pastBlocks,
      refetchParent,
      user,
    ]
  );

  return (
    <>
      {content}
      {/* Delete Availability Modal */}
      {!!Object.values(deleteAvailability || {}).length && (
        <DeleteAvailabilityConfirmationModal
          show={deleting}
          onHide={onHide}
          data={[deleteAvailability]}
          date={date}
          setUserData={setUserData}
        />
      )}
    </>
  );
};

type AvailabilityDisplayTextProps = ComponentProps<'div'> & {
  approved?: boolean;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  type?: UserAvailabilityTypeEnum;
  repeatUntil?: string;
  date: string;
  fillDay?: boolean;
  previousDay?: boolean;
};
const AvailabilityDisplayText = ({
  approved,
  startDate,
  startTime,
  endDate,
  endTime,
  type,
  repeatUntil,
  date,
  fillDay: isFillDay,
  previousDay: isPreviousDay,
  ...divProps
}: AvailabilityDisplayTextProps): React.JSX.Element => {
  const displayText = useMemo((): string => {
    const startDateTime = new Datetime(startDate).setTime(startTime);
    const endDateTime = new Datetime(endDate).setTime(endTime);
    let result = 'No Availability'; // technically this shouldn't be displayed
    if (isFillDay) result = ` 00:00 - 23:59`;
    else if (isPreviousDay) result = `00:00 - ${endDateTime.time}`;
    else result = `${startDateTime.time} - ${endDateTime.asDayjs().isAfter(`${date} 23:59:59`, 'day') ? '23:59' : endDateTime.time}`;
    return result;
  }, [startDate, startTime, endDate, endTime, isFillDay, isPreviousDay, date]);

  const classes = useMemo(
    (): string => getClasses('DisplayText', divProps?.className, getTextColor(approved, type, repeatUntil)),
    [approved, divProps?.className, repeatUntil, type]
  );

  return (
    <div {...divProps} className={classes}>
      {approved && <div>{type.toUpperCase()}</div>}
      {displayText}
    </div>
  );
};

const getTextColor = (approved: boolean, type: UserAvailabilityTypeEnum, repeatUntil: string): string => {
  if (approved) return 'bg-purple';
  if (type === UserAvailabilityTypeEnum.Off) return 'bg-red';
  if (repeatUntil) return 'bg-secondary';
  return 'bg-primary';
};

export default AvailabilityDisplay;
