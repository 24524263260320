import { ComponentStateType, InitComponentStateType } from '@/state';
import { generateQueryString, parseQueryString } from '@/utils/strings';

import { getResultFromState } from '@/utils/custom';

export const getInitState = <T>(initState: InitComponentStateType<T>, set, get) => {
  const setState = (val: T | ((current: T) => T)): void =>
    set((current: ComponentStateType<T>): ComponentStateType<T> => ({ ...current, state: getResultFromState(val, current.state) }));
  let state: T;
  let methods: Record<string, Function> = {};
  if (typeof initState === 'function') {
    const result = (initState as Function)(setState, get);
    if (Array.isArray(result)) {
      [state, methods] = result;
    } else {
      state = result;
    }
  } else {
    state = initState;
  }
  return {
    state,
    ...methods,
    setState,
  };
};
// we made this func so the current url is parsed the same
// as what it would be updated to so we can compare the values
export const getCurrentUrl = () => {
  const currentParsedQueryString = parseQueryString(window.location.search);
  const current = Object.entries(currentParsedQueryString).reduce((acc, [key, value]: [string, string]) => {
    acc[key] = value === 'undefined' ? '' : value.includes(',') ? value.split(',') : value;
    return acc;
  }, {});
  return formatUrl(undefined, current);
};
// standard url formatting func
export const formatUrl = (pathname: string = window.location.pathname, search?: Record<string, unknown>): string =>
  `${pathname}?${generateQueryString(search)
    .replace(/=undefined/, '')
    .replace(/=&/, '&')
    .replace(/=$/, '')}`;
