import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getRoles } from '@/api/services/filters/roles';
import { titleCase } from '@/utils';

type RoleDropdownProps = Omit<QueryDropdownProps, 'query'>;
const RoleDropdown = ({ name = 'roles', ...props }: RoleDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getRoles();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({
        label: titleCase(option.displayName), // TODO: Remove titleCase when BE implements proper casing
        value: option.id,
        keywords: option.displayName.split(' '),
      })
    );
  }, []);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Role' } }} />;
};

export default RoleDropdown;
