import { H } from 'highlight.run';
import Logger from './logs';
import { Validation } from './validations';
import axios from 'axios';
import { deleteCookies } from './strings';
import md5 from 'md5';
import { mergeEdges } from './custom';
import useSession from '@/state/session';

//TODO: Cypress errors says Logger is undefined
// const log = Logger.of('utils/promises');

export const sleep = (delay: number): Promise<void> => new Promise((resolve: () => void): NodeJS.Timeout => setTimeout(resolve, delay));
export const uploadFile = async (bundle: { file: any; url: string; contentType: string }, config: any = {}): Promise<any> => {
  try {
    if (!bundle.file) throw new Error('Nothing to upload.');
    if (!bundle.url) throw new Error('No url provided.');
    if (Validation.isNil(bundle.contentType)) {
      throw new Error('No contentType provided.');
    }
    const headers: any = {
      ...config.headers,
      'Content-Type': bundle.contentType,
    };
    const response: any = await axios.put(bundle.url, bundle.file, {
      ...config,
      headers,
    });
    return { ...response, file: bundle };
  } catch (err) {
    Logger.of('utils/promises').error(err.message || err);
    return err;
  }
};
/* LOGOUT
  The logout flow has been updated to clear the token and redirect regardless of any possible errors
  during the process. The promises have been reworked slightly use a "finally" callback that will
  fire even if the try/catch block throws an error.

  By handling logout this way, we can be sure that even if anything in the flow throws an error, the
  bare minimum of functionality will always happen: the session will be cleared and the user will be
  redirected to the login page.
*/
export const logout = async (saveLocation: boolean = true, msg?: string): Promise<void> => {
  try {
    Logger.of('utils/promises').warn('logging out...');
    if (saveLocation) window.localStorage.setItem('redirect', `${window.location.pathname}${window.location.search}`);
    if (window.location.search.includes('debug')) throw new Error('Unable to log out in debug mode.');
    else if (window.location.href.endsWith(`/login${window.location.search}`)) throw new Error('Already logged out.');
    H.stop();
  } catch (err) {
    Logger.of('utils/promises')
      .warn(err.message || err)
      .notify({ title: 'Logging Out' });
  } finally {
    await clearSession();
    window.location.href = `/login${msg ? `?msg=${msg}` : ''}`;
  }
};
export const clearSession = async (): Promise<void> => {
  try {
    Logger.of('utils/promises').warn('clearing session...');
    await clearCache(true);
  } catch (err) {
    Logger.of('utils/promises')
      .error("Couldn't clear user session: " + (err.message || err))
      .notify({ message: 'Failed to logout.', title: 'Session' });
  } finally {
    deleteCookies('token');
    useSession.setState((current) => ({ ...current, state: { ...current.state, user: undefined, token: undefined, config: undefined } }));
  }
};
export const clearCache = async (fullClear: boolean = false): Promise<void> => {
  try {
    const databases = await window.indexedDB.databases();
    await Promise.all(
      databases.map(async (database: IDBDatabaseInfo) => {
        await new Promise<void>((resolve: any, reject: any) => {
          const deleteRequest = window.indexedDB.deleteDatabase(database.name);
          deleteRequest.onsuccess = () => resolve();
          deleteRequest.onerror = () => reject();

          const timer = setTimeout(() => {
            resolve();
            clearTimeout(timer);
          }, 3000);
        });
      })
    );
  } catch (err) {
    Logger.of('utils/promises').warn(err.message || err);
  } finally {
    const appStorage = window.localStorage.getItem('persist:app');
    window.localStorage.clear();
    if (!fullClear && !!appStorage) window.localStorage.setItem('persist:app', appStorage);
  }
};
export const processFilesForUpload = (fileList: any[]): Promise<void> =>
  new Promise((resolve: (file: any) => void, reject: any): void => {
    const promises: Promise<void>[] = [];
    for (const file of fileList) {
      const filePromise: Promise<any> = new Promise((resolve: any): void => {
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (): void => resolve({ file, md5: md5(reader.result), progress: -1 });
        reader.onerror = reject;
      });
      promises.push(filePromise);
    }
    Promise.all(promises).then(resolve);
  });
export const saveFile = (url: string, name?: string): void => {
  const [filename] = url.substring(url.lastIndexOf('/') + 1).split('?');
  const a = document.createElement('a');
  a.href = url;
  a.download = name || filename;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const getAll =
  (searchQuery: any, fetchMore: any, main: string, sub: string): any =>
  async (first: number, after: string, query: any, ...rest: any[]): Promise<void> => {
    let called = false;
    let start = null;
    let end = null;
    let lastQuery = null;
    let args = [];
    const fn = async (first: number, after: string, query: any, ...rest: any[]): Promise<void> => {
      let res;
      if (!called) {
        res = await searchQuery(first, after, query, ...rest);
      } else {
        res = await fetchMore(
          start,
          end,
          lastQuery,
          {
            updateQuery: mergeEdges(main, sub),
          },
          ...args
        );
      }
      lastQuery = query;
      start = first;
      end = after;
      called = true;
      end = res?.endCursor || end;
      args = rest;
      if (res?.hasNextPage) return await fn(first, after, query, ...rest);
      return res;
    };
    return await fn(first, after, query, ...rest);
  };
