import './styles.scss';

import React, { useEffect } from 'react';

import { Activity } from 'models/gen/graphql';
import { Datetime } from '../../utils';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { useGetActivityForTrip } from '@/api/services/activity/SearchActivity';

const TripActivity = ({ list, tripId }: { list?: Activity[]; tripId?: string }): JSX.Element => {
  const [{ data: activity, loading }, { fetch }] = useGetActivityForTrip();

  useEffect(() => {
    if (!tripId || list?.length) return;
    fetch(tripId);
  }, [list, tripId, fetch]);

  return (
    <>
      <LoadingBlur loading={loading} />
      <ul className="list-unstyled mt-2 mb-5" style={{ maxHeight: '400px' }}>
        {(list || activity || [])
          .filter(({ message }: Activity): boolean => !!message)
          .map(
            ({ createdAt, message }: Activity, i: any): JSX.Element => (
              <li key={i}>{message.replace(/ ?(\(.+\))?$/, ` (${new Datetime(createdAt).asDayjs().utc(true).fromNow()})`)}</li>
            )
          )}
      </ul>
    </>
  );
};

export default TripActivity;
