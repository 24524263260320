import { Button, Col, Row } from 'react-bootstrap';
import { Datetime, Validation, createNotification, getBulkValues, queryInput } from '../../utils';
import { License, User } from '../../models/gen/graphql';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DATE_FE_FORMAT_FULL } from '../../constants';
import EditModal from '../EditModal/new';
import FormField from '../FormField';
import Logger from '../../utils/logs';
import PasswordInput from '../PasswordInput';
import PhoneInput from '../MaskedInput/PhoneInput';
import ProfileHeader from './ProfileHeader';
import SelectActiveStatus from '../SelectActiveStatus';
import SelectAirportGroupPermission from '../SelectAirportGroupPermission';
import SelectCompany from '../SelectCompany';
import SelectGroup from '../SelectGroup';
import SelectLicenseType from '../SelectLicenseType';
import SelectRole from '../SelectRole';
import SelectState from '../SelectState';
import { Toast } from '../../models';
import { stringify } from '../../utils/objects';
import useForm from '../../hooks/useForm';
import { useSearchUsers } from '@/api/services/users/searchUsers';
import useSession from '@/state/session';
import useUsers from '../../hooks/useUsers';
import useUuid from '../../hooks/useUuid';

const log = Logger.of('EditUsersModal');

interface Props {
  show: boolean;
  title?: string;
  onHide?: Function;
  onSubmit?: (update: any, original: User[]) => void;
  onExited?: Function;
  onCancel?: Function;
  data: any;
  options?: {
    selected?: string[]; // array of user ids will be used to query or default to data.id
  };
  loading?: boolean;
}

const initState = { loading: false };

const EditUsersModal = ({ show, onHide, onSubmit, onCancel, onExited, data: initData, options = {}, ...props }: Props): JSX.Element => {
  const original = useRef(initData);
  const [state, setState] = useState(initState);
  const [form, onChange, setForm] = useForm(initData); // form data state

  const [{ data, loading: loadingSearchUsers }, { refetch: searchUser }] = useSearchUsers();
  const user = (data?.rows || []).find((node: User): boolean => node.id === initData?.id);

  const loading = loadingSearchUsers || state?.loading;
  // Init GraphApi useUsers hook
  const [, { handleUpdateUserBulk, handleCreateUserBulk }] = useUsers();

  const driverRoleId = useSession(({ state }) => state.config?.driverRoleId || null);

  const mode = initData?.id ? 'edit' : 'create';
  const selected = data?.rows || [];
  const selectedCount = selected?.length || 0;
  const fields = useMemo((): any => (mode === 'edit' ? getBulkValues(selected) : undefined), [selectedCount, mode]);
  const uuid = useUuid();

  // Temp validation solution for v1.
  // TODO: Refactor to a global validator in the future.
  // NOTE: We should not use our existing validator utility as it has been causing performance issues.
  // A new validator will need to be scoped out and created after v1.
  const validations = useMemo(
    (): any => ({
      roleId: (val: any): boolean => Validation.isValidUUID(val),
      username: (val: any): boolean => !!val,
      password: (val: any): boolean => !!val,
      confirmPassword: (val: any, form: any): boolean => !!val?.length && val === form?.password,
      firstName: (val: any): boolean => !!val,
      lastName: (val: any): boolean => !!val,
      companyId: (val: any): boolean => Validation.isValidUUID(val),
      email: (val: any): boolean => Validation.isEmail(val),
      active: (val: any): boolean => Validation.isNumber(val),
      licenses: (val: any, form: any): boolean => {
        if (form?.roleId !== driverRoleId) return true;
        if (!val?.length) return true;
        const validLicenses = val?.map?.(
          (license: License): boolean =>
            !!license?.number &&
            new Date(license?.expirationDate || undefined).getTime() >= new Date().getTime() &&
            !!license?.stateCode &&
            !!license?.licenseTypeId
        );
        return !validLicenses.includes(false);
      },
    }),
    []
  );

  const handleSubmit = async (): Promise<void> => {
    try {
      setState({ loading: true });
      if (mode === 'edit') await handleUpdateUserBulk(form, selected);
      else await handleCreateUserBulk(form);
      if (onSubmit) await onSubmit(form, selected);
    } catch (err) {
      log.error(err);
    } finally {
      await onHide();
    }
  };

  const handleSearch = async (): Promise<void> => {
    try {
      if (!initData?.id || !show) return;
      const res = await searchUser([{ id: queryInput((options.selected || []).length ? options.selected : initData?.id) }], {
        pageSize: (options.selected || []).length || 1,
      });
      const user: User = res?.rows?.find((node: User): boolean => node.id === initData?.id);
      if (!user) return createNotification('User not found.', Toast.Type.WARNING, 'Search User');
      original.current = user;
      setForm(user);
    } catch (err) {
      log.error(err);
    }
  };

  useEffect((): void => {
    if (show) {
      handleSearch();
    } else {
      setState(initState);
      setForm(undefined);
    }
  }, [show]);

  // Set State Handlers
  const handleAddLicense = (): void => {
    setForm((current: any): any => ({
      ...current,
      licenses: [
        ...(current.licenses || []),
        {
          number: '',
          stateCode: '',
          expirationDate: '',
          licenseTypeId: '',
        },
      ],
    }));
  };
  const handleRemoveLicense = (index: number): void => {
    setForm((current: any): any => ({
      ...current,
      licenses: current.licenses.filter((license: License, a: number): boolean => a !== index),
    }));
  };

  const onTrimString = (event: any): any => onChange({ target: { name: event.target.name, value: event.target.value.trim() } });

  const title =
    props?.title || mode === 'edit' ? `${user?.firstName || 'unknown'} ${user?.lastName || ''}`.toUpperCase() : 'Create New User';
  const valid = useMemo((): { [key: string]: boolean } => {
    const result = {};
    Object.keys(validations)
      .filter((key: string): boolean => mode === 'create' || !key.toLowerCase().includes('password'))
      .forEach((key: string): void => {
        result[key] = validations[key]?.(form?.[key], form);
      });
    return result;
  }, [form]);

  return (
    <EditModal
      show={show}
      title={title}
      icon="fa fa-location-dot"
      size="xl"
      onHide={onHide}
      onCancel={onCancel}
      onExited={onExited}
      onSubmit={Object.values(valid).includes(false) || stringify.compare(original.current || {}, form || {}) ? false : handleSubmit}
      options={{ Header: mode === 'edit' ? <ProfileHeader data={user || initData} /> : undefined }}
      loading={props?.loading || loading}
      name={'editUsers'}
      changes={!stringify.compare(original.current || {}, form || {})}
    >
      {/* Form Inputs */}
      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={6}>
          {/* Left Section */}
          <FormField
            label="Type:"
            name="roleId"
            value={form?.roleId || ''}
            // TODO: Disable this field if the user does not have permission to edit roles
            disabled={(fields?.roleId || []).length > 1}
            onChange={onChange}
            placeholder="--"
            valid={Validation.isValidUUID(form?.roleId)}
            searchable
            required
            options={{
              input: {
                as: SelectRole,
              },
            }}
          />
          <FormField
            label="Username:"
            name="username"
            value={form?.username || ''}
            disabled={(fields?.username || []).length > 1}
            onChange={onTrimString}
            valid={!!form?.username}
            required
          />
          {/* // Display password field when creating a user */}
          {mode === 'create' && (
            <>
              <PasswordInput
                label="Password:"
                name="password"
                type="password"
                value={form?.password || ''}
                onChange={onChange}
                valid={!!form?.password}
                required
              />
              <PasswordInput
                label="Confirm Password:"
                name="confirmPassword"
                type="password"
                value={form?.confirmPassword || ''}
                onChange={onChange}
                valid={!!form?.confirmPassword?.length && form?.password === form?.confirmPassword}
                required
              />
            </>
          )}
          <FormField
            label="Firstname:"
            name="firstName"
            value={form?.firstName || ''}
            disabled={(fields?.firstName || []).length > 1}
            onChange={onTrimString}
            required
            valid={!!form?.firstName}
          />
          <FormField
            label="Lastname:"
            name="lastName"
            value={form?.lastName || ''}
            disabled={(fields?.lastName || []).length > 1}
            required
            valid={!!form?.lastName}
            onChange={onTrimString}
          />
          <FormField
            label="Company:"
            name="companyId"
            value={form?.companyId || ''}
            // TODO: Disable this field if the user does not have permission to edit roles
            disabled={(fields?.companyId || []).length > 1}
            onChange={onChange}
            placeholder="--"
            valid={Validation.isValidUUID(form?.companyId)}
            searchable
            options={{
              input: {
                as: SelectCompany,
              },
            }}
          />
          <FormField
            label="Full Address:"
            name="address"
            value={form?.address || ''}
            disabled={(fields?.address || []).length > 1}
            onChange={onChange}
          />
          <FormField
            label="City:"
            name="cityName"
            value={form?.cityName || ''}
            disabled={(fields?.cityName || []).length > 1}
            onChange={onChange}
          />
          <FormField
            label="State:"
            name="stateCode"
            value={form?.stateCode || ''}
            disabled={(fields?.stateCode || []).length > 1}
            onChange={onChange}
            placeholder="--"
            searchable
            options={{
              input: {
                as: SelectState,
              },
            }}
          />
          <FormField
            label="Zip:"
            type="number"
            name="zipCode"
            min="0"
            max="9999999999"
            value={form?.zipCode || ''}
            disabled={(fields?.zipCode || []).length > 1}
            onChange={onChange}
          />
          <FormField
            label="Medical Info:"
            type="textarea"
            name="notes"
            value={form?.notes || ''}
            onChange={onChange}
            maxLength={250}
            options={{
              input: {
                className: '{height:8rem;min-height:8rem!;}',
              },
            }}
          />
        </Col>
        {/* Right Section */}
        <Col xs={12} lg={6}>
          <FormField
            label="Employee ID:"
            name="employeeId"
            value={form?.employeeId || ''}
            disabled={(fields?.employeeId || []).length > 1}
            onChange={onTrimString}
            maxLength={16}
          />
          <FormField
            label="Email:"
            name="email"
            value={form?.email || ''}
            disabled={(fields?.email || []).length > 1}
            onChange={onTrimString}
            valid={Validation.isEmail(form?.email)}
            required
          />
          <FormField
            label="Phone:"
            name="phone"
            value={form?.phone || ''}
            disabled={(fields?.phone || []).length > 1}
            onChange={onChange}
            options={{
              input: {
                as: PhoneInput,
              },
            }}
          />
          <FormField
            label="Assigned Airports:"
            name="airportCodes"
            value={(form?.airportCodes || []).map((node) => node?.id || node)}
            disabled={(fields?.airportCodes || []).length > 1}
            onChange={onChange}
            placeholder="--"
            options={{
              input: {
                as: (props) => <SelectAirportGroupPermission {...props} multiple searchable />,
              },
            }}
          />
          <FormField
            label="Assigned Roles:"
            name="groups"
            value={(form?.groups || []).map((el: any): any => el.id || el)}
            disabled={(fields?.groups || []).length > 1}
            onChange={onChange}
            placeholder="--"
            multiple
            searchable
            options={{
              input: {
                as: SelectGroup,
              },
            }}
          />
          <FormField
            label="Birth Date:"
            type="date"
            name="dob"
            value={form?.dob ? new Datetime(form?.dob).dateInput : ''}
            onChange={onChange.date}
            format={DATE_FE_FORMAT_FULL}
          />
          <FormField
            label="Hire Date:"
            type="date"
            name="startDate"
            value={form?.startDate ? new Datetime(form?.startDate).dateInput : ''}
            onChange={onChange.date}
            format={DATE_FE_FORMAT_FULL}
          />
          <FormField
            label="Status:"
            name="active"
            value={form?.active}
            disabled={(fields?.active || []).length > 1}
            onChange={onChange.int}
            placeholder="--"
            valid={Validation.isNumber(form?.active)}
            options={{
              input: {
                as: SelectActiveStatus,
              },
            }}
          />
          {form?.roleId === driverRoleId && (
            <FormField
              label="Long Trip?"
              id={`longTrip_${uuid}`}
              type="switch"
              name="longTrip"
              checked={!!form?.longTrip}
              onChange={onChange.toggleInt}
              options={{
                group: {
                  className: 'd-flex align-items-center gap-2',
                },
              }}
            />
          )}
        </Col>
      </Row>
      {form?.roleId === driverRoleId && selectedCount <= 1 && (
        <Row>
          <Col>
            <h3 className="{border-bottom:1px|solid|grey;padding:.7rem|0;margin-bottom:1.5rem!;}">Licenses</h3>
            <div className="{overflow:auto;overflow-x:hidden;max-height:180px;}">
              {(form?.licenses || []).map(
                (license: License, idx: number): JSX.Element => (
                  <UserLicense
                    number={license?.number}
                    expirationDate={license?.expirationDate}
                    stateCode={license?.stateCode}
                    licenseTypeId={license?.licenseTypeId}
                    onChange={onChange}
                    onRemoveLicense={(): void => handleRemoveLicense(idx)}
                    idx={idx}
                    key={idx}
                  />
                )
              )}
              <UserLicense onAddLicense={handleAddLicense} />
            </div>
          </Col>
        </Row>
      )}
    </EditModal>
  );
};

const UserLicense = ({
  number = '',
  expirationDate = '',
  stateCode = '',
  licenseTypeId = '',
  idx,
  onChange,
  onAddLicense,
  onRemoveLicense,
}: {
  number?: string;
  expirationDate?: string;
  stateCode?: string;
  licenseTypeId?: string;
  idx?: number;
  onChange?: any;
  onAddLicense?: any;
  onRemoveLicense?: any;
}): JSX.Element => {
  const validNumber = !!number;
  const validExpirationDate = new Date(expirationDate || undefined).getTime() >= new Date().getTime();
  const validStateCode = !!stateCode;
  const validLicenseTypeId = !!licenseTypeId;

  return (
    <Row key={idx}>
      <Col className="d-flex justify-content-space-between gap-4 mb-2">
        {!!onRemoveLicense && (
          <FormField
            label="License Number:"
            name={`licenses.${idx}.number`}
            value={number}
            onChange={onChange}
            valid={validNumber}
            condensed
          />
        )}
        {!!onAddLicense && (
          <div className="w-100 mt-3">
            <Button className="d-block w-100 {white-space:nowrap!;}" variant="outline-success" onClick={onAddLicense}>
              <i className="sv sv-plus-square {font-size:1.25rem;}" />
              <span>Add License</span>
            </Button>
          </div>
        )}
        <FormField
          type="date"
          label="Exp Date:"
          name={`licenses.${idx}.expirationDate`}
          value={new Datetime(expirationDate || undefined).dateInput}
          valid={validExpirationDate}
          onChange={onChange?.date}
          condensed
          options={{
            group: {
              className: `{visibility:${!onRemoveLicense ? 'hidden' : 'visible'}!;}`,
            },
          }}
          format={DATE_FE_FORMAT_FULL}
        />
        <FormField
          name={`licenses.${idx}.stateCode`}
          value={stateCode}
          onChange={onChange}
          label="State:"
          valid={validStateCode}
          searchable
          options={{
            group: {
              className: `{visibility:${!onRemoveLicense ? 'hidden' : 'visible'}!;}`,
            },
            input: {
              as: SelectState,
            },
          }}
          condensed
        />
        <FormField
          name={`licenses.${idx}.licenseTypeId`}
          value={licenseTypeId}
          onChange={onChange}
          label="License Type:"
          valid={validLicenseTypeId}
          searchable
          options={{
            group: {
              className: `{visibility:${!onRemoveLicense ? 'hidden' : 'visible'}!;}`,
            },
            input: {
              as: SelectLicenseType,
            },
          }}
          condensed
        />
        <div className="d-flex flex-column justify-content-center {margin-top:0.9rem;}">
          <Button
            className="rounded {transform:scale(0.75);}"
            variant={onRemoveLicense ? 'outline-danger' : 'outline-success'}
            size="sm"
            onClick={onRemoveLicense || onAddLicense}
            style={{
              visibility: !onRemoveLicense ? 'hidden' : 'visible',
            }}
          >
            <i className={`sv sv-${onRemoveLicense ? 'minus' : 'plus'}`} />
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(EditUsersModal);
