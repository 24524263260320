import React, { useCallback, useMemo } from 'react';
import { SimpleTableHeaderProps, SortCell } from '@/components/SimpleTable';
import { SortDirectionEnum } from '@/models/gen/graphql';
import useAirlineTripSettings, { AirlineColumnKeyEnum } from '@/features/AirlineTrips/components/TripSettingsModal/hook';
import useAirlineTripTableState, { AirlineTripSortColumnEnum } from '@/features/AirlineTrips/components/TripsTable/hook';

const TripTableHeader = (_rows: SimpleTableHeaderProps): React.JSX.Element => {
  const columns = useAirlineTripSettings(({ state }) => new Map(state.columns));
  const sorting = useAirlineTripTableState(({ state }) => state.sorting);
  const onSort = useAirlineTripTableState(({ state }) => state.onSort);
  const onSortOnly = useAirlineTripTableState(({ state }) => state.onSortOnly);

  const columnSortMap = useMemo(
    () =>
      sorting.reduce((acc, curr) => {
        acc[curr.column] = curr.direction;
        return acc;
      }, {}) as Record<AirlineTripSortColumnEnum, SortDirectionEnum>,
    [sorting]
  );

  const handleSort = useCallback(
    (column: AirlineTripSortColumnEnum): React.MouseEventHandler<HTMLButtonElement> =>
      (event: React.MouseEvent<HTMLButtonElement>): void => {
        // is user holding shift key
        if (event.shiftKey) return onSort(column);
        return onSortOnly(column);
      },
    [onSort, onSortOnly]
  );
  return (
    <>
      {columns.get(AirlineColumnKeyEnum.TYPE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell onSort={handleSort(AirlineTripSortColumnEnum.type)} direction={columnSortMap[AirlineTripSortColumnEnum.type]}>
            TYPE
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_DATE) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.latestScheduled)}
            direction={columnSortMap[AirlineTripSortColumnEnum.latestScheduled]}
          >
            DATE
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.SCHEDULED_TIME) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(AirlineTripSortColumnEnum.latestScheduledUtc)} direction={columnSortMap.latestScheduledUtc}>
            SCH
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.ACTUAL) && (
        <div className="table-header-cell w-sm-grow">
          <SortCell onSort={handleSort(AirlineTripSortColumnEnum.actual)} direction={columnSortMap[AirlineTripSortColumnEnum.actual]}>
            ACT
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.AIRPORT_CODE) && (
        <div className="table-header-cell w-md-fixed">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.airportCode)}
            direction={columnSortMap[AirlineTripSortColumnEnum.airportCode]}
          >
            CITY
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.IATA_CODE) && (
        <div className="table-header-cell  w-sm-fixed">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.servicerIataAirlineCode)}
            direction={columnSortMap.servicerIataAirlineCode}
          >
            AL
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.FLIGHT_NUMBER) && (
        <div className="table-header-cell">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.flightNumber)}
            direction={columnSortMap[AirlineTripSortColumnEnum.flightNumber]}
          >
            FLT
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.PILOT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell onSort={handleSort(AirlineTripSortColumnEnum.pilots)} direction={columnSortMap[AirlineTripSortColumnEnum.pilots]}>
            PLT
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.ATTENDANT) && (
        <div className="table-header-cell w-sm-fixed">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.attendants)}
            direction={columnSortMap[AirlineTripSortColumnEnum.attendants]}
          >
            FA
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.PU_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.puLocationId)}
            direction={columnSortMap[AirlineTripSortColumnEnum.puLocationId]}
          >
            P/U
          </SortCell>
        </div>
      )}
      {columns.get(AirlineColumnKeyEnum.DO_LOCATION) && (
        <div className="table-header-cell w-lg-grow">
          <SortCell
            onSort={handleSort(AirlineTripSortColumnEnum.doLocationId)}
            direction={columnSortMap[AirlineTripSortColumnEnum.doLocationId]}
          >
            D/O
          </SortCell>
        </div>
      )}
    </>
  );
};
export default React.memo(TripTableHeader);
