import './styles.scss';

import {
  Dispatch,
  ReactNode,
  Ref,
  SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Grid, { GridCell, GridCellProps, GridProps, GridRow, GridRowProps } from '@/components/Grid';
import { getClasses, stringify, WithRequiredProperty } from '@/utils';
import useComplexForm, { ComplexFormItem } from '@/hooks/useComplexForm';

import { LoadingBlur } from '@/components/LoadingSpinner';
import TippyWhen from '@/components/TippyWhen';
import useLocale from '@/hooks/useLocale';
import useViewport from '@/hooks/useViewport';
import { useVirtualizer } from '@tanstack/react-virtual';

export type ComplexTableRef = {
  items: ComplexFormItem[];
  isValid: boolean;
  isDirty: boolean;
  onAdd: () => void;
  onReset: () => void;
  updates: ComplexFormItem[];
  additions: ComplexFormItem[];
  deletions: ComplexFormItem[];
  setAdditions: (additions: ComplexFormItem[]) => void;
  setUpdates: (updates: ComplexFormItem[]) => void;
  setDeletions: (deletions: ComplexFormItem[]) => void;
  ref: HTMLDivElement;
};

export type ComplexTableProps = Omit<GridProps, 'columns'> & {
  data: Record<string, unknown>[];
  columns?: string[];
  onChange: (items: ComplexFormItem[]) => void;
  header?: (props) => ReactNode;
  row: (props: ComplexTableRowRendererProps) => ReactNode;
  options?: {
    loading?: boolean;
    locale?: Record<string, string>;
    filter?: (item: ComplexFormItem) => boolean;
    sorting?: (items: ComplexFormItem[], col: number, dir: 'asc' | 'desc') => ComplexFormItem[];
    selected?: Record<string, boolean>;
    onSelect?: (id: string) => void;
  };
  metadata?: (
    item: Record<string, unknown>,
    {
      update,
      addition,
      deletion,
    }: { update: Partial<Record<string, unknown>>; addition: Partial<Record<string, unknown>>; deletion: Record<string, unknown> }
  ) => Record<string, any>;
};
export type ComplexTableRowRendererProps = {
  index: number;
  item: ComplexFormItem;
  data: Record<string, unknown>;
  getValue: (key: string) => any;
  onChange: (key: string) => (value: any) => void;
  onDelete: () => void;
  selected: boolean;
  onSelect: () => void;
};
export type ComplexTableState = {
  sorting: { col: undefined | number; dir: undefined | 'asc' | 'desc' };
};
const initComplexTableState: ComplexTableState = {
  sorting: { col: undefined, dir: undefined },
};
export const ComplexTableContext = createContext([initComplexTableState, (): void => {}] as [
  ComplexTableState,
  Dispatch<SetStateAction<ComplexTableState>>,
]);

const ComplexTable = (
  {
    data,
    columns,
    onChange: handleChange,
    header: HeaderRenderer,
    row: RowRenderer,
    metadata: getMetadata,
    options: {
      loading = false,
      locale: customLocale = {},
      filter,
      sorting = (i: ComplexFormItem[]): ComplexFormItem[] => i,
      selected = {},
      onSelect = (): void => {},
    } = {},
    ...divProps
  }: ComplexTableProps,
  ref: Ref<ComplexTableRef>
): ReactNode => {
  const [state, setState] = useState<ComplexTableState>(initComplexTableState);
  const [{ height: maxHeight }] = useViewport();
  const [
    { data: original, items, updates, additions, deletions },
    { getValue, onChange, onDelete, onAdd, onReset, setAdditions, setUpdates, setDeletions },
  ] = useComplexForm(data, getMetadata);
  const complexTableContext = useMemo(
    (): [ComplexTableState, Dispatch<SetStateAction<ComplexTableState>>] => [state, setState],
    [state, setState]
  );

  const isValid = useMemo(
    (): boolean =>
      [...updates, ...additions]
        .filter((item: ComplexFormItem): boolean => !!item?.data)
        .every((item: ComplexFormItem): boolean => item?.isValid !== false),
    [updates, additions]
  );
  const isDirty = useMemo(
    (): boolean => updates.length > 0 || additions.length > 0 || deletions.length > 0,
    [updates, additions, deletions]
  );
  const tableRef = useRef(null);
  const locale = useLocale(customLocale);

  const handleAdd = useCallback((): void => {
    onAdd();
    setTimeout((): void => {
      tableRef?.current?.scroll?.(0, tableRef?.current?.scrollHeight || 0);
    }, 100);
  }, [onAdd]);

  useImperativeHandle(
    ref,
    (): ComplexTableRef => ({
      items,
      isValid,
      isDirty,
      onAdd: handleAdd,
      onReset,
      updates,
      additions,
      deletions,
      ref: tableRef.current,
      setAdditions,
      setUpdates,
      setDeletions,
    }),
    [items, isValid, isDirty, handleAdd, onReset, updates, additions, deletions, setAdditions, setUpdates, setDeletions]
  );

  const lastChange = useRef([]);
  useEffect((): void => {
    if (stringify.compare(lastChange.current, items)) return;
    lastChange.current = items;
    handleChange(items);
  }, [handleChange, items]);

  const filteredItems = useMemo(
    (): ComplexFormItem[] => [
      ...sorting(
        items
          .filter(
            (item: ComplexFormItem): boolean =>
              !additions.find((addition: ComplexFormItem<Record<string, unknown>>): boolean => item?.index === addition?.index)
          )
          .filter((item: ComplexFormItem): boolean => item?.data !== null)
          .filter((item: ComplexFormItem): boolean => !filter || filter(item)),
        state.sorting.col,
        state.sorting.dir
      ),
      ...additions,
    ],
    [sorting, items, state.sorting.col, state.sorting.dir, additions, filter]
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredItems.length,
    getScrollElement: (): HTMLElement => tableRef.current,
    estimateSize: (): number => 45,
    overscan: 3,
  });

  return (
    <ComplexTableContext.Provider value={complexTableContext}>
      <div
        {...divProps}
        className={getClasses('ComplexTable', divProps.className)}
        style={{ maxHeight: divProps?.style?.maxHeight || maxHeight }}
      >
        <LoadingBlur loading={loading} passive={true} />
        <div className="ComplexTable-Container" style={{ maxHeight: divProps?.style?.maxHeight || maxHeight }} ref={tableRef}>
          <div className="ComplexTable-Header">
            <Grid className="ComplexTable-Row" columns={columns}>
              <HeaderRenderer selected={selected} onSelect={onSelect} />
            </Grid>
          </div>
          <div className="ComplexTable-Body" style={{ height: rowVirtualizer.getTotalSize() }}>
            {filteredItems.length === 0 && (
              <Grid className="ComplexTable-Row">
                <GridRow className="text-center">
                  <GridCell className="ComplexTable-Cell ComplexTable-Empty">
                    <i className="fa fa-inbox" />
                    <span>{locale('No Records')}</span>
                  </GridCell>
                </GridRow>
              </Grid>
            )}
            {rowVirtualizer.getVirtualItems().map((virtualRow): ReactNode => {
              const item: ComplexFormItem = filteredItems[virtualRow.index];
              return (
                <div
                  className="ComplexTable-Row"
                  ref={rowVirtualizer.measureElement}
                  style={{
                    height: virtualRow.size,
                    transform: `translateY(${virtualRow.start + 45}px)`,
                  }}
                  key={virtualRow.key}
                >
                  <Grid columns={columns}>
                    <RowRenderer
                      index={item.index}
                      item={item}
                      data={original.find((orig: ComplexFormItem): boolean => orig.index === item.index)?.data}
                      getValue={(key: string): any => getValue(item.index, key)}
                      onChange={(key: string): ((value: any) => void) => onChange(item.index, key)}
                      onDelete={onDelete(item.index)}
                      selected={selected[`${item.data?.id || item.index}`] || false}
                      onSelect={(): void => onSelect(`${item.data?.id || item.index}`)}
                    />
                  </Grid>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ComplexTableContext.Provider>
  );
};

export type ComplexTableHeaderProps = GridRowProps & {};
export const ComplexTableHeader = ({ ...complexTableHeaderProps }: ComplexTableHeaderProps): ReactNode => (
  <GridRow {...complexTableHeaderProps} className={getClasses('ComplexTable-Header', complexTableHeaderProps?.className)} />
);

export type ComplexTableRowProps = GridRowProps & {};
export const ComplexTableRow = ({ ...complexTableRowProps }: ComplexTableRowProps): ReactNode => (
  <GridRow {...complexTableRowProps} className={getClasses('ComplexTable-Cell', complexTableRowProps?.className)} />
);

export type ComplexTableCellProps = GridCellProps & {};
export const ComplexTableCell = ({ ...complexTableCellProps }: ComplexTableCellProps): ReactNode => (
  <GridCell {...complexTableCellProps} className={getClasses('ComplexTable-Cell', complexTableCellProps?.className)} />
);

export type ComplexTableSortableCellProps = WithRequiredProperty<ComplexTableCellProps, 'index'> & {};
export const ComplexTableSortableCell = ({ index, children, ...complexTableCellProps }: ComplexTableSortableCellProps): ReactNode => {
  const [state, setState] = useContext(ComplexTableContext);
  return (
    <ComplexTableCell {...complexTableCellProps}>
      <button
        className="ComplexTable-Sortable"
        onClick={(): void =>
          setState(
            (current: ComplexTableState): ComplexTableState => ({
              ...current,
              sorting: {
                col: state.sorting.col === index && current.sorting?.dir === 'desc' ? undefined : index,
                dir:
                  state.sorting.col !== index || current.sorting?.dir === undefined
                    ? 'asc'
                    : current.sorting?.dir === 'desc'
                      ? undefined
                      : 'desc',
              },
            })
          )
        }
      >
        <span className="flex-grow-1">{children}</span>
        <TippyWhen
          isTrue={state.sorting.col === index && state.sorting.dir !== undefined}
          options={{ content: state.sorting.dir === 'asc' ? 'Ascending' : 'Descending', delay: 500 }}
        >
          <span>
            <i
              className={getClasses(
                'fa',
                state.sorting.col !== index || state.sorting.dir === undefined ? 'fa-sort opacity-25' : `fa-sort-${state.sorting.dir}`
              )}
            />
          </span>
        </TippyWhen>
      </button>
    </ComplexTableCell>
  );
};

export default forwardRef(ComplexTable);
