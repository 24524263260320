import { StoreApi, UseBoundStore, create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { customLocalStorage, saveSearchStorage, searchStorage } from '@/state/storage';

import { getInitState } from '@/state/utils';

export type ComponentStateType<T> = {
  state: T;
  setState: (value: T | ((current: T) => T)) => void;
};
export type PersistComponentStateType<T> = {
  state: T;
  setState: (value: T | ((current: T) => T)) => void;
  saveState: () => void; // manually save what's in state to storage.
  rehydrate: () => Promise<void>; // manually refresh state to reflect what's in the storage.
};
export type PersistComponentOptions<T> = {
  live?: boolean; // determine if storage being updated automatically when state changes.
  reviver?: (key: string, value: unknown) => unknown; // used with JSON.parse to deserialize the data.
  replacer?: (key: string, value: unknown) => unknown; // used with JSON.stringify to serialize the data.
  merge?: (
    persistedState: Partial<PersistComponentStateType<T>>,
    currentState: PersistComponentStateType<T>
  ) => PersistComponentStateType<T>; // merge whats in storage with state.
};
export type InitComponentStateType<T> =
  | T
  | ((setState: (value: T | ((current: T) => T)) => void, getState: () => ComponentStateType<T>) => T | [T, Record<string, Function>]);

// createComponentState - state managed using standard zustand state management (not persisted)
export const createComponentState = <T>(initState: InitComponentStateType<T>): UseBoundStore<StoreApi<ComponentStateType<T>>> =>
  create<ComponentStateType<T>>((set, get): ComponentStateType<T> => getInitState(initState, set, get));

// createPersistentComponentState - state is managed by zustand and persisted via local storage
export const createPersistentComponentState = <T>(
  name: string,
  initState: InitComponentStateType<T>,
  options?: PersistComponentOptions<T>
) => {
  const storage = createJSONStorage(() => customLocalStorage(initState, options?.live !== false), {
    replacer: options?.replacer, // is a function that is passed to JSON.stringify to serialize the data
    reviver: options?.reviver, // is a function that is passed to JSON.parse to deserialize the data.
  });

  // zustand does a shallow merge by default since we are nesting the state
  // we need to use a custom default merge function to handle deeply nested values
  const merge =
    options?.merge ||
    ((persistedState: Partial<PersistComponentStateType<T>>, currentState) => ({
      ...((persistedState || {}) as Partial<PersistComponentStateType<T>>),
      ...currentState,
      state: {
        ...currentState.state,
        ...((persistedState.state || {}) as T),
      },
    }));

  return create<PersistComponentStateType<T>>()(
    persist(
      (set, get) => ({
        ...getInitState(initState, set, get),
        saveState: (): void => {
          const { state } = get();
          const result = JSON.stringify({ state: { state } }, options?.replacer);
          localStorage.setItem(name, result);
        },
        rehydrate: async (): Promise<void> => {
          try {
            const persistedState = (await storage.getItem(name)) as PersistComponentStateType<T>;
            set((current) => merge(persistedState, current));
          } catch (error) {
            console.error('createPersistentComponentState failed to rehydrate:', error);
          }
        },
      }),
      {
        name,
        storage,
        merge,
      }
    )
  );
};

// createComponentQueryState - state is managed by zustand and persisted via query string
export const createComponentQueryState = <T>(initState: T, options?: PersistComponentOptions<T>) => {
  const storage = createJSONStorage(() => searchStorage(initState, options?.live === true), {
    replacer: options?.replacer,
    reviver: options?.reviver,
  });

  const merge =
    options?.merge ||
    ((persistedState: Partial<PersistComponentStateType<T>>, currentState) => ({
      ...((persistedState || {}) as Partial<PersistComponentStateType<T>>),
      ...currentState,
      state: {
        ...currentState.state,
        ...((persistedState.state || {}) as T),
      },
    }));

  return create<PersistComponentStateType<T>>()(
    persist(
      (set, get) => ({
        ...getInitState(initState, set, get),
        saveState: () => {
          const { state } = get();
          saveSearchStorage(state);
        },
        rehydrate: async (): Promise<void> => {
          try {
            const persistedState = (await storage.getItem('query')) as PersistComponentStateType<T>;
            set((current) => merge(persistedState.state, current));
          } catch (error) {
            console.error('createComponentQueryState failed to rehydrate:', error);
          }
        },
      }),
      {
        name: 'query',
        storage,
        merge,
      }
    )
  );
};
