import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getKinds } from '@/api/services/filters/kinds';

type KindsDropdownProps = Omit<QueryDropdownProps, 'query'>;
const KindsDropdown = ({ name = 'client', ...props }: KindsDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getKinds();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Kind' } }} />;
};

export default KindsDropdown;
