import * as mocks from '@/api/mocks';
import * as queries from '../../queries';

import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  ApolloProvider,
  DefaultContext,
  FetchResult,
  GraphQLRequest,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
  createHttpLink,
  from,
} from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { getCookies, getErrors, handleError, logout } from '../../utils';

import { ApolloProviderProps } from '@apollo/client/react/context';
import { GraphApi } from '../../models';
import { GraphApiMockResponse } from '../../api/core';
import { GraphQLFormattedError } from 'graphql';
import { NetworkError } from '@apollo/client/errors';
import React from 'react';
import { RetryLink } from '@apollo/client/link/retry';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import config from '../../config.json';
import platform from 'platform';
import { setContext } from '@apollo/client/link/context';

/* Environment Config
  Dynamically set the environment based on the config file compiled through deploy process.
*/
const env = Object.entries(config.env || {}).reduce(
  (acc: string, [name = '', domains = []]: [string, string[]]): string => (domains.includes(window.location.host) ? name : acc),
  'local'
);

/* Request Endpoint
  Dynamically set the API URL based on the environment.
*/
const apiUrl = config['client-api-url'].replace(/\{\{env\}\}/g, env);
const httpLink = createHttpLink({
  uri: (operation: Operation): string => `${apiUrl}?${operation.operationName}`,
  credentials: 'include',
});

/* Request Authentication
  Here we initialize the headers and context for our requests in the Apollo Client.
*/
const authLink = setContext((_: GraphQLRequest<Record<string, any>>, { headers, ...previousContext }: DefaultContext): DefaultContext => {
  const { token } = getCookies('token');
  const authHeaders = token ? { authorization: `Bearer ${token}` } : {};
  const commonHeaders = {
    'sky-language': navigator.language || 'unknown',
    'sky-app': 'portal',
    'sky-app-version': config['hash'] || 'unknown',
    'sky-platform': platform.name || 'unknown',
    'sky-platform-version': platform.version || 'unknown',
    'sky-device-type': platform.os.toString() || 'unknown',
  };
  return {
    ...previousContext,
    headers: { ...headers, ...commonHeaders, ...authHeaders },
  };
});

/* Request Error Handling
  Here we handle all errors; both GraphQL and Network Errors.
  If it was a Network Error, This is only hit after the request finishes its retries.
*/
const errorLink = onError((error: ErrorResponse): void => {
  const logoutMessage = 'Your session has expired. Please log in again.';
  const { networkError, graphQLErrors } = error;
  if (graphQLErrors?.some((err: GraphQLFormattedError): boolean => err?.extensions?.code === 1016)) {
    logout(true, logoutMessage);
    return;
  }
  if (!networkError) return;
  // If we have an actual networkError, then something went wrong on the browser/server level.
  switch (networkError?.['statusCode']) {
    // Unauthorized: Log the user out.
    case undefined: // Getting here with an undefined statusCode means that it has already been retried multiple times.
    case 401: {
      logout(true, logoutMessage);
      break;
    }
    // Some other network error: handle it in the default case.
    default: {
      handleError(error);
      break;
    }
  }
});

const queryHandlers = {};
Object.values(queries).forEach((bundle: GraphApi.Query.Bundle): void => {
  const operationName = bundle?.query?.definitions?.[0]?.['name']?.['value'] || 'UnknownQuery';
  queryHandlers[operationName] = bundle?.handler || handleError;
});
const handleErrorsLink = new ApolloLink((operation: Operation, forward: NextLink): Observable<FetchResult<Record<string, any>>> => {
  const { operationName, variables } = operation;
  return forward(operation).map((data: FetchResult<Record<string, any>>): FetchResult<Record<string, any>> => {
    try {
      const gqlErrors = data?.errors || [];
      const responseErrors = getErrors(data);
      const errors = [...gqlErrors, ...responseErrors];
      const payload = {
        data: data?.data || data,
        errors: errors.length > 0 ? errors : undefined,
        variables: variables || {},
        operationName,
      };
      const options = {
        notification: {
          title: operationName,
        },
      };
      if (queryHandlers[operationName]) queryHandlers[operationName](payload, options);
    } catch (err) {
      handleError(err);
    }
    return data;
  });
});

/* Request Conditional Retry
  This type of link is only used for Network Errors.
*/
const retryLink = new RetryLink({
  delay: {
    initial: 1000,
  },
  attempts: {
    max: 3,
    retryIf: (networkError: NetworkError): boolean => {
      // Using a switch/case here so that we can expand on this concept in the future.
      // For example, if we want to retry on a 401 response, we can add it here.
      const status = networkError?.['statusCode'];
      switch (status) {
        case undefined: {
          // Something weird is happening, let's retry before logging the user out.
          console.warn(`Network Error (${status}): Retrying...`);
          return true;
        }
        default:
          return false;
      }
    },
  },
});

/* Local Link
  This link checks if the request exists in our mocks before returning the result.
  If it doesn't exist, it will continue down the link like normal.
*/
const localLink = new ApolloLink((operation: Operation, forward: NextLink): Observable<FetchResult<Record<string, any>>> => {
  if (window.location.search?.includes('debug') || window.location.host === 'localhost:5000') {
    const { query, variables } = operation;
    const match = Object.values(mocks)
      .sort((a: GraphApiMockResponse<TypedDocumentNode>) => (a?.request?.variables !== undefined ? -1 : 0))
      .find((mock: any): boolean => {
        const { query: mockedQuery, variables: checkVariables } = mock?.['request'] || {};
        const operationName = query?.definitions?.[0]?.['name']?.['value'];
        return mockedQuery && operationName && mockedQuery === operationName && (checkVariables === undefined || checkVariables(variables));
      });
    if (match) return Observable.of(match?.['result']);
  }
  return forward(operation);
});

/* Cache Object
  Setup the cache for Apollo.
*/
export const cache = new InMemoryCache({
  typePolicies: {
    SearchTripsResponse: { merge: true },
    TripConnection: { merge: true },
    TripTableConnection: { merge: true },
    SearchImportsResponse: { merge: true },
    ImportConnection: { merge: true },
  },
});

/* Apollo Client
  Setup the Apollo Client with the links and cache from above.
*/
export const apolloClient = new ApolloClient<NormalizedCacheObject>({
  link: from([localLink, authLink, errorLink, handleErrorsLink, retryLink, httpLink]),
  cache,
  connectToDevTools: true,
});

/* Apollo Root
  Setup the Apollo Provider for use in the App.
*/
const ApolloRoot = (props: Omit<ApolloProviderProps<InMemoryCache>, 'client'>): JSX.Element => (
  <ApolloProvider {...props} client={apolloClient} />
);

// TODO: When GraphApi migration is finished, remove this.
export const handleOnError = ({ networkError, graphQLErrors }: ApolloError): void =>
  handleError({
    errors: graphQLErrors.length ? graphQLErrors : undefined,
    networkError,
  });

export default ApolloRoot;
