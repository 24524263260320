import EditLocationModal, { EditLocationModalProps } from '@/components/EditLocationModal';

import { createComponentState } from '@/state';

type GlobalEditLocationModalState = Omit<EditLocationModalProps, 'title' | 'loading' | 'options' | 'onCancel'>;

const initState: GlobalEditLocationModalState = {
  show: false,
  data: undefined,
  onSubmit: undefined,
  onAfterSubmit: undefined,
  onHide: undefined,
};
export const useGlobalEditLocationModalState = createComponentState(initState);
const GlobalEditLocationModal = () => {
  const state = useGlobalEditLocationModalState(({ state }) => state);
  const setState = useGlobalEditLocationModalState(({ setState }) => setState);
  const handleHide = () => {
    if (state.onHide) state.onHide();
    setState(initState);
  };
  return <EditLocationModal show={state.show} onAfterSubmit={state.onAfterSubmit} onHide={handleHide} data={state.data} />;
};
export default GlobalEditLocationModal;
