import { Datetime, onEnter } from '../../utils';
import { GetTripsWithWrongContractedRateInput, UpdateRateOnTripInput } from '../../models/gen/graphql';
import React, { useRef, useState } from 'react';
import { TODAY, TODAY_EOD } from '../../constants';

import { AirportGroupDropdown } from '@/components/AirportDropdown';
import { Button } from 'react-bootstrap';
import ClientDropdown from '@/components/ClientDropdown';
import ContractedRatesTable from '../../components/ContractedRatesTable';
import DateRangePicker from '@/components/DateRangePicker';
import Filters from '../../components/Filters';
import FormField from '../../components/FormField';
import Portal from '../../components/Portal';
import { RouteProps } from 'react-router-dom';
import { TripWithRateChangeRow } from '../../api/services/rates/getCorrectRatesForTrips';
import { stringify } from '../../utils/objects';
import { updateRateOnTrips } from '../../api/services/rates/updateRateOnTrips';
import { useGetTripsWithWrongContractedRate } from '../../api/services/rates/getTripsWithWrongContractedRate';
import { useVirtualTable } from '../../components/VirtualTable';

interface Filters {
  search: string;
  scheduledStartDatetime: string;
  scheduledEndDatetime: string;
  payerProviderId: string[];
  airportCode: string[];
}

const initFilters: Filters = {
  search: '',
  scheduledStartDatetime: TODAY,
  scheduledEndDatetime: TODAY_EOD,
  payerProviderId: [],
  airportCode: [],
};
interface State {
  selected: string[];
}
const initState: State = {
  selected: [],
};
const ContractedRates = (_props: RouteProps) => {
  const [filters, setFilters] = useState(initFilters);
  const [state, setState] = useState(initState);
  const [{ data: rows = [], loading }, { fetch: getTripsWithWrongContractedRate, refetch }] = useGetTripsWithWrongContractedRate();
  const { search } = filters;
  const { selected } = state;
  const lastQuery = useRef(null);

  const onSearch = (filters) => {
    const { scheduledStartDatetime, scheduledEndDatetime, payerProviderId, airportCode } = filters || {};
    const payload: GetTripsWithWrongContractedRateInput = {
      scheduledStartDatetime: scheduledStartDatetime ? new Datetime(scheduledStartDatetime).toString() : null,
      scheduledEndDatetime: scheduledEndDatetime ? new Datetime(scheduledEndDatetime).toString() : null,
      payerProviderId: payerProviderId.length ? payerProviderId : null,
      airportCode: airportCode.length ? airportCode : null,
    };
    if (stringify.compare(payload, lastQuery.current)) {
      refetch(payload);
    } else {
      getTripsWithWrongContractedRate(payload);
    }
    lastQuery.current = payload;
    setState((current: State): State => ({ ...current, selected: [] }));
  };

  const { selectedRows, onSelect, filteredRows } = useVirtualTable(setState, {
    selected,
    rows,
    search,
  });

  return (
    <div className="ContractedRates page-container">
      <Filters
        name="clientsTable"
        onSubmit={onSearch}
        onReset={(): void => setFilters((current: Filters): Filters => ({ ...current, search: '' }))}
        value={filters}
        isValid={({ airportCode }: { airportCode: string[] }): boolean => !!airportCode.length}
        primary={({ values: { scheduledStartDatetime, scheduledEndDatetime, payerProviderId, airportCode }, onChange }) => (
          <>
            <div className="ContractRatesDateRange">
              <DateRangePicker
                name="dates"
                value={[scheduledStartDatetime, scheduledEndDatetime] as any}
                onChange={(event) => {
                  const [startDatetime, endDatetime] = event.target.value?.split?.(' - ') || [null, null];
                  onChange({ target: { name: 'scheduledStartDatetime', value: startDatetime } });
                  onChange({ target: { name: 'scheduledEndDatetime', value: endDatetime } });
                }}
              />
            </div>
            <ClientDropdown
              name="payerProviderId"
              value={payerProviderId}
              onChange={(value: string) => onChange({ target: { name: 'payerProviderId', value } })}
            />
            <AirportGroupDropdown
              name="airportCode"
              value={airportCode}
              onChange={(value: string[]) => onChange({ target: { name: 'airportCode', value } })}
              isInvalid={!airportCode.length}
              options={{
                locale: { 'Select...': 'Airport' },
              }}
            />
          </>
        )}
        controls={({ values, onChange }): JSX.Element => {
          const { search } = values;
          return (
            <>
              <FormField
                prepend={<i className="sv sv-magnifier fs-4" />}
                id={'filters-searchbar'}
                name="search"
                value={search || ''}
                onChange={onChange}
                onKeyDown={onEnter((): void => setFilters((current: Filters): Filters => ({ ...current, search })))}
                onBlur={(): void => setFilters((current: Filters): Filters => ({ ...current, search }))}
                placeholder="Search"
                condensed
                inline
              />
            </>
          );
        }}
        alternate={(): JSX.Element => {
          return (
            <>
              <Button
                name="RECTIFY_RATES"
                variant="outline-gray"
                onClick={async (): Promise<void> => {
                  const payload = selectedRows.map(
                    (row): UpdateRateOnTripInput => ({ tripId: row?.trip?.id, rateHistoryId: row?.newRate?.id })
                  );
                  if (!payload?.length) return;
                  await updateRateOnTrips(payload);
                  refetch(lastQuery.current);
                }}
              >
                Rectify Rates
              </Button>
            </>
          );
        }}
      />
      <ContractedRatesTable data={filteredRows} selected={selected} onSelect={onSelect} loading={loading} />
      <Portal container={document.getElementById('RouteContent-Footer')}>
        <ContractedRatesFooter rows={filteredRows} selected={selected} />
      </Portal>
    </div>
  );
};

export const ContractedRatesFooter = ({
  rows = [],
  selected = [],
}: {
  rows: TripWithRateChangeRow[];
  selected: string | string[];
}): JSX.Element => {
  const totalDifference: number = rows
    .filter((row: TripWithRateChangeRow): boolean => row?.id && selected.includes(row?.id))
    .reduce((acc: number, row: any): number => {
      const currentRate = row?.oldRate?.rate;
      const difference = row?.newRate?.rate ? row?.newRate?.rate - (currentRate || 0) : 0;
      return acc + difference;
    }, 0);
  return (
    <div className="w-100 position-relative {height:2rem;}">
      <div
        className={`p-2 text-end ${totalDifference === 0 ? '' : totalDifference > 0 ? 'text-success' : 'text-danger'}`}
        style={{
          position: 'absolute',
          width: '100%',
          backgroundColor: 'var(--bs-secondary-subtle)',
          borderTopLeftRadius: '0.63rem',
          borderTopRightRadius: '0.63rem',
          boxShadow: '1.05469px -2.10938px 2.10938px 0px rgba(0, 0, 0, 0.15)',
        }}
      >
        <strong>Total Difference: {totalDifference > 0 ? `+$${totalDifference?.toFixed(2)}` : `$${totalDifference?.toFixed(2)}`}</strong>
      </div>
    </div>
  );
};

export default ContractedRates;
