import { Datetime, getClasses } from '@/utils';
import { Import, ImportStatusEnum, ImportTypeEnum } from '@/models/gen/graphql';
import React, { useImperativeHandle, useMemo, useRef, useState } from 'react';
import VirtualTable, { DynamicCell, SelectCell, VirtualTableProps, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';

import FooterDrawer from '@/components/FooterDrawer';
import InlineImport from '@/pages/Manifests/InlineImport';
import PromiseButton from '@/components/PromiseButton';
import useViewport from '@/hooks/useViewport';

type Props = {
  addonAsDrawer?: boolean;
  selected?: string[];
  onSelect?: (id: string | string[]) => void;
  loading?: boolean;
  onSort?: (column: string) => void;
  onSubmitAddon?: (importId: string) => void;
  onDoubleClick?: (data: Import) => void;
  onDownload?: (id: string, name?: string) => Promise<void>;
  downloading?: boolean;
} & VirtualTableProps<Import>;

const header = {
  createdAt: 'Date',
  name: 'Name',
  statusDisplayName: 'Status',
  provider: { displayName: 'Provider' },
  importedBy: 'Imported By',
  email: { from: 'From', subject: 'Subject' },
  type: 'Type',
  download: <i className="sv sv-download2 {font-size:1.5rem;}" />,
};

type PendingImportsTableRef = {
  onExpand: (id: string) => void;
};

const PendingImportsTable = (
  { addonAsDrawer, data: rows, selected, onSelect, onDoubleClick, onDownload, onSort, onSubmitAddon, downloading, ...props }: Props,
  ref: React.ForwardedRef<PendingImportsTableRef>
): JSX.Element => {
  const tableRef = useRef(null);
  const [state, setState] = useState({
    expanded: [],
  });
  const { expanded } = state;
  const { onExpand, filteredRows } = useVirtualTable(setState, {
    expanded,
    rows,
  });

  useImperativeHandle(ref, () => ({
    onExpand: (id: string) => onExpand(id),
  }));

  const handleHideAddon = () => {
    setState((current) => ({ ...current, expanded: [] }));
  };

  const [{ height: viewportHeight }] = useViewport();

  const inlinePreviewHeight = useMemo(() => {
    if (!expanded.length) return 0;
    const headerRow = document.querySelector('.VirtualTable-Header') as HTMLElement | null;
    const currentRow = document.querySelector(`._${expanded?.[0]}`) as HTMLElement | null;
    // adding 2 px here (factoring in top and bottom borders) for a more exact calculation/fit
    return viewportHeight + 2 - ((currentRow?.offsetHeight || 0) + (headerRow?.offsetHeight || 0));
  }, [expanded, viewportHeight]);

  const footerTitle = useMemo(() => {
    const addon = filteredRows?.find?.((row) => row?.id === expanded?.[0]);
    if (!addon) return '';
    return `${new Datetime().setAsUTC(addon?.createdAt).toLocaleDatetime().frontendDatetimeShort} - ${addon?.email?.from || ''} - ${addon?.email?.subject || ''}`;
  }, [expanded, filteredRows]);

  return (
    <>
      <VirtualTable
        {...props}
        name="pendingImportsTable"
        data={filteredRows}
        selected={selected}
        expanded={expanded}
        dynamic
        dynamicRowHeight
        header={header}
        ref={tableRef}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
              expanded: !!expanded.includes(data?.id || index),
            }}
            className={getClasses(
              selected.includes(index) ? 'selected' : '',
              expanded.includes(data?.id || index) ? 'expanded' : '',
              `${data?.id || index}`.includes('_') ? 'nested' : '',
              !data?.children?.length ? 'no-children' : '',
              `_${data?.id}`
            )}
            onDoubleClick={() => {
              if (
                addonAsDrawer &&
                data?.type === ImportTypeEnum.Addon &&
                [ImportStatusEnum.Potential, ImportStatusEnum.Staged, ImportStatusEnum.NotParsed].includes(data?.status)
              ) {
                if (expanded?.length === 0) return onExpand(data?.id || index);
                return setState((current) => ({
                  ...current,
                  expanded: current?.expanded?.includes(data?.id || index) ? [] : [data?.id || index],
                }));
              } else {
                onDoubleClick(data);
              }
            }}
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="createdAt"
              placeholder="--"
              className="text-center alternate"
              width="calc((100% / 7) * 0.5)"
              sorting={onSort('createdAt')}
              render={({ value }: { value: string }): string => new Datetime().setAsUTC(value).toLocaleDatetime().frontendDatetimeShort}
            />
            <DynamicCell
              selector="statusDisplayName"
              placeholder="--"
              className={getClasses('text-center', data?.status)}
              width="calc(100% / 7)"
              sorting={onSort('statusDisplayName')}
            />
            <DynamicCell
              selector="provider.iataAirlineCode|provider.displayName"
              placeholder="--"
              className="text-center alternate"
              width="calc((100% / 7) * 0.75)"
              sorting={onSort('provider.iataAirlineCode|provider.displayName')}
            />
            <DynamicCell
              selector="importedBy"
              placeholder="--"
              className="text-center "
              width="calc(100% / 7)"
              sorting={onSort('importedBy')}
            />
            <DynamicCell
              selector="email.from"
              placeholder="--"
              className="text-center alternate"
              width="calc((100% / 7) * 1.25)"
              sorting={onSort('email.from')}
            />
            <DynamicCell
              selector="email.subject"
              placeholder="--"
              className="text-center "
              width="calc((100% / 7) * 1.75)"
              sorting={onSort('email.subject')}
            />
            <DynamicCell
              selector="type"
              placeholder="--"
              className="text-center alternate"
              width="calc((100% / 7) * 0.75)"
              sorting={onSort('type')}
            />
            <DynamicCell
              selector="download"
              placeholder="--"
              className="text-center"
              width="calc(100% / 11)"
              render={({ data }) => (
                <PromiseButton
                  variant="icon"
                  onClick={() => onDownload(data.id)}
                  icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
                  feedback="Download"
                />
              )}
            />
          </VirtualTableRow>
        )}
      />
      <FooterDrawer
        className="InlineImportFooter"
        show={addonAsDrawer && expanded?.length === 1}
        onClose={handleHideAddon}
        title={footerTitle}
        initHeight={inlinePreviewHeight}
        hideMinimize
      >
        {expanded?.length === 1 && (
          <div className={`{height:${Math.max(inlinePreviewHeight - 45, 500)}px;overflow-y:auto;overflow-x:hidden}`}>
            <InlineImport
              importId={expanded?.[0] || ''}
              onSubmit={() => {
                onSubmitAddon(expanded?.[0]);
                handleHideAddon();
              }}
              onCancel={handleHideAddon}
              onMount={() => {
                const timer = setTimeout(() => {
                  window.dispatchEvent(new Event('resize'));
                  tableRef?.current?.scrollToIndex?.({
                    index: filteredRows?.findIndex?.((row) => row?.id === expanded?.[0]) + 1,
                    behavior: 'smooth',
                  });
                  clearTimeout(timer);
                }, 250);
              }}
            />
          </div>
        )}
      </FooterDrawer>
    </>
  );
};

export default React.memo(React.forwardRef(PendingImportsTable));
