import React, { useEffect, useState } from 'react';
import { getClasses, uploadFile } from '../../utils';

import EditModal from '../EditModal/new';
import FileUpload from '../FileUpload';
import ManifestFileUpload from '../../pages/Manifests/ManifestFileUpload';
import useSession from '@/state/session';

const initManifestUploadState = {
  ready: false,
  files: [],
  progress: {},
};
const ManifestUpload = ({ show, onHide, onSuccess }) => {
  const supportedManifestFileTypes = useSession(({ state }) => state.config?.supportedManifestFileTypes || []);
  const [state, setState] = useState(initManifestUploadState);
  const { ready, files } = state;
  const progress = {};
  files.forEach((file) => {
    progress[file.md5] = Math.round(file.progress * 100);
  });

  const uploadAll = async () => {
    const payload = [];
    for (let f = 0; f < files.length; f++) {
      const bundle = files[f];
      if (!bundle.url) {
        payload.push(new Promise((resolve) => resolve({ status: 200, file: { md5: bundle.md5 } })));
      } else {
        payload.push(
          uploadFile(bundle, {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = (loaded * 100) / total;
              setState((current) => ({
                ...current,
                progress: { ...current.progress, [bundle.md5]: percent },
              }));
            },
          })
        );
      }
    }
    Promise.all(payload).then((results) => {
      const failed = results.find(({ status }) => status !== 200);
      if (failed) return false;
      setState((current) => {
        const completed = {};
        Object.keys(current.progress).forEach((key: string): void => {
          completed[key] = current.progress[key] = 100;
        });
        return { ...state, files: (current?.files || []).map((file) => ({ ...file, progress: 1 })), progress: completed };
      });
      onSuccess(results, files);
    });
  };
  const handleHide = () => {
    setState(initManifestUploadState);
    onHide();
  };
  const onChange = (files) => {
    setState((current) => ({ ...current, files }));
  };
  const onSubmit = () => {
    uploadAll();
  };
  const onReady = (isReady) => setState((current) => ({ ...current, ready: !!isReady }));

  useEffect(() => {
    if (!show) setState(initManifestUploadState);
  }, [show]);

  return (
    <EditModal
      size="xl"
      name="ManifestUpload"
      show={show}
      onHide={onHide}
      title="Upload"
      onSubmit={!ready || (files || [])?.map((file) => file.progress !== -1).includes(false) ? null : onSubmit}
      options={{
        footer: {
          submitButtonText: 'Continue',
          condensed: true,
        },
      }}
    >
      <FileUpload
        label="Select a manifest that you'd like to import"
        placeholder={
          <span>
            Drag &amp; Drop Here Or <strong>Browse</strong>
          </span>
        }
        submitButtonText="UPLOAD"
        onChange={onChange}
        File={ManifestFileUpload}
        onReady={onReady}
        progress={progress}
        supportedExtensions={supportedManifestFileTypes}
      />
      <div className={getClasses('text-end', !ready && '{visibility:hidden;}')}>Please press Continue to import.</div>
    </EditModal>
  );
};

export default ManifestUpload;
