import './styles.scss';

import { DATE_FE_FORMAT, TODAY, TODAY_EOD } from '@/constants';
import { DatePicker, DatePickerProps } from 'antd';

import { Datetime } from '@/utils';
import { FormFieldProps } from '../FormField';
import React from 'react';
import useClassNames from '@/hooks/useClassNames';

type DateRangePickerProps = { isInvalid?: boolean } & Omit<FormFieldProps, 'isInvalid'> & Omit<DatePickerProps, 'onChange'>;
const DateRangePicker = ({
  value,
  onChange,
  isValid,
  isInvalid,
  isDirty,
  format = DATE_FE_FORMAT,
  className,
  name: fieldName,
  ...props
}: DateRangePickerProps): JSX.Element => {
  const classes = useClassNames(
    isValid ? 'border-success' : isInvalid ? 'border-danger' : isDirty ? 'border-secondary' : undefined,
    className,
    'flex-grow-1'
  );
  const handleChange = (name: string, newValue: string): void => {
    let from = new Datetime(name === 'from' ? newValue : value?.[0]).setTime('00:00:00').datetimeInput;
    let to = new Datetime(name === 'to' ? newValue : value?.[1]).setTime('23:59:59').datetimeInput;
    if (new Datetime(from).asDayjs().isAfter(new Datetime(to).asDayjs())) {
      if (name === 'from') {
        to = new Datetime(from).setTime('23:59:59').datetimeInput;
      } else {
        from = new Datetime(to).setTime('00:00:00').datetimeInput;
      }
    }
    onChange(
      {
        target: {
          name: fieldName,
          value: `${from} - ${to}`,
        },
      },
      `${from} - ${to}`
    );
  };

  const initialFrom = value?.[0] === undefined ? TODAY : value?.[0];
  const initialTo = value?.[1] === undefined ? TODAY_EOD : value?.[1];

  return (
    <div className="DateRangePicker gap-2">
      <DatePicker
        allowClear={false}
        {...props}
        name={'from'}
        value={initialFrom ? new Datetime(initialFrom).asDayjs() : undefined}
        onChange={(_d: any, value: string) => handleChange('from', value)}
        suffixIcon={<i className="fa-regular fa-calendar" />}
        format={format}
        className={classes}
        preserveInvalidOnBlur
        onBlur={(event): void => {
          if (event.target?.['value'] === undefined || event.relatedTarget?.className?.includes('ant')) return;
          handleChange('from', event.target?.['value']);
        }}
      />
      <DatePicker
        allowClear={false}
        {...props}
        name={'to'}
        value={initialTo ? new Datetime(initialTo).asDayjs() : undefined}
        onChange={(_d: any, value: string) => handleChange('to', value)}
        suffixIcon={<i className="fa-regular fa-calendar" />}
        format={format}
        className={classes}
        preserveInvalidOnBlur
        onBlur={(event): void => {
          if (event.target?.['value'] === undefined || event.relatedTarget?.className?.includes('ant')) return;
          handleChange('to', event.target?.['value']);
        }}
      />
    </div>
  );
};

export default DateRangePicker;
