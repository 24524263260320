import '@/components/ChatWidget/styles.scss';

import { Badge, Stack } from 'react-bootstrap';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { TODAY, TODAY_EOD } from '@/constants';
import useChatRooms, { ChatRoomType, useChatState } from '@/hooks/useSocket/chat/useChatRooms';

import ChatList from '@/components/ChatWidget/ChatList';
import HasPermission from '../HasPermission';
import { Link } from 'react-router-dom';
import Portal from '@/components/Portal';
import useInterval from '@/hooks/useInterval';
import useViewport from '@/hooks/useViewport';

const ChatWidget = () => (
  <HasPermission name="allowViewChat">
    <Portal>
      <div className="ChatWidget">
        <Chat />
      </div>
    </Portal>
  </HasPermission>
);

const Chat = () => {
  const [, , { totalUnread, getRooms }] = useChatRooms(ChatRoomType.CURRENT_CHAT);
  const rooms = useChatState(({ state: { rooms } }) => rooms?.[ChatRoomType.CURRENT_CHAT] || {});
  const setChatState = useChatState(({ setState }) => setState);
  const [hidden, setHidden] = useState(false);
  const canHide = HasPermission.check('allowHideChat');
  const headerRef = useRef(null);
  const [
    {
      content: { height: viewportHeight },
    },
  ] = useViewport();
  useInterval(() => {
    const now = Date.now();
    setChatState((current) => {
      const now = Date.now();
      return {
        ...current,
        usersTyping: current.usersTyping.filter((user: any) => now - user.timestamp < 3000),
      };
    });
    const hasNonCrewMessage = Object.values(rooms).find(
      (room) => room.latestMessage.role_name !== 'crew' && now - new Date(room.latestMessage.datetime).getTime() >= 7000
    );
    if (hasNonCrewMessage) {
      setChatState((current) => {
        return {
          ...current,
          rooms: {
            ...current.rooms,
            [ChatRoomType.CURRENT_CHAT]: Object.entries(current.rooms[ChatRoomType.CURRENT_CHAT] || {}).reduce((acc, [key, value]) => {
              if (!(value.latestMessage.role_name !== 'crew' && now - new Date(value.latestMessage.datetime).getTime() >= 7000)) {
                acc[key] = value;
              }
              return acc;
            }, {}),
          },
        };
      });
    }
  }, 3000);

  useEffect(() => {
    getRooms({
      format: ChatRoomType.CURRENT_CHAT,
      start_datetime: TODAY,
      end_datetime: TODAY_EOD,
      user_provider_id: null,
      keyword: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ChatWidgetHeader unread={totalUnread} onClick={canHide ? () => setHidden(!hidden) : undefined} ref={headerRef} />
      {!hidden && <ChatList rooms={rooms} className={`{max-height:${viewportHeight / 2 - (headerRef.current?.offsetHeight || 0)}px;}`} />}
    </>
  );
};

type ChatWidgetHeaderProps = {
  onClick?: () => void;
  unread?: number;
};
const ChatWidgetHeader = (() => {
  const ChatWidgetHeaderWithRef = ({ onClick, unread: _unread }: ChatWidgetHeaderProps, ref) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`ChatWidget-Header position-sticky top-0 rounded-top-4 px-5 py-1 {z:999} ${onClick ? 'pointer' : ''}`}
      onClick={onClick}
      ref={ref}
    >
      <Stack direction="horizontal">
        <span className="ChatWidget-Title fs-5 text-uppercase">Message Center</span>
        <div className="ms-auto" />
        <ChatWidgetUnread unread={0} />
      </Stack>
    </div>
  );
  return forwardRef(ChatWidgetHeaderWithRef);
})();

type ChatWidgetUnreadProps = {
  unread?: number;
};
const ChatWidgetUnread = ({ unread }: ChatWidgetUnreadProps) => (
  <div className="ChatWidget-Unread">
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus*/}
    <Link to="/message-center">
      <span role="button">
        <i className="sv sv-inbox" />
      </span>
      {unread > 0 && (
        <Badge bg="secondary" pill>
          {unread}
        </Badge>
      )}
    </Link>
  </div>
);

export default ChatWidget;
