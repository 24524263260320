import './styles.scss';

import { Button, Modal } from 'react-bootstrap';
import { DATE_FE_FORMAT, TIME_FORMAT } from '@/constants';
import React, { useState } from 'react';
import VirtualTable, { DynamicCell, FormatCell, VirtualTableRow, useVirtualTable } from '@/components/VirtualTable';
import { formatDollars, formatNumber, zeroPadFlightNumber } from '@/utils/numbers';

import ConfirmationButton from '@/components/ConfirmationButton';
import { Datetime } from '@/utils/dates';
import Details from '@/components/Details';
import FormField from '@/components/FormField';
import { Trip } from '@/models/gen/graphql';

type DeleteTripsModalProps = {
  show: boolean;
  onHide: () => void;
  selected: Trip[];
  sorting?: any;
  formValues: any;
  onDelete: (values: any, comment: string, selected?: Trip[]) => Promise<void>;
};

const initDeleteTripsModalState: DeleteTripsModalProps & { comment: string } = {
  show: false,
  onHide: () => {},
  selected: [],
  formValues: undefined,
  comment: '',
  sorting: { direction: undefined, column: undefined },
  onDelete: async () => {},
};

const DeleteTripsModal = ({ show, selected, onHide, onDelete, formValues }: DeleteTripsModalProps) => {
  const [_state, setState] = useState(initDeleteTripsModalState);
  const { comment } = _state;
  const totalCost = selected?.reduce((acc, curr) => {
    return acc + (curr?.rate?.rate || 0);
  }, 0);
  const { makeSortable, filteredRows } = useVirtualTable(setState, {
    sorting: { direction: undefined, column: undefined },
    rows: selected,
  });
  return (
    <Modal
      show={show}
      size="xl"
      onHide={() => {
        onHide?.();
      }}
      className="DeleteTripsModal"
    >
      {/* TODO: Make this title a standalone component */}
      <Modal.Header className="DeleteTripsModal-Header">
        <div className="DeleteTripsModal-Title">
          <h3>Bulk Delete Trips</h3>
        </div>
      </Modal.Header>
      <Modal.Body className="DeleteTripsModal-Body">
        <Details
          data={[
            { 'Total Trips(s):': { name: 'Total Trips(s):', value: formatNumber(selected?.length) } },
            { 'Total Cost:': { name: 'Total Cost:', value: `$${formatDollars(totalCost || 0)}` } },
          ]}
          className="uppercase justify-content-center gap-2"
        />
        <VirtualTable
          dynamicRowHeight
          data={filteredRows}
          header={{
            scheduledDate: 'Date',
            scheduledTime: 'Sch',
            airportCode: 'City',
            servicerIataAirlineCode: 'Al',
            flightNumber: 'Flt',
            puLocation: { name: 'Pick-Up' },
            doLocation: { name: 'Drop-Off' },
            rate: { rate: 'Rate' },
          }}
          style={{ height: 500 }}
          rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
            <VirtualTableRow
              context={{
                ...context,
                rowType: _type,
                data,
                index,
              }}
            >
              <FormatCell
                selector="scheduledDate"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="5rem"
                sorting={makeSortable('scheduledDate')}
                format={(_value: string, data: Trip): string => new Datetime(data?.scheduled).format(DATE_FE_FORMAT)}
              />
              <FormatCell
                selector="scheduledTime"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="5rem"
                sorting={makeSortable('scheduledTime')}
                format={(_value: string, data: Trip): string => new Datetime(data?.scheduled).format(TIME_FORMAT)}
              />
              <DynamicCell
                selector="airportCode"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="4rem"
                sorting={makeSortable('airportCode')}
              />
              <DynamicCell
                selector="servicerIataAirlineCode"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="4rem"
                sorting={makeSortable('servicerIataAirlineCode')}
              />
              <DynamicCell
                selector="flightNumber"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="4rem"
                sorting={makeSortable('flightNumber')}
                render={({ data }: { data: Trip }): string =>
                  (data.loopName || data.flightNumber > 0 ? zeroPadFlightNumber(data.flightNumber) : undefined) || '--'
                }
              />
              <DynamicCell
                selector="puLocation.name"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="calc(100% / 8)"
                sorting={makeSortable('puLocationId')}
              />
              <DynamicCell
                selector="doLocation.name"
                placeholder="--"
                className="text-left {bx:none!;}"
                width="calc(100% / 8)"
                sorting={makeSortable('doLocationId')}
              />
              <DynamicCell
                selector="rate.rate"
                placeholder="--"
                className="text-left {bx:none!;}"
                minWidth="6rem"
                width="calc(100% / 24)"
                sorting={makeSortable('rate.rate')}
                render={({ value }): string => (!Number.isNaN(parseFloat(value)) ? formatDollars(value) : '--')}
              />
            </VirtualTableRow>
          )}
        ></VirtualTable>
      </Modal.Body>
      <Modal.Footer className="DeleteTripsModal-Footer">
        <div className="flex-grow-1">
          <div className="mb-0 {width:80%;}">
            <FormField
              variant="primary"
              onChange={(e) => setState((current) => ({ ...current, comment: e.target.value }))}
              name="reason"
              label="Reason"
              placeholder="Provide a reason for the deletion."
              options={{
                input: {
                  className: 'text-primary border-primary {outline:none!;}',
                },
                label: {
                  className: 'text-primary',
                },
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-end align-items-end {padding-top:0.75rem!;}">
          {/* Ensures buttons stay aligned with input due to label affecting alignment */}
          <Button size="lg" onClick={onHide} variant="secondary" name="CANCEL">
            Cancel
          </Button>
          <ConfirmationButton
            size="lg"
            variant="primary"
            onConfirm={async () => {
              onDelete?.(formValues, comment, selected);
              onHide?.();
            }}
            options={{
              confirmation: {
                Modal: {
                  props: { className: 'DeleteTripsModal-Confirmation', centered: true },
                },
                Header: {
                  as: () => (
                    <Modal.Header>
                      <h3 className="modal-title">Bulk Delete Trips</h3>
                    </Modal.Header>
                  ),
                },
                Body: {
                  as: () => (
                    <Modal.Body className="d-flex flex-column align-items-center">
                      <div className="align-self-start {font-size:14px;}">Are you sure you want to delete these trips?</div>
                      <div className="d-flex justify-content-between mt-4 max-width:50%; w-100">
                        <div className="d-flex flex-column align-items-center">
                          <h6>TOTAL TRIPS</h6>
                          <strong className="fs-4">{formatNumber(selected.length)}</strong>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <h6>TOTAL REVENUE</h6>
                          <strong className="fs-4">{formatDollars(totalCost || 0)}</strong>
                        </div>
                      </div>
                    </Modal.Body>
                  ),
                },
                Footer: {
                  props: { className: 'text-end {min-width:100px;flex-grow:0!;}>button modal-footer' },
                  resolve: 'DELETE',
                  reject: 'CANCEL',
                },
              },
            }}
            onCancel={onHide}
            name="CONFIRM_DELETE"
          >
            Delete
          </ConfirmationButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(DeleteTripsModal);
