import { UpdatePotentialTripBulkInput, UpdatePotentialTripBulkResponse, UpdatePotentialTripInput } from '../../../models/gen/graphql';
import { createNotification, handleError } from '../../../utils';

import { GraphApiResponse } from '../../core';
import { Toast } from '../../../models';
import { UpdatePotentialTripBulkDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';

type UpdatePotentialTripBulkGraphApiResponse = GraphApiResponse<typeof UpdatePotentialTripBulkDocument>;

const title = 'Update Potential Trip(s)';

const updatePotentialTripBulkSelector = (res: UpdatePotentialTripBulkGraphApiResponse): UpdatePotentialTripBulkResponse =>
  !res.errors ? res?.updatePotentialTripBulk || { response: [] } : undefined;

const [runUpdatePotentialTripBulk] = graphApi(UpdatePotentialTripBulkDocument, {
  onError: (res: UpdatePotentialTripBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const updatePotentialTripBulk = async (updates: UpdatePotentialTripInput[]): Promise<UpdatePotentialTripBulkResponse> => {
  const input: UpdatePotentialTripBulkInput = { updates };
  const res = await runUpdatePotentialTripBulk({ input });

  return updatePotentialTripBulkSelector(res);
};

export const useUpdatePotentialTripBulk = createGraphApiHook(updatePotentialTripBulk);

export default updatePotentialTripBulk;
