import { formatUrl, getCurrentUrl } from '@/state/utils';

import { StateStorage } from 'zustand/middleware/persist';
import { parseQueryString } from '@/utils/strings';

export const customLocalStorage = <T>(initState: T, live: boolean): StateStorage => ({
  getItem: (key: string): string => {
    const value = localStorage.getItem(key);
    if (value === null) return JSON.stringify({ state: { state: initState } });
    return value;
  },
  setItem: (key: string, value: string): void => {
    if (!live) return;
    localStorage.setItem(key, value);
  },
  removeItem: localStorage.removeItem,
});

export const saveSearchStorage = (state) => {
  // get current query string
  const current = getCurrentUrl();
  // convert state to search
  const parsed = Object.entries(state).reduce(
    (acc, [key, value]: [string, unknown]) =>
      value === undefined ? { ...acc, [key]: '' } : { ...acc, [key]: Array.isArray(value) ? value.join(',') : value },
    {}
  );
  const url = formatUrl(undefined, parsed);
  // check if the url changed
  if (current === url) return;
  // otherwise add to history new state
  window.history.pushState({}, '', url);
};
export const getSearchStorage = (initState) => {
  const parsed = { ...initState, ...parseQueryString(window.location.search) };
  const validKeys = Object.keys(initState);
  const state = Object.entries(parsed).reduce((acc, [key, value]: [string, string]) => {
    if (!validKeys.includes(key)) return acc;
    acc[key] = value === 'undefined' ? '' : value.includes(',') ? value.split(',') : value;
    return acc;
  }, {});
  return JSON.stringify({ state: { state } });
};
export const searchStorage = (initState, live: boolean = false): StateStorage => ({
  getItem: (): string => getSearchStorage(initState),
  setItem: (_key: string, value: string): void => {
    if (!live) return;
    const {
      state: { state },
    } = JSON.parse(value);
    saveSearchStorage(state);
  },
  removeItem: (): void => {
    window.history.replaceState({}, '', `${window.location.pathname}`);
  },
});
