import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { QueryInput } from '@/utils';
import { getSelectUserFilter } from '@/api/services/users/searchUsers';

type UserDropdownProps = Omit<QueryDropdownProps, 'query'> & {
  airports?: string[];
  query?: Record<string, QueryInput>;
};
const UserDropdown = ({ name = 'user', airports = null, query = {}, ...props }: UserDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getSelectUserFilter(airports || [], query);
    return (res || []).map(
      ({ id, fullName }): DropdownItem => ({
        label: fullName,
        keywords: fullName.split(' '),
        value: id,
      })
    );
  }, [airports, query]);

  return <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'User' } }} />;
};

export default UserDropdown;
