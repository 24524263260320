import Tooltip from '@/components/Tooltip';
import { TripCombineTypeEnum } from '@/models/gen/graphql';

const CombineCell = ({
  combineId,
  combineType,
  onClick,
}: {
  combineId: string;
  combineType: TripCombineTypeEnum;
  onClick: () => void;
}): React.JSX.Element => (
  <Tooltip content="Combine">
    <button onClick={onClick}>
      <i className={`sv sv-combine-icon fs-5 ${combineId || !!combineType ? 'text-green' : 'text-secondary'}`} />
    </button>
  </Tooltip>
);
export default CombineCell;
