import { Badge, Button, Form } from 'react-bootstrap';
import {
  Communication,
  Fcr,
  FlagWithTrip,
  Location,
  Provider,
  Rate,
  Trip,
  TripKindEnum,
  TripState,
  TripTableFormatEnum,
  TripTypeEnum,
  User,
  Vehicle,
} from '@/models/gen/graphql';
import ComplexTable, {
  ComplexTableCell,
  ComplexTableHeader,
  ComplexTableProps,
  ComplexTableRow,
  ComplexTableRowRendererProps,
  ComplexTableSortableCell,
} from '@/components/ComplexTable';
import { DATE_FE_FORMAT_SHORT, TIME_FORMAT } from '@/constants';
import { Datetime, Validation, getClasses, getProperty, parsePhoneNumber, stringify, titleCase } from '@/utils';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { AssignDriverDropdown } from '@/components/DriverDropdown';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import FlagsButton from '@/components/FlagsButton';
import SelectVehicle from '@/components/SelectVehicle';
import Tippy from '@tippyjs/react';
import TippyWhen from '@/components/TippyWhen';
import { useSearchTripsTable } from '@/api/services/trips/searchTrips';

const COLUMN_SIZES = [
  '2rem', // Select
  '1fr', // flagCom
  '1fr', // type
  '1fr', // scheduledDate
  '1fr', // scheduledTime
  '1fr', // actual
  '1fr', // airportCode
  '1fr', // servicerIataAirlineCode
  '1fr', // flightNumber
  '0.75fr', // pilots
  '0.75fr', // attendants
  '2fr', // driverId
  '2fr', // vehicleId
  '2fr', // puLocation
  '2fr', // doLocation
  '2fr', // payerProvider
  '0.75fr', // completionButton
  '0.5fr', // fcrButton
  '0.5fr', // combineButton
  '1.5fr', // rate
];

export type TripsTableProps = Omit<ComplexTableProps, 'columns' | 'header' | 'row'> & {};

export type TripMetadata = {
  id: string;
  isDirty: boolean;
  isValid: boolean;
  validity: {};
};
const getMetadata = (item: Trip, { update }: { update: Record<string, unknown> }): TripMetadata => {
  const validity = {};
  return {
    id: item?.id,
    isDirty: !!update,
    isValid: !item || !Object.values(validity).includes(false),
    validity,
  };
};

const TableSandbox = (): JSX.Element => {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [{ data: { rows = [] } = {}, loading }, { fetch }] = useSearchTripsTable();
  const ref = useRef(null);

  const onSelect = (id?: string): void =>
    id !== undefined
      ? setSelected(
          (current: Record<string, boolean>): Record<string, boolean> => ({
            ...current,
            [id]: !current[id],
          })
        )
      : setSelected(
          (current: Record<string, boolean>): Record<string, boolean> =>
            rows.reduce(
              (acc: {}, row: Trip): Record<string, boolean> => ({
                ...acc,
                [row.id]: Object.values(current).every((v: boolean): boolean => v !== true),
              }),
              {}
            )
        );

  const sorting = useCallback((items: ComplexFormItem<Trip>[], col: number, dir: 'asc' | 'desc'): ComplexFormItem<Trip>[] => {
    if (col === undefined) return items;
    const column = [
      'data.flagCom',
      'data.type',
      'data.scheduledDate',
      'data.scheduledTime',
      'data.actual',
      'data.airportCode',
      'data.servicerIataAirlineCode',
      'data.flightNumber',
      'data.pilots',
      'data.attendants',
      'data.driverId',
      'data.vehicleId',
      'data.puLocation',
      'data.doLocation',
      'data.payerProvider',
      'data.completionButton',
      'data.fcrButton',
      'data.combineButton',
      'data.rate',
    ][col];
    return items.sort(
      (a: ComplexFormItem<Trip>, b: ComplexFormItem<Trip>): number =>
        (getProperty(column, a) < getProperty(column, b) ? -1 : getProperty(column, a) > getProperty(column, b) ? 1 : 0) *
        (dir === 'desc' ? -1 : 1)
    );
  }, []);

  useEffect((): void => {
    if (!fetch || loading) return;
    fetch({ query: null, format: TripTableFormatEnum.All }, { pageSize: 1000, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100 h-100">
      <ComplexTable
        columns={COLUMN_SIZES}
        header={TripsTableHeader}
        row={TripsTableRow}
        data={rows}
        metadata={getMetadata}
        onChange={console.log}
        options={{ loading, sorting, selected, onSelect }}
        ref={ref}
      />
    </div>
  );
};

const TripsTableHeader = ({ selected, onSelect }: { selected: boolean; onSelect: (id?: string) => void }): ReactNode => {
  return (
    <ComplexTableHeader>
      <ComplexTableCell>
        <Form.Check checked={selected} onChange={(): void => onSelect()} />
      </ComplexTableCell>
      <ComplexTableCell>
        <div className="w-100 d-flex justify-content-around">
          <i className="sv sv-flag-empty fs-5 {padding-top:0.05rem;}" />
          <i className="sv sv-message2 fs-5" />
        </div>
      </ComplexTableCell>
      <ComplexTableSortableCell index={1}>Type</ComplexTableSortableCell>
      <ComplexTableSortableCell index={2}>Date</ComplexTableSortableCell>
      <ComplexTableSortableCell index={3}>Sch</ComplexTableSortableCell>
      <ComplexTableSortableCell index={4}>Act</ComplexTableSortableCell>
      <ComplexTableSortableCell index={5}>City</ComplexTableSortableCell>
      <ComplexTableSortableCell index={6}>Al</ComplexTableSortableCell>
      <ComplexTableSortableCell index={7}>Flt</ComplexTableSortableCell>
      <ComplexTableSortableCell index={8}>Plt</ComplexTableSortableCell>
      <ComplexTableSortableCell index={9}>FA</ComplexTableSortableCell>
      <ComplexTableSortableCell index={10}>Drv</ComplexTableSortableCell>
      <ComplexTableSortableCell index={11}>Van</ComplexTableSortableCell>
      <ComplexTableSortableCell index={12}>P/U</ComplexTableSortableCell>
      <ComplexTableSortableCell index={13}>D/O</ComplexTableSortableCell>
      <ComplexTableSortableCell index={14}>Clt</ComplexTableSortableCell>
      <ComplexTableCell>
        <i className="sv sv-completion fs-5" />
      </ComplexTableCell>
      <ComplexTableCell>
        <i className="sv sv-fcr fs-5" />
      </ComplexTableCell>
      <ComplexTableCell>
        <i className="sv sv-combine-icon fs-5 text-secondary" />
      </ComplexTableCell>
      <ComplexTableSortableCell index={18}>Rate</ComplexTableSortableCell>
    </ComplexTableHeader>
  );
};

type TripRowState = {
  data: {
    flags: FlagWithTrip[];
    communications: Communication[];
    type: TripTypeEnum;
    scheduled: string;
    scheduledDate: string;
    scheduledTime: string;
    airportCode: string;
    servicerIataAirlineCode: string;
    flightNumber: string;
    pilots: number;
    attendants: number;
    actual: string;
    driver: User;
    driverId: string | null;
    vehicle: Vehicle;
    vehicleId: string | null;
    puLocation: string;
    doLocation: string;
    payerProvider: string;
    state: TripState;
    trackFlight: Trip['trackFlight'];
    fcrs: Fcr[];
    combineId: string;
    rate: Rate['rate'];
    kind: TripKindEnum;
  };
};

const TripsTableRow = ({ item, getValue, onChange, onDelete: _, selected, onSelect }: ComplexTableRowRendererProps): ReactNode => {
  /*
    This is all just a conceptual example. This is the general idea
    of how we will want to handle rows from now on, so that they
    have their own local state, and they can also still update their
    data when the table says so.
  */
  const getData = useCallback(() => {
    const scheduled = getValue('scheduled');
    const driver = getValue('driver');
    const vehicle = getValue('vehicle');
    return {
      flags: getValue('flags') || [],
      communications: getValue('communications') || [],
      type: getValue('type') || TripTypeEnum.Sch,
      scheduled,
      scheduledDate: new Datetime(scheduled).format(DATE_FE_FORMAT_SHORT),
      scheduledTime: new Datetime(scheduled).format(TIME_FORMAT),
      airportCode: getValue('airportCode'),
      servicerIataAirlineCode: getValue('servicerIataAirlineCode'),
      flightNumber: getValue('flightNumber'),
      pilots: getValue('pilots'),
      attendants: getValue('attendants'),
      actual: getValue('trackFlight.actual'),
      driver,
      driverId: driver?.id || null,
      vehicle,
      vehicleId: vehicle?.id || null,
      puLocation: getValue('puLocation.name'),
      doLocation: getValue('doLocation.name'),
      payerProvider: getValue('payerProvider.displayName') || '--',
      state: getValue('state') || {},
      trackFlight: getValue('trackFlight'),
      fcrs: getValue('fcrs') || [],
      combineId: getValue('combineId') || null,
      rate: getValue('rate.rate'),
      kind: getValue('kind') || TripKindEnum.Arr,
    };
  }, [getValue]);
  /*
    Local state for the row. Store all data here, but inside of a `data` key so that
    we can have sibling keys for other local state that the row might need; modals, or
    other displays of some kind as an example.
  */
  const [state, setState] = useState<TripRowState>(
    (): TripRowState => ({
      data: getData(),
    })
  );
  /* 
    Handle local changes using the local state above to prevent full-table re-renders
    Theoretically, this could also have some logic, using a switch case or something similar
    to cause certain columns to update the table-state if needed.
  */
  const handleChange =
    (name: keyof TripRowState['data']): ((value: unknown) => void) =>
    (value: unknown): void => {
      setState((current: TripRowState): TripRowState => ({ ...current, data: { ...current.data, [name]: value } }));
    };

  /*
      Just a concept of how to make sure that if the table DOES change the item, we update
      the row state with the new values.
    */
  const lastItem = useRef(item);
  useEffect((): void => {
    if (stringify.compare(lastItem.current, item)) return;
    setState((current: TripRowState): TripRowState => ({ ...current, data: getData() }));
  }, [getData, item]);

  return (
    <ComplexTableRow>
      <ComplexTableCell>
        <Form.Check checked={selected} onChange={onSelect} />
      </ComplexTableCell>
      <ComplexTableCell>
        <div className="w-100 d-flex justify-content-around">
          <Tippy content="Flags">
            <div className="{padding-top:0.5rem;}">
              <FlagsButton flags={state.data.flags} onClick={(): any => console.log(item?.data)} />
            </div>
          </Tippy>
          <Tippy content="Communications">
            <Button variant="icon" className="p-0" name="COMMUNICATIONS_BUTTON" onClick={(): any => console.log(item?.data)}>
              <i className={`sv sv-message2 fs-5 ${state.data.communications?.length ? 'text-info' : ''}`} />
            </Button>
          </Tippy>
        </div>
      </ComplexTableCell>
      <ComplexTableCell>{state.data.type}</ComplexTableCell>
      <ComplexTableCell>{state.data.scheduledDate}</ComplexTableCell>
      <ComplexTableCell>{state.data.scheduledTime}</ComplexTableCell>
      <ComplexTableCell>
        <ActualColumn trackFlight={state.data.trackFlight} kind={state.data.kind} scheduled={state.data.scheduled} />
      </ComplexTableCell>
      <ComplexTableCell>{state.data.airportCode}</ComplexTableCell>
      <ComplexTableCell>{state.data.servicerIataAirlineCode}</ComplexTableCell>
      <ComplexTableCell>{state.data.flightNumber}</ComplexTableCell>
      <ComplexTableCell>{state.data.pilots}</ComplexTableCell>
      <ComplexTableCell>{state.data.attendants}</ComplexTableCell>
      <ComplexTableCell>
        <AssignDriverDropdown
          value={state.data.driverId}
          onChange={handleChange('driverId')}
          airportCode={state.data.airportCode}
          scheduled={state.data.scheduled}
          actual={state.data.actual}
          details={state.data.driver}
          options={{
            lazyLoadItems: true,
            showChevron: false,
            showClearButton: false,
            locale: {
              'Select...': state.data.driver?.fullName || '--',
            },
          }}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <TippyWhen
          isTrue={!!state.data.vehicle}
          options={{
            content: (
              <>
                <div className="text-center">{`Vehicle #${state.data.vehicle?.trackingId || '--'}`}</div>
                <div>{`Phone: ${parsePhoneNumber(state.data.vehicle?.phoneNumber || '', ' ')}`}</div>
                <div className="text-center">{`Capacity: ${state.data.vehicle?.capacity || '--'}`}</div>
                <div className="text-center">{titleCase(`${state.data.vehicle?.make || ''} ${state.data.vehicle?.model || ''}`)}</div>
              </>
            ),
          }}
        >
          <SelectVehicle
            name="vehicle"
            data={state.data}
            refetchParent={console.log}
            placeholder={state.data.vehicle?.trackingId || '--'}
            disabled={!state.data.driverId}
            value={state.data.vehicleId}
            onChange={(event) => handleChange('vehicleId')(event.target.value)}
          />
        </TippyWhen>
      </ComplexTableCell>
      <ComplexTableCell>{state.data.puLocation}</ComplexTableCell>
      <ComplexTableCell>{state.data.doLocation}</ComplexTableCell>
      <ComplexTableCell>{state.data.payerProvider}</ComplexTableCell>
      <ComplexTableCell>
        <Tippy
          content={
            !Validation.isNil(state.data.state)
              ? state.data.state
                ? state.data.state?.datetimeOfChange
                  ? `${state.data.state?.displayName} at ${new Datetime(state.data.state?.datetimeOfChange).format('HH:mm')}`
                  : `${state.data.state?.displayName}`
                : ''
              : 'No Completion'
          }
        >
          <Button variant="transparent" className="p-0" onClick={console.log}>
            {state.data.state && <small className="completion-reason">{state.data.state ? state.data.state?.displayNameShort : ''}</small>}
            {state.data.state ? (
              <small className="completion-time text-success d-block">
                {new Datetime(state.data.state?.datetimeOfChange).format('HH:mm')}
              </small>
            ) : (
              <i className="sv sv-time fs-5" />
            )}
          </Button>
        </Tippy>
      </ComplexTableCell>
      <ComplexTableCell>
        <Tippy content="Complaints">
          <Button variant="transparent" className="p-0" onClick={console.log}>
            <i className={`sv sv-fcr fs-5 ${state.data.fcrs?.length ? 'text-purple' : 'text-primary'}`} />
          </Button>
        </Tippy>
      </ComplexTableCell>
      <ComplexTableCell>
        <Tippy content="Combine">
          <Button variant="transparent" className="p-0" onClick={console.log}>
            <i className={getClasses('sv sv-combine-icon fs-5', state.data.combineId ? 'text-green' : 'text-secondary')} />
          </Button>
        </Tippy>
      </ComplexTableCell>
      <ComplexTableCell>{state.data.rate}</ComplexTableCell>
    </ComplexTableRow>
  );
};

const getActualTimeVariant = (trackFlight: Trip['trackFlight'], scheduled: string): string => {
  if (!trackFlight?.actual || !scheduled) return;
  const actualDatetime = new Datetime(trackFlight?.actual).asDayjs();
  if (actualDatetime.isBefore(scheduled)) return 'success';
  const isLate = actualDatetime.diff(scheduled, 'minutes') >= 1;
  const isVeryLate = actualDatetime.diff(scheduled, 'hours') >= 5;
  if (isLate && !isVeryLate) return 'warning-dark';
  if (isVeryLate) return 'danger';
};

const ActualColumn = ({
  trackFlight,
  kind,
  scheduled,
}: {
  trackFlight: Trip['trackFlight'];
  kind: Trip['kind'];
  scheduled: string;
}): ReactNode => {
  const color = getActualTimeVariant(trackFlight, scheduled);

  if (!trackFlight?.actual) return '--';
  return (
    <div className="d-flex align-items-center gap-1 justify-content-center flex-wrap">
      <TippyWhen
        isTrue={!!trackFlight?.label}
        options={{
          content: (
            <div className="d-flex flex-column align-items-center justify-content-center gap-2">
              <div>{`Status: ${titleCase(trackFlight?.label?.replace('_', ' ') || '')}`}</div>
              {trackFlight?.label === 'EN_ROUTE' && <div>{`ETA: ${new Datetime(trackFlight?.actual).time || 'Unknown'}`}</div>}
              {kind === TripKindEnum.Arr && (
                <>
                  {trackFlight?.arrivalGate && <div>Gate: {trackFlight?.arrivalGate}</div>}
                  {trackFlight?.arrivalTerminal && <div>Terminal: {trackFlight?.arrivalTerminal}</div>}
                </>
              )}
            </div>
          ),
        }}
      >
        <Badge className="d-flex align-items-center justify-content-center fs-6" bg={color}>
          {(trackFlight?.label || '')[0]}
        </Badge>
      </TippyWhen>
      <span className={`text-${color}`}>{new Datetime(trackFlight?.actual).time}</span>
    </div>
  );
};

export default TableSandbox;
