import QueryDropdown, { QueryDropdownProps } from '@/components/QueryDropdown';
import { ReactNode, useCallback } from 'react';

import { DropdownItem } from '@/components/Dropdown';
import { FilterOption } from '@/models/gen/graphql';
import { getImportProvider } from '@/api/services/filters/importProvider';

export type ImportProviderDropdownProps = Omit<QueryDropdownProps, 'query'>;

const ImportProviderDropdown = ({ name = 'airline', ...props }: ImportProviderDropdownProps): ReactNode => {
  const getItems = useCallback(async (): Promise<DropdownItem[]> => {
    const res = await getImportProvider();
    return res.map?.(
      (option: FilterOption): DropdownItem => ({ label: option.displayName, value: option.id, keywords: option.displayName.split(' ') })
    );
  }, []);

  return (
    <QueryDropdown {...props} name={name} query={getItems} options={{ locale: props?.options?.locale || { 'Select...': 'Airline' } }} />
  );
};

export default ImportProviderDropdown;
