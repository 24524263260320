export const randomInt = (max: number, min: number = 0): number => Math.floor(Math.random() * (max - min + 1) + min);
type NumberFormatOptions = Intl.NumberFormatOptions & { locale?: Intl.LocalesArgument };
export const formatNumber = (() => {
  const fn = (amount: number = 0, options: NumberFormatOptions = {} as NumberFormatOptions): string => {
    const { locale = 'en-US', ...optionsObj } = options;
    return new Intl.NumberFormat(locale, optionsObj).format(amount);
  };
  fn.currency = (amount: number, options: NumberFormatOptions = {} as NumberFormatOptions): string =>
    formatNumber(amount, { currency: 'USD', ...options, style: 'currency' }).replace(/\$/g, '');
  return fn;
})();
export const getHoursAndMinutesFromMinutes = (minutes: number): [number, number] => [Math.floor(minutes / 60), minutes % 60];
export const formatDollars = (input: string | number): string => {
  const parsed = parseFloat(`${input}`);
  // TODO: create a utility function for this
  // const fixDecimal = parsed % 1 !== 0 ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : undefined;
  return formatNumber.currency(parsed);
};
export const minMax = (value: number, min: number, max: number): number => {
  const result = Math.max(Math.min(value, max), min);
  if (isNaN(result)) return null;
  return result;
};
export const zeroPadFlightNumber = (input: number | string): string => `${input}`.padStart(4, '0');
export const dollarsToCents = (dollars: string | number = 0): number => {
  return Math.round(Number(dollars) * 100);
};
