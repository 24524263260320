import { RunRemoveCompletionOnTripInput, RunRemoveCompletionOnTripResponse } from '@/models/gen/graphql';
import { createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunRemoveCompletionOnTripDocument } from '@/api/queries';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const title = 'Remove Completion On Trip';

type RunRemoveCompletionOnTripApiResponse = GraphApiResponse<typeof RunRemoveCompletionOnTripDocument>;

const runRemoveCompletionOnTripResponseSelector = (res: RunRemoveCompletionOnTripApiResponse): RunRemoveCompletionOnTripResponse =>
  !res.errors ? (res?.runRemoveCompletionOnTrip as RunRemoveCompletionOnTripResponse) : undefined;

const [runRunRemoveCompletionOnTrip] = graphApi(RunRemoveCompletionOnTripDocument, {
  onError: (res: RunRemoveCompletionOnTripApiResponse): void => handleError(res, { notification: { title } }),
});
const runRemoveCompletionOnTrip = async (tripIds: string[]): Promise<RunRemoveCompletionOnTripResponse> => {
  const input: RunRemoveCompletionOnTripInput = {
    tripIds,
  };
  const res = await runRunRemoveCompletionOnTrip({ input });
  return runRemoveCompletionOnTripResponseSelector(res);
};

export const handleDeleteCompletionBulk = async (tripIds: string[]): Promise<RunRemoveCompletionOnTripResponse> => {
  if (!tripIds.length) {
    createNotification('no trips provided.', Toast.Type.DANGER, 'Remove Completion On Trip');
    return null;
  }
  return await runRemoveCompletionOnTrip(tripIds);
};

export const useRunRemoveCompletionOnTrip = createGraphApiHook(runRemoveCompletionOnTrip);

export default runRemoveCompletionOnTrip;
