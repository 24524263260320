import './styles.scss';

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual';

import FormButton from '@/components/FormButton';
import { LoadingBlur } from '@/components/LoadingSpinner';
import { SortDirectionEnum } from '@/models/gen/graphql';
import Tooltip from '@/components/Tooltip';

const DEFAULT_MAX_HEIGHT = 300;
const DEFAULT_ROW_HEIGHT = 45;
const DEFAULT_OVER_SCAN = 20;
export type SimpleTableHeaderProps = { rows: string[] };
export type SimpleTableRowProps = {
  index: number;
  rowId: string;
} & React.ComponentPropsWithRef<'div'>;
export type SimpleTableProps = {
  rows: string[];
  loading?: boolean;
  title: string;
  shortcuts?: (() => React.ReactNode) | React.ReactNode;
  fetchMore?: () => void;
  disableScroll?: boolean;
  header: ((props: SimpleTableHeaderProps) => React.JSX.Element) | React.JSX.Element;
  row: (props: SimpleTableRowProps) => React.JSX.Element;
  rowHeight?: number;
  overscan?: number;
} & React.ComponentPropsWithoutRef<'div'>;
const SimpleTable = ({
  rows,
  loading,
  title,
  shortcuts: Shortcuts,
  fetchMore,
  disableScroll,
  header: TableHeader,
  row: TableRow,
  rowHeight = DEFAULT_ROW_HEIGHT,
  overscan = DEFAULT_OVER_SCAN,
  className,
  ...props
}: SimpleTableProps) => {
  // state
  const [printing, setPrinting] = useState(disableScroll !== undefined);
  const [maxHeight, setMaxHeight] = useState(DEFAULT_MAX_HEIGHT);
  // ref
  const tableRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: (): HTMLElement => tableRef.current,
    estimateSize: (): number => rowHeight,
    overscan,
    enabled: !printing,
  });

  // bind printing events
  const onStartPrint = useCallback((): void => setPrinting(true), []);
  const onEndPrint = useCallback((): void => setPrinting(false), []);

  useLayoutEffect((): (() => void) => {
    if (disableScroll !== undefined) return;
    window.addEventListener('beforeprint', onStartPrint);
    window.addEventListener('afterprint', onEndPrint);

    return (): void => {
      window.removeEventListener('beforeprint', onStartPrint);
      window.removeEventListener('afterprint', onEndPrint);
    };
  }, [onStartPrint, onEndPrint, disableScroll]);

  // constants
  const { fullScreenPadding, minHeight, tableContainerHeight, contentHeight } = useMemo(() => {
    const fullScreenPadding = rowHeight * 2;
    const minHeight = rowHeight * 3;
    const contentHeight = (rowHeight * rows?.length || rowHeight) + rowHeight;
    const tableContainerHeight = Math.min(maxHeight, contentHeight + rowHeight);
    return { fullScreenPadding, minHeight, tableContainerHeight, contentHeight };
  }, [rowHeight, rows.length, maxHeight]);

  // calculate max height on mount
  useLayoutEffect((): void => {
    const node = document.querySelector('.RouteContent');
    const { y } = node.getBoundingClientRect();
    const result = window.innerHeight - y - fullScreenPadding;
    setMaxHeight(result);
  }, [headerRef.current?.offsetHeight]);

  // Lazy Loading - Detect when user scrolls near the end and load more rows
  useEffect((): void => {
    if (disableScroll !== undefined || loading || !fetchMore) return;
    const list = rowVirtualizer.getVirtualItems();
    const lastVirtualItem = list[list.length - 1];
    if (!lastVirtualItem) return;
    const threshold = rows.length - (rows.length / 4 || -1);
    // If the last virtual item is within being rendered, load more
    if (lastVirtualItem.index >= threshold) fetchMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows.length, loading, fetchMore, rowVirtualizer.getVirtualItems()]);

  return (
    <div {...props} className={`SimpleTable ${className || ''} ${printing ? 'printing' : ''}`} ref={containerRef}>
      <div className="SimpleTable-Header" ref={headerRef}>
        {title && <div className="SimpleTable-Title">{title}</div>}
        {Shortcuts && <div className="SimpleTable-Shortcuts">{Shortcuts instanceof Function ? <Shortcuts /> : Shortcuts}</div>}
      </div>
      <section className="table-container" ref={tableRef} style={{ height: printing ? 'auto' : tableContainerHeight, minHeight }}>
        <div className="table-header">
          {typeof TableHeader === 'function' && <TableHeader rows={rows} />}
          {typeof TableHeader !== 'function' && TableHeader}
        </div>
        <div
          className="table-body"
          style={{
            position: 'relative',
            height: printing ? 'auto' : `${rowVirtualizer.getTotalSize()}px`,
            minHeight: rowHeight,
          }}
        >
          <LoadingBlur loading={loading} />
          {!rows.length && !loading && (
            <div className="table-row empty">
              <div className="table-cell text-center">
                <i className="fa fa-inbox" />
                <span>No Records</span>
              </div>
            </div>
          )}
          {/* when printing render all rows */}
          {!!rows.length &&
            printing &&
            rows.map(
              (rowId: string, index: number): React.JSX.Element => (
                <TableRow key={rowId} ref={rowVirtualizer.measureElement} data-index={index} index={index} rowId={rowId} />
              )
            )}
          {/* Render only visible rows */}
          {!!rows.length &&
            !printing &&
            rowVirtualizer.getVirtualItems().map(
              (virtualRow: VirtualItem): React.JSX.Element => (
                <TableRow
                  key={virtualRow.index}
                  ref={rowVirtualizer.measureElement}
                  data-index={virtualRow.index}
                  style={{
                    position: 'absolute',
                    top: `${virtualRow.start}px`,
                    left: 0,
                    height: `${virtualRow.size}px`,
                    transition: 'top 0.2s ease-in-out', // Smooth transition
                  }}
                  index={virtualRow.index}
                  rowId={rows[virtualRow.index]}
                />
              )
            )}
        </div>
      </section>
      <div className="SimpleTable-Controls" style={{ height: printing ? contentHeight : tableContainerHeight, minHeight }}>
        {/* Top */}
        <div>
          <FormButton
            className="border-gray"
            variant="secondary-subtle"
            onClick={(): void => {
              if (printing) {
                const node = document.querySelector('.RouteContent');
                if (!node) return;
                const scrollPosition = containerRef.current.getBoundingClientRect().bottom - window.innerHeight + fullScreenPadding;
                node.scrollTop = scrollPosition;
                return;
              }
              rowVirtualizer.scrollToIndex(rows.length || 0);
            }}
            disabled={loading}
            icon={<i className="fa fa-chevron-down" />}
            tooltip="Scroll to Bottom"
          />
        </div>
        {/* Bottom */}
        <div>
          <FormButton
            className="border-gray"
            variant="secondary-subtle"
            onClick={(): void => {
              if (printing) {
                containerRef.current.scrollIntoView();
                return;
              }
              rowVirtualizer.scrollToIndex(0);
            }}
            disabled={loading}
            icon={<i className="fa fa-chevron-up" />}
            tooltip="Scroll to Top"
          />
        </div>
      </div>
    </div>
  );
};

export type SortCellProps = {
  direction: SortDirectionEnum;
  onSort: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
};
export const SortCell = ({ children, onSort, direction }: SortCellProps): React.JSX.Element => (
  <button className="sort" onClick={onSort}>
    <span>{children}</span>
    <Tooltip content={direction && (direction === SortDirectionEnum.Asc ? 'Ascending' : 'Descending')}>
      <span className="ms-1 d-print-none">
        <i className={`fa ${direction ? `fa-sort-${direction.toLowerCase()}` : 'fa-sort opacity-25'} `} />
      </span>
    </Tooltip>
  </button>
);

export default React.memo(SimpleTable);
