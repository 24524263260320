import { ConnectionDetails, QueryInputType, QueryObject, handleError, properCase, queryInput, unCamelCase } from '@/utils';
import { Location, LocationSearch, LocationTypeEnum, SortDirectionEnum } from '@/models/gen/graphql';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { SelectOption, SelectProps } from '@/components/Select';

import EditLocationModal from '@/components/EditLocationModal';
import { createLocationWithPickupPoint } from '@/api/services/locations';
import useClassNames from '@/hooks/useClassNames';
import { useSelectLocationFilter } from '@/api/services/locations/searchLocations';

export type SelectLocationState = {
  creating: boolean;
};
const initSelectLocationState = {
  creating: false,
};

export type SelectLocationProps = {
  name: string;
  label: string;
  placeholder: string;
  className?: string;
  onChange: (event) => void;
  value?: string;
  multiple?: boolean;
  filter?: ({ label, value }: { label: string; value: string }) => boolean;
  query?: QueryObject;
  onCreate?: (location: Location) => void;
  options?: {
    autoSelectLocation?: boolean;
    removeAirportPrefix?: boolean;
    showSelectAll?: boolean;
  };
} & SelectProps;
const SelectLocation = ({
  label,
  placeholder,
  onChange,
  value,
  multiple,
  filter = (): boolean => true,
  query = {},
  onCreate = undefined,
  options = {
    autoSelectLocation: false,
    removeAirportPrefix: false,
  },
  ...props
}: SelectLocationProps): JSX.Element => {
  const [state, setState] = useState<SelectLocationState>(initSelectLocationState);
  const { creating } = state;
  const [{ data, loading }, { refetch }] = useSelectLocationFilter();
  const classes = useClassNames('SelectLocation', props.className);

  const handleChange = (event): void => {
    const { value } = event?.target || {};
    if (value === '+') {
      setState((current: SelectLocationState): SelectLocationState => ({ ...current, creating: true }));
    } else {
      onChange(event);
    }
  };
  const handleSubmit = async (location: Location): Promise<void> => {
    try {
      const result = await createLocationWithPickupPoint(location);
      await getLocations();
      if (onCreate) onCreate(result);
    } catch (err) {
      handleError(err);
    } finally {
      setState((current: SelectLocationState): SelectLocationState => ({ ...current, creating: false }));
    }
  };
  const handleHide = (): void => {
    setState((current: SelectLocationState): SelectLocationState => ({ ...current, creating: false }));
  };

  const getLocations = useCallback(async (): Promise<ConnectionDetails<Location>> => {
    const payload: LocationSearch = {
      name: queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Asc, 0),
      ...query,
    };
    const result = await refetch([payload], {
      pageSize: null,
      page: null,
    });
    if (!(value || []).length && options?.autoSelectLocation && query?.airportCode && result?.rows?.length) {
      // Find an airport that matches the query by name.
      const airport = result?.rows?.find?.(
        ({ type, name }: Location): boolean => name === query?.airportCode?.values?.[0] && type === LocationTypeEnum.Airport
      );
      // If no airport is found, let's find the first airport in the list.
      const location = result?.rows?.find?.(({ type }: Location): boolean => type === LocationTypeEnum.Airport);
      if (airport?.id || location?.id) onChange({ target: { name: props?.name, value: airport?.id || location?.id } });
    }
    return result;
  }, [value, query, onChange, props?.name, options?.autoSelectLocation, refetch]);

  const selectOptions = useMemo((): { label: string; value: string }[] => {
    const rows = data?.rows || [];
    const result = rows.map(({ id, name, displayName }: Location): { label: string; value: string } => ({
      label: options?.removeAirportPrefix ? name : displayName || name,
      value: id,
    }));
    if (onCreate) result.unshift({ label: 'Create Location', value: '+' });
    return result;
  }, [data?.rows, onCreate, options?.removeAirportPrefix]);

  useEffect((): void => {
    // we want to get locations if the select input has values
    // we also want to get locations if the query changes
    if ((value || []).length || (options?.autoSelectLocation && query?.airportCode)) getLocations();
  }, [value, query, options?.autoSelectLocation, getLocations]);

  return (
    <>
      <Select
        {...props}
        label={label}
        className={classes}
        onChange={handleChange}
        value={value}
        placeholder={loading ? '...' : placeholder || label || (props.name ? properCase(unCamelCase(props.name)) : 'Options')}
        multiple={multiple}
        searchable
        onFocus={getLocations}
        loading={loading}
        options={options}
      >
        {(selectOptions || []).filter(filter).map(
          ({ label, value }: { label: string; value: string }, o: number): SelectOption => (
            <option value={value} key={o}>
              {label}
            </option>
          )
        )}
      </Select>
      {/* TODO Remove editLocationModal from the dropdown and use the global editLocationModal */}
      <EditLocationModal
        show={creating}
        title="Add Location"
        onHide={handleHide}
        onCancel={handleHide}
        onSubmit={handleSubmit}
        data={undefined}
      />
    </>
  );
};

export default React.memo(SelectLocation);
