import './styles.scss';

import { Col, Row } from 'react-bootstrap';
import { Datetime, getClasses } from '@/utils';
import React, { HTMLAttributes, useMemo } from 'react';

import { Message } from '@/hooks/useSocket/chat/useChatRooms';

type ChatMessageBubbleProps = HTMLAttributes<HTMLDivElement> & Message & {};
const ChatMessageBubble = ({
  type,
  content,
  role_name,
  datetime,
  username,
  user_display_name,
  recipeint_read_at,
  recipeint_read_by,
  ...props
}: ChatMessageBubbleProps) => {
  const timestamp = useMemo(() => new Datetime().setAsUTC(datetime).toLocaleDatetime().time, [datetime]);
  return (
    <div
      className={getClasses(
        'ChatMessageBubble',
        props?.className,
        role_name === 'crew' ? 'incoming' : '',
        ['dispatch', 'driver'].includes(role_name) ? 'outgoing' : ''
      )}
    >
      <Row
        className={getClasses(role_name === 'crew' ? 'flex-row' : '', ['dispatch', 'driver'].includes(role_name) ? 'flex-row-reverse' : '')}
      >
        <Col sm="auto">
          {(type || '').startsWith('image/') ? (
            <span>
              <img className="{max-width:100%;height:auto;}" src={`data:${type};charset=utf-8;base64,${content}`} alt="Avatar" />
            </span>
          ) : (
            <div className="ChatMessageBubble-Message">{content}</div>
          )}
        </Col>
      </Row>
      <Row
        className={getClasses(role_name === 'crew' ? 'flex-row' : '', ['dispatch', 'driver'].includes(role_name) ? 'flex-row-reverse' : '')}
      >
        <Col sm="auto" className={getClasses('ChatMessageBubble-Stats')}>
          {user_display_name || username}, {timestamp}{' '}
          <span className="text-secondary uppercase">{!!recipeint_read_at && !!recipeint_read_by ? 'Read' : ''}</span>
        </Col>
      </Row>
    </div>
  );
};

export default ChatMessageBubble;
