import EnumDropdown, { EnumDropdownProps } from '@/components/EnumDropdown';

import { ReactNode } from 'react';
import { ReportReasonEnum } from '@/models/gen/graphql';

type ReportReasonDropdownProps = Omit<EnumDropdownProps, 'enum' | 'name'> & {
  name?: string;
};
const ReportReasonDropdown = ({ name = 'reportReason', ...props }: ReportReasonDropdownProps): ReactNode => {
  return (
    <EnumDropdown
      {...props}
      enum={ReportReasonEnum}
      name={name}
      options={{ locale: props?.options?.locale || { 'Select...': 'Reason' } }}
    />
  );
};

export default ReportReasonDropdown;
