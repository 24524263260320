import React, { useMemo } from 'react';

import { TripModalViewProps } from '@/features/Trips/components/TripModal';
import TripViewDrawer from './drawer';
import TripViewModal from './modal';

export type TripViewProps = Omit<TripModalViewProps, 'as'>;
const TripView = ({ as, ...props }: TripModalViewProps) => {
  const As = useMemo(() => (as === 'drawer' ? TripViewDrawer : TripViewModal), [as]);
  return <As {...props} />;
};

export default TripView;
