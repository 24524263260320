import { createPersistentComponentState } from '@/state';

const settingsStatePages = ['trips', 'manifests', 'users', 'reports', 'availability', 'invoices', 'clients', 'airlines', 'rates'] as const;
type SettingsStateAddonsPagePreferences = SettingsStatePagePreferences & { addonAsDrawer?: boolean };

export type SettingsStatePage = (typeof settingsStatePages)[number];
export type SettingsState = {
  filters: Record<string, SettingsStateFilters>;
  columns: Record<string, SettingsStateColumns>;
  headings: Record<string, SettingsStateHeadings>;
  details: Record<string, SettingsStateDetails>;
  addons: SettingsStateAddonsPagePreferences;
} & Partial<Record<SettingsStatePage, Record<string, SettingsStatePagePreferences>>>;
export type SettingsStateFilters = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateColumns = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateHeadings = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStateDetails = {
  remember: boolean;
  saved: Record<string, unknown>;
};
export type SettingsStatePagePreferences = {
  dynamicContentAs?: string;
};

const initSettingsState: SettingsState = {
  filters: {},
  columns: {},
  headings: {},
  details: {},
  addons: {},
  ...settingsStatePages.reduce((acc, page) => ({ ...acc, [page]: {} }), {}),
};

const useSettings = createPersistentComponentState('settings', initSettingsState);

export default useSettings;
