import { Datetime, queryInput } from '../../utils';
import { Flag, FlagWithTrip } from '../../models/gen/graphql';
import React, { useCallback, useEffect } from 'react';

import EditModal from '../../components/EditModal/new';
import RadioButton from '@/components/RadioButton';
import { createComponentState } from '@/state';
import { searchFlags } from '../../queries';
import useDb from '../../hooks/useDb';
import { useGetFlagsByTripId } from '@/api/services/trips/searchTrips';
import useOnChange from '@/hooks/useOnChange';
import useTrips from '../../hooks/useTrips';

type EditFlagModalState = {
  show?: boolean;
  tripId?: string;
  flightNumber?: string;
  servicerIataAirlineCode?: string;
  scheduled?: string;
  flags?: Record<string, unknown>;
  onSubmit?: (data?: any) => Promise<void>;
  onHide?: () => void;
};

const initEditFlagModalState: EditFlagModalState = {
  show: false,
  tripId: '',
  flightNumber: '',
  servicerIataAirlineCode: '',
  scheduled: '',
  flags: undefined,
  onSubmit: async (): Promise<void> => undefined,
  onHide: (): void => undefined,
};

export const useEditFlagModal = createComponentState(initEditFlagModalState);

const EditFlag = (): JSX.Element => {
  const [state, setState] = useEditFlagModal(({ state, setState }) => [state, setState]);
  const { show = false, tripId = '', onHide = () => undefined, flags = undefined, onSubmit = async () => undefined } = state;
  const onChange = useOnChange(setState);

  // Init Queries
  const [, { handleSubmitFlag }] = useTrips();
  const {
    data: searchFlagsResponse,
    loading: searchFlagsLoading,
    refetch: refetchSearchFlags,
  } = useDb('Flags', searchFlags.query, { ttl: 0 });
  const searchFlagsData = {
    rows: (searchFlagsResponse?.searchFlags?.flagConnection?.edges || []).map(({ node }: { node: any }): any => node),
  };
  const [{ data: getFlagsByTripIdData, loading: getFlagsByTripIdLoading }, { fetch: getFlagsByTripId }] = useGetFlagsByTripId();

  const getTripFlags = useCallback(async (): Promise<void> => {
    const tripFlags = await getFlagsByTripId([{ id: queryInput(tripId) }]);
    const result = {};
    tripFlags?.forEach((node: FlagWithTrip): void => {
      if ((tripFlags || []).filter((n: FlagWithTrip): boolean => n.id === node.id).length > 0) {
        result[node.id] = true;
      }
    });
    if (Object.values(result).length === 0) return;
    setState((current: any): any => ({ ...current, flags: result }));
  }, [getFlagsByTripId, setState, tripId]);

  const handleOnSubmit = async () => {
    const creates = [];
    const deletes = [];
    Object.entries(flags || {}).forEach(([key, value]: [string, boolean]): void => {
      const foundFlag = (getFlagsByTripIdData || []).find((node: FlagWithTrip): boolean => node.id === key);
      if (foundFlag && !value) deletes.push(key);
      if (!foundFlag && value) creates.push(key);
    });
    await handleSubmitFlag({ updates: { creates, deletes }, tripId });
    onSubmit?.();
    setState({ show: false });
  };

  const handleOnHide = () => {
    onHide?.();
    setState({ show: false });
  };

  useEffect((): void => {
    if (!tripId) return;
    refetchSearchFlags();
    getTripFlags();
  }, [getTripFlags, refetchSearchFlags, tripId]);

  const title = `Trip Flags - ${(state?.servicerIataAirlineCode || '').toUpperCase()}${state?.flightNumber || ''} ${
    state?.scheduled ? new Datetime(state?.scheduled).frontendDatetimeShort : ''
  }`;

  return (
    <EditModal
      name="editFlag"
      icon="sv sv-flag1"
      loading={getFlagsByTripIdLoading || searchFlagsLoading}
      show={show}
      onHide={handleOnHide}
      title={title}
      onSubmit={searchFlagsData?.rows?.length ? handleOnSubmit : false}
      options={{
        footer: {
          submitButtonText: 'Confirm Changes',
        },
      }}
    >
      {searchFlagsData?.rows?.length === 0 && <h4 className="text-center">No Flags to Show</h4>}
      {searchFlagsData?.rows?.length > 0 && (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '1rem', minHeight: 100 }}>
          {(searchFlagsData?.rows || []).map(
            (flag: Flag, f: number): JSX.Element => (
              <RadioButton
                onClick={() => onChange.toggle({ target: { name: `flags.${flag.id}`, value: flag.id } })}
                checked={!!state?.flags?.[flag.id]}
                style={{
                  justifyContent: 'start',
                  borderColor: flag.color,
                }}
                key={f}
              >
                <i className={`sv sv-flag-${state?.flags?.[flag.id] ? 'filled' : 'empty'} {font-size:1.5rem;color:${flag.color};}`} />
                {flag.name}
              </RadioButton>
            )
          )}
        </div>
      )}
    </EditModal>
  );
};

export default EditFlag;
