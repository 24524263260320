import {
  Completion,
  CompletionCurrencyEnum,
  CreateCompletionInput,
  RunCompletionOnTripInput,
  RunCompletionOnTripResponse,
} from '@/models/gen/graphql';
import { Validation, createNotification, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { RunCompletionOnTripDocument } from '@/api/queries';
import { Toast } from '@/models';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';

const title = 'Update Completion On Trip';

const RUN_COMPLETION_ON_TRIP_VALIDATION_CRITERIA = {
  'completionTypeId!': (val: string): Validation.Validity => ({
    valid: !!val,
    message: !Validation.isValidUUID(val) ? 'Please select a completion type.' : undefined,
    defaultValue: '',
  }),
  'cost!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'datetime!': (val: string): Validation.Validity => ({
    valid: !!val,
    defaultValue: '',
  }),
  'currency!': (val: any): Validation.Validity => ({
    valid: Object.values(CompletionCurrencyEnum).includes(val),
    defaultValue: CompletionCurrencyEnum.Usd,
  }),
  'comment!': (val: string): Validation.Validity => ({
    valid: !!val?.length && Validation.isAlphanumeric(val),
    defaultValue: '',
  }),
  // TODO: The fields below is metadata that should be populated by the BE
  'distanceMeters!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'efficiency!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
  'equivalency!': (val: number): Validation.Validity => ({
    valid: Validation.isNumber(val),
    defaultValue: 0,
  }),
};
const validator = new Validation.Validator(RUN_COMPLETION_ON_TRIP_VALIDATION_CRITERIA);

type RunCompletionOnTripApiResponse = GraphApiResponse<typeof RunCompletionOnTripDocument>;

const runCompletionOnTripResponseSelector = (res: RunCompletionOnTripApiResponse): RunCompletionOnTripResponse =>
  !res.errors ? (res?.runCompletionOnTrip as RunCompletionOnTripResponse) : undefined;

const [runRunCompletionOnTrip] = graphApi(RunCompletionOnTripDocument, {
  onError: (res: RunCompletionOnTripApiResponse): void => handleError(res, { notification: { title } }),
});

const runCompletionOnTrip = async (
  tripIds: string[],
  completions: Partial<CreateCompletionInput>
): Promise<RunCompletionOnTripResponse> => {
  const input: RunCompletionOnTripInput = {
    completion: validator.create(completions),
    tripIds,
  };
  const res = await runRunCompletionOnTrip({ input });
  return runCompletionOnTripResponseSelector(res);
};

export const handleSubmitCompletion = async (tripIds: string[], completion: Partial<Completion>): Promise<RunCompletionOnTripResponse> => {
  if (tripIds.every((id: string): boolean => !Validation.isValidUUID(id))) {
    createNotification('Failed to update completion on trip \n Contact Support: invalid trip', Toast.Type.DANGER, 'Complete Trip');
    return;
  }
  const input: Partial<CreateCompletionInput> = {
    comment: completion?.comment,
    completionTypeId: completion?.completionTypeId,
    cost: completion?.cost,
    currency: completion?.currency,
    datetime: completion?.datetime,
  };
  return await runCompletionOnTrip(tripIds, input);
};

export const useRunCompletionOnTrip = createGraphApiHook(runCompletionOnTrip);

export default runCompletionOnTrip;
