import ComplexTable, {
  ComplexTableCell,
  ComplexTableHeader,
  ComplexTableProps,
  ComplexTableRow,
  ComplexTableRowRendererProps,
  ComplexTableSortableCell,
} from '@/components/ComplexTable';
import { Datetime, Validation, getProperty, minMax } from '@/utils';
import { ReactNode, forwardRef, useCallback } from 'react';

import ActionsDropdown from '@/components/ActionsDropdown';
import { Button } from 'react-bootstrap';
import { CombineRule } from '@/models/gen/graphql';
import { ComplexFormItem } from '@/hooks/useComplexForm';
import { DATETIME_FE_FORMAT } from '@/constants';
import ImportContractProviderDropdown from '@/components/ImportContractProviderDropdown';
import NumberInput from '@/components/NumberInput';
import useConfirmation from '@/hooks/useConfirmation';

const COLUMN_SIZES = [
  '1.5fr', // Airline
  '1.25fr', // Created At
  '1.25fr', // Created By
  '0.75fr', // Pickup Window
  '0.75fr', // Discount
  '0.75fr', // Turnaround Time
  '4rem', // Actions
];

export type CombineRulesTableProps = Omit<ComplexTableProps, 'columns' | 'header' | 'row'>;

export type CombineRuleMetadata = {
  id: string;
  isDirty: boolean;
  isValid: boolean;
  validity: {
    contractId: boolean;
    pickupWindow: boolean;
    turnaroundThreshold: boolean;
    discount: boolean;
  };
};
const getMetadata = (item: CombineRule, { update }: { update: Record<string, unknown> }): CombineRuleMetadata => {
  const parsedDiscount = parseFloat(item?.discount);
  const validity = {
    contractId: !!item?.contractId, //Validation.isValidUUID(item?.contractId),
    pickupWindow: Validation.isNumber(item?.pickupWindow) && Validation.isWithinThreshold(item?.pickupWindow, 0, 1440),
    turnaroundThreshold: Validation.isNumber(item?.turnaroundThreshold) && Validation.isWithinThreshold(item?.turnaroundThreshold, 0, 1440),
    discount: Validation.isNumber(parsedDiscount) ? Validation.isWithinThreshold(parsedDiscount, 0, 100) : undefined,
  };
  return {
    id: item?.id,
    isDirty: !!update,
    isValid: !item || !Object.values(validity).includes(false),
    validity,
  };
};

const CombineRulesTable = (props: CombineRulesTableProps, ref): ReactNode => {
  const sorting = useCallback((items: ComplexFormItem<CombineRule>[], col: number, dir: 'asc' | 'desc'): ComplexFormItem<CombineRule>[] => {
    if (col === undefined) return items;
    const column = [
      'data.provider.name', // Airline
      'data.creator.fullName', // Creator
      'data.createdAt', // Create At Time
      'data.pickupWindow', // Pickup Window
      'data.discount', // Discount
      'data.turnaroundThreshold', // Turnaround Time
    ][col];
    return items.sort(
      (a: ComplexFormItem<CombineRule>, b: ComplexFormItem<CombineRule>): number =>
        (getProperty(column, a) < getProperty(column, b) ? -1 : getProperty(column, a) > getProperty(column, b) ? 1 : 0) *
        (dir === 'desc' ? -1 : 1)
    );
  }, []);
  return (
    <ComplexTable
      {...props}
      columns={COLUMN_SIZES}
      header={CombineRulesTableHeader}
      row={CombineRulesTableRow}
      metadata={getMetadata}
      options={{ ...(props?.options || {}), sorting }}
      ref={ref}
    />
  );
};

const CombineRulesTableHeader = (): ReactNode => (
  <ComplexTableHeader>
    <ComplexTableSortableCell index={0}>Airline</ComplexTableSortableCell>
    <ComplexTableSortableCell index={1}>Created By</ComplexTableSortableCell>
    <ComplexTableSortableCell index={2}>Created At</ComplexTableSortableCell>
    <ComplexTableSortableCell index={3}>P/U Window</ComplexTableSortableCell>
    <ComplexTableSortableCell index={4}>Discount (%)</ComplexTableSortableCell>
    <ComplexTableSortableCell index={5}>Turnaround Time</ComplexTableSortableCell>
    <ComplexTableCell className="text-center">
      <Button variant="icon" className="{cursor:normal;}">
        <i className="fa fa-ellipsis-h fs-5" />
      </Button>
    </ComplexTableCell>
  </ComplexTableHeader>
);

const CombineRulesTableRow = ({ index, item, data, getValue, onChange, onDelete }: ComplexTableRowRendererProps): ReactNode => {
  const { validity } = item;
  const contractId = getValue('contractId');
  const pickupWindow = getValue('pickupWindow');
  const discount = getValue('discount');
  const turnaroundThreshold = getValue('turnaroundThreshold');
  const createdAt = getValue('createdAt');
  const createdBy = getValue('creator.fullName');
  const isAddition = !item?.data?.id;
  const providerDisplayName = getValue('provider.name');
  const confirmDelete = useConfirmation({
    Body: { title: 'Delete Combine Rule?', message: 'Are you sure you want to delete this combine rule?' },
  });

  const handleDelete = async (): Promise<void> => {
    try {
      await confirmDelete();
      onDelete();
    } catch (err) {
      console.log('Delete Cancelled');
    }
  };

  return (
    <ComplexTableRow>
      <ComplexTableCell>
        {!isAddition && !!data?.contractId ? (
          providerDisplayName || '--'
        ) : (
          <ImportContractProviderDropdown
            name="contractId"
            value={contractId}
            onChange={onChange('contractId')}
            options={{ locale: { 'Select...': 'Airline' } }}
            isValid={validity?.contractId === true}
            isInvalid={validity?.contractId === false}
          />
        )}
      </ComplexTableCell>
      <ComplexTableCell>{createdBy || '--'}</ComplexTableCell>
      <ComplexTableCell>
        {createdAt ? new Datetime().setAsUTC(createdAt).toLocaleDatetime().format(DATETIME_FE_FORMAT) : '--'}
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="pickupWindow"
          value={pickupWindow}
          onChange={(value: number): void => onChange('pickupWindow')(minMax(value, 0, 1440))}
          append={'mins'}
          placeholder="--"
          isDirty={!isAddition && pickupWindow !== data?.pickupWindow}
          isValid={isAddition && validity?.pickupWindow === true}
          isInvalid={validity?.pickupWindow === false}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="discount"
          value={discount}
          onChange={(value: number): void => onChange('discount')(minMax(value, 0, 100)?.toString?.())}
          append={'%'}
          placeholder="--"
          isDirty={!isAddition && discount !== (data?.discount ?? '')}
          isValid={isAddition && validity?.discount === true}
          isInvalid={validity?.discount === false}
        />
      </ComplexTableCell>
      <ComplexTableCell>
        <NumberInput
          name="turnaroundThreshold"
          value={turnaroundThreshold}
          onChange={(value: number): void => onChange('turnaroundThreshold')(minMax(value, 0, 1440))}
          append={'mins'}
          placeholder="--"
          isDirty={!isAddition && turnaroundThreshold !== data?.turnaroundThreshold}
          isValid={isAddition && validity?.turnaroundThreshold === true}
          isInvalid={validity?.turnaroundThreshold === false}
        />
      </ComplexTableCell>
      <ComplexTableCell className="text-center">
        <ActionsDropdown
          icon={<i className="fa fa-ellipsis-h fs-5" />}
          items={[{ key: index, label: 'DELETE', danger: true }]}
          className="ActionsCellDropdown text-center"
          onClick={handleDelete}
        />
      </ComplexTableCell>
    </ComplexTableRow>
  );
};

export default forwardRef(CombineRulesTable);
