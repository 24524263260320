import { Button, Col, Row } from 'react-bootstrap';
import { Datetime, QueryInputType, Validation, onEnter, queryInput } from '../../utils';
import React, { useEffect, useMemo } from 'react';
import VirtualTable, { DynamicCell, VirtualTableRow } from '../../components/VirtualTable';

import ConfirmationButton from '../../components/ConfirmationButton';
import { DATETIME_FE_FORMAT_SHORT } from '../../constants';
import EditModal from '../../components/EditModal/new';
import FormField from '../../components/FormField';
import { SortDirectionEnum } from '../../models/gen/graphql';
import TippyWhen from '../../components/TippyWhen';
import deleteCommunicationBulk from '@/api/services/communications/deleteCommunicationBulk';
import { useSearchCommunications } from '@/api/services/communications/searchCommunications';
import useTrips from '../../hooks/useTrips';
import { createComponentState } from '@/state';
import useOnChange from '@/hooks/useOnChange';

type EditCommunicationModalState = {
  show?: boolean;
  loading?: boolean;
  tripId?: string;
  offset?: string;
  message?: string;
  servicerIataAirlineCode?: string;
  flightNumber?: string;
  scheduled?: string;
  onSubmit?: () => Promise<void>;
  onHide?: () => void;
};

const initEditCommunicationModalState: EditCommunicationModalState = {
  show: false,
  loading: false,
  tripId: '',
  offset: '',
  message: '',
  servicerIataAirlineCode: '',
  flightNumber: '',
  scheduled: '',
  onSubmit: async (): Promise<void> => undefined,
  onHide: (): void => undefined,
};

export const useEditCommunicationModal = createComponentState(initEditCommunicationModalState);

const EditCommunication = (): JSX.Element => {
  const [state, setState] = useEditCommunicationModal(({ state, setState }) => [state, setState]);
  const {
    show = false,
    loading = false,
    tripId,
    offset,
    message,
    servicerIataAirlineCode,
    flightNumber,
    scheduled,
    onHide = () => undefined,
    onSubmit = async () => undefined,
  } = state;
  const onChange = useOnChange(setState);
  const [validity, valid] = useMemo((): [any, boolean] => {
    const result: any = {};
    result.message = {
      valid:
        !!(message || '').trim().length && Validation.isAlphanumeric(message)
          ? Validation.ValidityType.VALID
          : Validation.ValidityType.INVALID,
    };
    result.tripId = {
      valid: Validation.isValidUUID(tripId) ? Validation.ValidityType.VALID : Validation.ValidityType.INVALID,
    };
    const isValid = !Object.values(result)
      .map(({ valid }: any): any => valid)
      .includes(Validation.ValidityType.INVALID);
    return [result, isValid];
  }, [message, tripId]);

  const [, { handleSubmitCommunication }] = useTrips();
  const [{ data: searchCommunicationsData, loading: searchCommunicationsLoading }, { refetch: refetchCommunications }] =
    useSearchCommunications();
  const { rows = [] } = searchCommunicationsData || {};

  const handleHide = () => {
    onHide?.();
    setState({ show: false });
  };

  // Crud Handlers
  const handleDeleteCommunications = async (id: string): Promise<void> => {
    await deleteCommunicationBulk([id]);
    if (!tripId) return;
    await refetchCommunications();
    onSubmit?.();
  };

  const handleSubmit = async (): Promise<void> => {
    await handleSubmitCommunication({ tripId, message });
    if (!tripId) return;
    await refetchCommunications();
    onSubmit?.();
  };

  useEffect((): void => {
    if (!tripId) return;
    refetchCommunications([
      {
        tripId: queryInput(tripId),
        createdAt: queryInput([], QueryInputType.ISNOTNULL, SortDirectionEnum.Desc),
      },
    ]);
  }, [refetchCommunications, tripId]);

  const title = `Communication - ${(servicerIataAirlineCode || '').toUpperCase()}${flightNumber || ''} ${
    scheduled ? new Datetime(scheduled).frontendDatetimeShort : ''
  }`;

  return (
    <EditModal
      name="editCommunication"
      icon="sv sv-communication"
      show={show}
      onHide={handleHide}
      title={title}
      options={{ Footer: null }}
      loading={loading}
      size="lg"
    >
      <Row className="my-3">
        <Col className="d-flex gap-2">
          <div className="flex-grow-1">
            <FormField
              name="message"
              value={message || ''}
              onChange={onChange}
              onKeyDown={onEnter(handleSubmit)}
              placeholder="Enter New Communication"
              valid={validity?.message?.valid}
              condensed
            />
          </div>
          <div>
            <TippyWhen isTrue={!valid} options={{ content: 'No changes have been made, or fields are invalid.' }}>
              <span>
                <Button disabled={!valid} name="SUBMIT" variant="success" onClick={handleSubmit}>
                  <i className="sv sv-check" />
                </Button>
              </span>
            </TippyWhen>
          </div>
        </Col>
      </Row>
      {/* Table Component */}
      <Row>
        <Col>
          <VirtualTable
            name="tripCommunication"
            data={rows}
            loading={searchCommunicationsLoading}
            header={{
              creator: 'Created By',
              message: 'Communication',
              createdAt: 'Date',
              id: 'Delete',
            }}
            dynamic
            dynamicRowHeight
            rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
              <VirtualTableRow
                context={{
                  ...context,
                  rowType: _type,
                  data,
                  index,
                }}
              >
                <DynamicCell
                  selector="creator"
                  placeholder="--"
                  className="text-center alternate"
                  width="calc(100% / 4)"
                  render={({ value }: { value: any }): string => `${value?.firstName || ''} ${value?.lastName || ''}`.trim() || '--'}
                />
                <DynamicCell
                  selector="message"
                  placeholder="--"
                  className="text-center alternate {overflow:hidden|auto!;}>span"
                  width="100%"
                />
                <DynamicCell
                  selector="createdAt"
                  placeholder="--"
                  className="text-center alternate"
                  width="calc(100% / 4)"
                  render={({ value }: { value: string }): string =>
                    offset ? new Datetime(value).applyOffset(offset).format(DATETIME_FE_FORMAT_SHORT) : ''
                  }
                />
                <DynamicCell
                  selector="id"
                  placeholder="--"
                  className="text-center alternate"
                  width="calc(100% / 6)"
                  render={({ value }: { value: string }): JSX.Element => (
                    <ConfirmationButton
                      name="DELETE"
                      feedback="Delete Communication"
                      disabled={loading}
                      variant="transparent"
                      onConfirm={(): Promise<void> => handleDeleteCommunications(value)}
                      icon={<i className="sv sv-trash2 text-danger" />}
                    />
                  )}
                />
              </VirtualTableRow>
            )}
          />
        </Col>
      </Row>
    </EditModal>
  );
};

export default EditCommunication;
