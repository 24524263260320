import { Location, LocationTypeEnum, SortDirectionEnum } from '../../models/gen/graphql';
import { QueryInputType, Validation, onEnter, parsePhoneNumber, queryInput } from '../../utils';
import React, { useEffect, useRef, useState } from 'react';
import { SEARCH_LOCATIONS_TABLE_PAGE_SIZE, useSearchLocationsTable } from '../../api/services/locations/searchLocations';
import VirtualTable, { DynamicCell, FormatCell, SelectCell, VirtualTableRow, useVirtualTable } from '../../components/VirtualTable';

import { Badge } from 'react-bootstrap';
import ConfirmationButton from '../../components/ConfirmationButton';
import { ConnectionDetails } from '../../utils/custom';
import EditLocationModal from '../../components/EditLocationModal';
import Filters from '../../components/Filters';
import FormButton from '../../components/FormButton';
import FormField from '../../components/FormField';
import PageInfo from '../../components/PageInfo';
import deleteLocationBulk from '../../api/services/locations/deleteLocationBulk';
import { getClasses } from '../../utils/strings';
import { stringify } from '../../utils/objects';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import EnumDropdown from '@/components/EnumDropdown';
import Dropdown from '@/components/Dropdown';

type LocationTableState = {
  selected: string[];
  editing: boolean;
  creating: boolean;
  search: string;
  sorting: {
    column: string;
    direction: any;
  };
};

const initLocationTableState: LocationTableState = {
  selected: [],
  creating: false,
  editing: false,
  search: '',
  sorting: {
    column: undefined,
    direction: undefined,
  },
};
const LocationsTable = (): JSX.Element => {
  const [state, setState] = useState(initLocationTableState);
  const { creating, editing, selected, search, sorting } = state;

  const [{ data, loading }, { fetch: searchLocationTable, refetch, fetchMore }] = useSearchLocationsTable();
  const { rows, hasNextPage, totalCount } = data || {};

  const lastFilters = useRef(undefined);
  const appliedQuery = useRef(undefined);

  const onSort = async (column: string, direction: string): Promise<void> => {
    setState((current: LocationTableState): LocationTableState => ({ ...current, sorting: { column, direction } }));
  };

  const { makeSortable, onSelect, filteredRows, selectedRows } = useVirtualTable(setState, {
    selected,
    sorting: {
      ...sorting,
      onSort,
    },
    rows,
    search,
  });

  const onHide = async (): Promise<void> => {
    setState((current: LocationTableState): LocationTableState => ({ ...current, editing: false, creating: false, selected: [] }));
    await refetch();
  };
  const getMore = async (after: number): Promise<ConnectionDetails<Location>> => {
    if (loading || !hasNextPage) return;
    return await fetchMore(appliedQuery.current, {
      page: Math.round(after / SEARCH_LOCATIONS_TABLE_PAGE_SIZE),
      merge: true,
    });
  };
  const onSubmit = async (filters) => {
    const query = {
      active: Validation.isNumber(filters?.active) ? queryInput([filters?.active]) : null,
      airportCode: filters?.airportCode?.length ? queryInput(filters?.airportCode) : null,
      type: filters?.type ? queryInput(filters?.type) : null,
    };

    if (!!sorting?.column && !!sorting?.direction) {
      query[sorting?.column] = queryInput([], QueryInputType.DEFAULT, sorting?.direction?.toUpperCase());
    } else query['createdAt'] = queryInput([], QueryInputType.DEFAULT, SortDirectionEnum.Desc);

    if (stringify.compare(appliedQuery.current, query)) refetch();
    else searchLocationTable([query]);
    appliedQuery.current = query;
    lastFilters.current = filters;
  };
  const onCreate = (): void => {
    setState((current: LocationTableState): LocationTableState => ({ ...current, creating: true }));
  };
  const onEdit = (): void => {
    setState((current: LocationTableState): LocationTableState => ({ ...current, editing: true }));
  };
  const onDelete = async (): Promise<void> => {
    try {
      await deleteLocationBulk(selected);
    } catch (err) {
      console.error(err);
    } finally {
      await onHide();
    }
  };

  useEffect((): void => {
    if (!rows?.length) return;
    onSubmit(lastFilters.current);
  }, [sorting]);

  return (
    <div className="LocationsTable d-flex flex-column">
      <Filters
        name="locationFilters"
        onSubmit={onSubmit}
        onReset={(): void => setState((current: LocationTableState): LocationTableState => ({ ...current, search: '' }))}
        primary={({ values, onChange }): JSX.Element => {
          const { search, type, active, airportCode } = values;
          const handleChange = (name: string, value: any): void => onChange({ target: { name, value } });
          return (
            <>
              <EnumDropdown
                onChange={(value) => handleChange('type', value)}
                name="type"
                value={type}
                options={{ locale: { 'Select...': 'Location Type' } }}
                enum={LocationTypeEnum}
              />
              <Dropdown
                name="active"
                value={!Validation.isNil(active) && !Number.isNaN(active) ? `${active}` : undefined}
                onChange={(value) => {
                  if (Validation.isNil(value) && Number.isNaN(value)) return handleChange('active', undefined);
                  handleChange('active', parseInt(value));
                }}
                options={{ locale: { 'Select...': 'Status' } }}
                items={[
                  {
                    label: 'Inactive',
                    value: '0',
                  },
                  {
                    label: 'Active',
                    value: '1',
                  },
                ]}
              />
              <AirportGroupDropdown
                name="airportCode"
                onChange={(value) => handleChange('airportCode', value)}
                value={airportCode}
                options={{ locale: { 'Select...': 'Airport' } }}
              />
              <FormField
                name="search"
                onChange={onChange}
                onBlur={(): void => setState((current: LocationTableState): LocationTableState => ({ ...current, search }))}
                onKeyDown={onEnter((): void => setState((current: LocationTableState): LocationTableState => ({ ...current, search })))}
                placeholder="Search"
                value={search}
                style={{ width: 300 }}
                condensed
              />
            </>
          );
        }}
        alternate={(): JSX.Element => (
          <>
            <FormButton icon={<i className="sv sv-2x sv-plus-square" />} name="CREATE_LOCATION" variant="outline-gray" onClick={onCreate}>
              Add Location
            </FormButton>
            <FormButton
              icon={<i className="sv sv-2x sv-layers" />}
              name="EDIT_LOCATION"
              variant="outline-gray"
              onClick={onEdit}
              disabled={selected.length !== 1}
            >
              Edit Location{selected.length > 1 ? 's' : ''}
              {selected.length > 1 && (
                <Badge className="ms-1" pill bg="success">
                  {selected.length}
                </Badge>
              )}
            </FormButton>
            <ConfirmationButton
              icon={<i className="sv sv-2x sv-trash2 {font-size:1.5rem;}" />}
              feedback="Delete Locations"
              name="DELETE_LOCATION"
              onConfirm={onDelete}
              disabled={selected.length === 0}
              options={{
                confirmation: {
                  Body: {
                    message: 'Are you sure you want to delete the selected location(s)?',
                  },
                },
              }}
            />
          </>
        )}
        submitOnMount
      />
      <PageInfo>
        {filteredRows.length} / {totalCount || 0} Locations
      </PageInfo>
      <VirtualTable
        name="locationsTable"
        data={filteredRows}
        onLazyLoad={hasNextPage && rows.length < totalCount ? getMore : undefined}
        loading={loading}
        selected={selected}
        header={{
          name: 'Location',
          cityName: 'City',
          stateCode: 'State',
          zipCode: 'Zip Code',
          phoneNumber: 'Phone',
        }}
        rowRenderer={({ index, data: { _type, ...data } = {}, context = {} }: { index: any; data: any; context: any }): JSX.Element => (
          <VirtualTableRow
            context={{
              ...context,
              rowType: _type,
              data,
              index,
              selected: _type === 'header' ? selected.length === context.rows.length : selected.includes(data?.id),
            }}
            className={getClasses(selected.includes(index) ? 'selected' : '')}
            onDoubleClick={(): void =>
              setState(
                (current: LocationTableState): LocationTableState => ({
                  ...current,
                  selected: Array.from(new Set([data?.id])),
                  editing: true,
                })
              )
            }
          >
            <SelectCell onClick={onSelect} />
            <DynamicCell
              selector="name"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 5)"
              sorting={makeSortable('name')}
            />
            <DynamicCell
              selector="cityName"
              placeholder="--"
              className="text-center"
              width="calc(100% / 5)"
              sorting={makeSortable('cityName')}
            />
            <DynamicCell
              selector="stateCode"
              placeholder="--"
              className="text-center"
              width="calc(100% / 10)"
              sorting={makeSortable('stateCode')}
            />
            <DynamicCell
              selector="zipCode"
              placeholder="--"
              className="text-center"
              width="calc(100% / 10)"
              sorting={makeSortable('zipCode')}
            />
            <FormatCell
              selector="phoneNumber"
              placeholder="--"
              className="text-center alternate"
              width="calc(100% / 10)"
              sorting={makeSortable('phoneNumber')}
              format={(val: string): string => parsePhoneNumber(val, ' ')}
            />
          </VirtualTableRow>
        )}
      />
      <EditLocationModal show={!!editing || !!creating} data={editing ? selectedRows[0] : undefined} onHide={onHide} onCancel={undefined} />
    </div>
  );
};

export default LocationsTable;
