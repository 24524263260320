import React, { useEffect, useState } from 'react';
import { generateQueryString, getClasses, parseQueryString } from '../../utils/strings';

import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Route } from '../MainMenu';
import Tippy from '@tippyjs/react';
import { useGetAvailabilityRequestCount } from '../../api/services/users/searchUsers';
import useInterval from '../../hooks/useInterval';
import useSession from '@/state/session';

type ShortcutProps = {
  className?: string;
  children?: React.ReactNode;
} & Route;

const Shortcut = ({ url, search: queryString, label, icon, className, children }: ShortcutProps): JSX.Element => {
  const [search, setSearch] = useState(url);

  const createLink = () => {
    const currentPath = window.location.pathname;
    const searchObj = parseQueryString(window.location.search);
    const pathSearchObj = parseQueryString(queryString || '');
    const result =
      currentPath === url
        ? generateQueryString({ ...searchObj, ...pathSearchObj }).replace(/=undefined/g, '')
        : (queryString || '').replace(/^\?/, '') || '';
    setSearch(result);
  };

  useEffect(() => {
    createLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tippy content={label}>
      <span className={getClasses('Shortcut', className)} onMouseOver={createLink} onFocus={createLink}>
        <Link
          tabIndex={-1}
          to={`${url}?${search || ''}`}
          reloadDocument={!!url?.startsWith?.(window.location.pathname) || !!queryString}
          className={`${location.pathname.includes(url) ? 'active' : ''}`}
        >
          {icon}
          {children}
        </Link>
      </span>
    </Tippy>
  );
};
const DEFAULT_REQUEST_AVAILABILITY_INTERVAL_SECONDS = 300; // 5 minutes

const ShortcutAvailability = (props: Route): React.JSX.Element => {
  const requestAvailabilityIntervalSeconds = useSession(({ state }) => state.config?.requestAvailabilityIntervalSeconds);
  const [{ data }, { fetch: getAvailabilityRequestCount }] = useGetAvailabilityRequestCount();

  const delay = convertSecondsToMilliseconds(requestAvailabilityIntervalSeconds || DEFAULT_REQUEST_AVAILABILITY_INTERVAL_SECONDS);
  useInterval(getAvailabilityRequestCount, delay, true);

  return <Shortcut {...props}>{!!data && <Badge bg="purple">{data}</Badge>}</Shortcut>;
};
Shortcut.Availability = ShortcutAvailability;

const convertSecondsToMilliseconds = (seconds: number): number => (seconds || 0) * 1000;

export default Shortcut;
