import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

interface TooltipProps {
  children: ReactNode;
  content?: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  className?: string;
  interactive?: boolean;
  when?: boolean;
}

// Memoize Tooltip component to prevent unnecessary re-renders
const Tooltip: React.FC<TooltipProps> = ({ children, content, className, placement = 'top', interactive = false, when = true }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState(undefined);
  const [calculatedPlacement, setCalculatedPlacement] = useState(placement);
  const [targetRect, setTargetRect] = useState<DOMRect | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Show tooltip on hover or focus
  const handleShowTooltip = useCallback((event: React.MouseEvent<HTMLDivElement> | React.FocusEvent<HTMLDivElement>) => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
    setVisible(true);
    setTargetRect(event.currentTarget.getBoundingClientRect());
  }, []);

  // Hide tooltip
  const handleHideTooltip = useCallback(() => {
    if (interactive) {
      // If interactive is true, delay hiding to allow hover over tooltip
      hideTimeoutRef.current = setTimeout(() => {
        setVisible(false);
        setCalculatedPlacement(placement);
      }, 100); // Delay to check if mouse entered tooltip
    } else {
      setVisible(false);
      setCalculatedPlacement(placement);
    }
  }, [placement, interactive]);

  // Handle mouse entering the tooltip
  const handleTooltipMouseEnter = useCallback(() => {
    if (hideTimeoutRef.current) {
      clearTimeout(hideTimeoutRef.current);
      hideTimeoutRef.current = null;
    }
  }, []);

  // Handle mouse leaving the tooltip
  const handleTooltipMouseLeave = useCallback(() => {
    setVisible(false);
    setCalculatedPlacement(placement);
  }, [placement]);

  useEffect(() => {
    if (visible && tooltipRef.current && targetRect) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      // Calculate available space
      const spaceAbove = targetRect.top;
      const spaceBelow = window.innerHeight - targetRect.bottom;
      const spaceLeft = targetRect.left;
      const spaceRight = window.innerWidth - targetRect.right;

      // Decide whether to flip the placement
      let newPlacement = placement;

      if (placement === 'top' && spaceAbove < tooltipRect.height + 10) {
        newPlacement = 'bottom';
      } else if (placement === 'bottom' && spaceBelow < tooltipRect.height + 10) {
        newPlacement = 'top';
      } else if (placement === 'left' && spaceLeft < tooltipRect.width + 10) {
        newPlacement = 'right';
      } else if (placement === 'right' && spaceRight < tooltipRect.width + 10) {
        newPlacement = 'left';
      }

      if (newPlacement !== calculatedPlacement) {
        setCalculatedPlacement(newPlacement);
      }

      // Calculate tooltipPosition based on 'newPlacement'
      let top = 0;
      let left = 0;

      if (newPlacement === 'top') {
        top = targetRect.top + window.scrollY - 10;
        left = targetRect.left + window.scrollX + targetRect.width / 2;
      } else if (newPlacement === 'bottom') {
        top = targetRect.bottom + window.scrollY + 10;
        left = targetRect.left + window.scrollX + targetRect.width / 2;
      } else if (newPlacement === 'left') {
        top = targetRect.top + window.scrollY + targetRect.height / 2;
        left = targetRect.left + window.scrollX - 10;
      } else if (newPlacement === 'right') {
        top = targetRect.top + window.scrollY + targetRect.height / 2;
        left = targetRect.right + window.scrollX + 10;
      }

      setTooltipPosition({ top, left });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, tooltipRef, targetRect, interactive]);

  // Decide whether to attach mouse events to the tooltip
  const tooltipMouseEvents = interactive
    ? {
        onMouseEnter: handleTooltipMouseEnter,
        onMouseLeave: handleTooltipMouseLeave,
      }
    : {};

  const tooltipContent = useMemo(
    () =>
      visible &&
      ReactDOM.createPortal(
        <div
          ref={tooltipRef}
          {...tooltipMouseEvents}
          style={{
            position: 'absolute',
            top: `${tooltipPosition?.top || 0}px`,
            left: `${tooltipPosition?.left || 0}px`,
            transform:
              calculatedPlacement === 'top'
                ? 'translate(-50%, -100%)'
                : calculatedPlacement === 'bottom'
                  ? 'translate(-50%, 0)'
                  : calculatedPlacement === 'left'
                    ? 'translate(-100%, -50%)'
                    : 'translate(0, -50%)',
            backgroundColor: '#fff',
            color: '#333',
            padding: '4px 8px',
            display: tooltipPosition ? 'inline-block' : 'none',
            borderRadius: '4px',
            whiteSpace: 'nowrap',
            fontSize: '10px',
            zIndex: 1000,
            willChange: 'transform, opacity', // for better rendering
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          {content}
          <div
            style={{
              position: 'absolute',
              width: 0,
              height: 0,
              ...(calculatedPlacement === 'top' && {
                bottom: '-5px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                borderTop: '5px solid #fff',
              }),
              ...(calculatedPlacement === 'bottom' && {
                top: '-5px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                borderBottom: '5px solid #fff',
              }),
              ...(calculatedPlacement === 'left' && {
                right: '-5px',
                top: '50%',
                transform: 'translateY(-50%)',
                borderTop: '5px solid transparent',
                borderBottom: '5px solid transparent',
                borderLeft: '5px solid #fff',
              }),
              ...(calculatedPlacement === 'right' && {
                left: '-5px',
                top: '50%',
                transform: 'translateY(-50%)',
                borderTop: '5px solid transparent',
                borderBottom: '5px solid transparent',
                borderRight: '5px solid #fff',
              }),
            }}
          ></div>
        </div>,
        document.body
      ),
    [visible, tooltipPosition, content, calculatedPlacement, tooltipMouseEvents]
  );

  // Decide whether to attach mouse events to the trigger element
  const triggerMouseEvents = interactive
    ? {
        onMouseEnter: handleShowTooltip,
        onMouseLeave: handleHideTooltip,
      }
    : {
        onMouseOver: handleShowTooltip,
        onMouseOut: handleHideTooltip,
      };

  return content && when ? (
    <div
      {...triggerMouseEvents}
      onFocus={handleShowTooltip}
      onBlur={handleHideTooltip}
      style={{ position: 'relative', display: 'inline-block' }}
      className={`Tooltip ${className ? className : ''}`}
    >
      {children}
      {tooltipContent}
    </div>
  ) : (
    <>{children}</>
  );
};

export default React.memo(Tooltip);
