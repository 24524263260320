import { Stop, UpdateResponse, UpdateStopBulkInput } from '@/models/gen/graphql';
import { Validation, createNotification, generateUpdateBulkPayload, handleError } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import { Toast } from '@/models';
import { UpdateStopBulkDocument } from '@/api/queries';
import graphApi from '@/api';

type UpdateStopBulkGraphApiResponse = GraphApiResponse<typeof UpdateStopBulkDocument>;

const title = 'Update Stops';

const updateStopValidator = new Validation.Validator({
  'id!': (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  actual: (val: any): Validation.Validity => ({ valid: !!val }),
  completed: (val: any): Validation.Validity => ({ valid: !!val }),
  locationId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  scheduled: (val: any): Validation.Validity => ({ valid: !!val }),
  tripId: (val: any): Validation.Validity => ({ valid: Validation.isValidUUID(val) }),
  type: (val: any): Validation.Validity => ({ valid: !!val }),
});

const updateStopBulkResponseSelector = (res: UpdateStopBulkGraphApiResponse): UpdateResponse[] =>
  !res.errors ? res?.updateStopBulk?.response || [] : undefined;

const [runUpdateStopBulk] = graphApi(UpdateStopBulkDocument, {
  onError: (res: UpdateStopBulkGraphApiResponse): void => handleError(res, { notification: { title } }),
});

export const updateStopBulk = async (stops: Stop[]): Promise<UpdateResponse[]> => {
  const validatedStops = stops?.map((stop: Stop): Stop => updateStopValidator.partial(stop));
  const input: UpdateStopBulkInput = {
    updates: generateUpdateBulkPayload(validatedStops),
  };
  const res = await runUpdateStopBulk({ input });

  const selected = updateStopBulkResponseSelector(res);
  if (selected) createNotification('Success', Toast.Type.SUCCESS, title);
  return selected;
};

export default updateStopBulk;
