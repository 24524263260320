import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

/* usePopState

  Most components init themselves on mount.
  But there are times we want to run some logic that won't run when soft reloading a page.
  Without having to do a full page reload, This hook is used to fire a call back when the user navigates
  using the browser forward or back button and we're going to the same page.

  By default the hook will trigger a full page reload if we navigate to the same page.

*/
const usePopState = (cb: (previousUrl: string) => void = () => window.location.reload()) => {
  const previousPathname = useRef(window.location.pathname);
  const previousSearch = useRef(window.location.search);
  // update callback reference without re-defining handlePopState
  const savedCallback = useRef(cb);

  // manage updating the call back
  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    const handlePopState = () => {
      const currentPathname = window.location.pathname;
      const currentSearch = window.location.search;

      // If the user navigated back/forward to the same page, fire callback
      if (currentPathname === previousPathname.current) savedCallback.current(previousPathname.current + previousSearch.current);

      // Update the previous pathname to the current one
      previousPathname.current = currentPathname;
      previousSearch.current = currentSearch;
    };

    // Add event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup listener when component unmounts
    return () => window.removeEventListener('popstate', handlePopState);
  }, []);

  // This component just manages the popstate event listener
  return;
};

export default usePopState;
