import { InitComponentStateType, createComponentState } from '@/state';
import { SortDirectionEnum, Trip } from '@/models/gen/graphql';

import { Datetime } from '@/utils/dates';

export enum TripRowActionEnum {
  UPDATE = 'UPDATE', // update rows but don't persist state
  UPDATE_AND_COMBINE = 'UPDATE_AND_COMBINE', // persist state
}

// this enum maps the sortable trips headers to the sortable fields in the TripTableSearchObject
export enum TripSortColumnEnum {
  type = 'type',
  latestScheduled = 'latestScheduled',
  latestScheduledUtc = 'latestScheduledUtc',
  actual = 'actual',
  airportCode = 'airportCode',
  servicerIataAirlineCode = 'servicerIataAirlineCode',
  flightNumber = 'flightNumber',
  pilots = 'pilots',
  attendants = 'attendants',
  driverId = 'driverId',
  vehicleId = 'vehicleId',
  puLocationId = 'puLocationId',
  doLocationId = 'doLocationId',
  payerProviderId = 'payerProviderId',
  rateAmount = 'rateAmount',
}

export type TripTableState = {
  sorting: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>;
  onSortOnly: (column: TripSortColumnEnum) => void;
  onSort: (column: TripSortColumnEnum) => void;
  trips: Map<string, Trip>;
  onSetTrips: (rows: Trip[]) => void;
  refetch: () => void;
  selected: Map<string, Trip>;
  onSelect: (rowId: string) => void;
  onSelectOnly: (rowId: string) => void;
  onSetRow: (update: Partial<Trip>, tripIds: string[], action?: TripRowActionEnum) => void;
  onDeleteRow: (tripIds: Set<string>, softDelete?: boolean) => void;
};

export const DEFAULT_TRIP_SORTING = [{ column: TripSortColumnEnum.latestScheduledUtc, direction: SortDirectionEnum.Asc }];
const getNextSortDirection = (current: SortDirectionEnum): SortDirectionEnum => {
  switch (current) {
    case null:
      return SortDirectionEnum.Asc;
    case SortDirectionEnum.Asc:
      return SortDirectionEnum.Desc;
    case SortDirectionEnum.Desc:
    default:
      return null;
  }
};
const initTripTableState: InitComponentStateType<TripTableState> = (set, get): TripTableState => {
  return {
    trips: new Map(),
    sorting: DEFAULT_TRIP_SORTING,
    onSortOnly: (column: TripSortColumnEnum): void => {
      set((current: TripTableState): TripTableState => {
        const found = current.sorting.find((c) => c.column === column);
        // sort single column
        if (!found) return { ...current, sorting: [{ column, direction: SortDirectionEnum.Asc }] };
        const next = getNextSortDirection(found.direction);
        // if no sorting is applied to column set sorting to default
        if (next === null) return { ...current, sorting: DEFAULT_TRIP_SORTING };
        return { ...current, sorting: [{ ...found, direction: next }] };
      });
    },
    onSort: (column: TripSortColumnEnum): void => {
      set((current: TripTableState): TripTableState => {
        const sorting = [...current.sorting];
        // sort multiple columns
        const index = sorting.findIndex((s) => s.column === column);
        // if not found add
        if (index === -1) {
          sorting.push({ column, direction: SortDirectionEnum.Asc });
          return { ...current, sorting };
        }
        const next = getNextSortDirection(sorting[index].direction);
        if (next !== null) {
          // update with next sort direction
          sorting[index] = { ...sorting[index], direction: next };
          return { ...current, sorting };
        }
        // remove from array if next is null
        sorting.splice(index, 1);
        if (sorting.length) return { ...current, sorting };

        return { ...current, sorting: DEFAULT_TRIP_SORTING };
      });
    },
    refetch: () => {
      console.log('default refetch called');
      return null;
    },
    selected: new Map(),
    onSelect: (rowId: string): void => {
      set((current: TripTableState): TripTableState => {
        const selected = new Map(current.selected);
        const found = selected.get(rowId);

        // add selection
        if (!found) selected.set(rowId, current.trips.get(rowId));
        else selected.delete(rowId); // remove selection

        // update state
        return { ...current, selected };
      });
    },
    onSelectOnly: (rowId: string): void => {
      set((current: TripTableState): TripTableState => {
        const selected = new Map();
        // add selection
        selected.set(rowId, current.trips.get(rowId));
        // update state
        return { ...current, selected };
      });
    },
    onSetRow: (update: Partial<Trip>, tripIds: string[], action: TripRowActionEnum = TripRowActionEnum.UPDATE): void => {
      set((current: TripTableState): TripTableState => {
        const combinedTripIds: string[] = (tripIds !== undefined ? tripIds : current.trips.get(update.id)?.[action]) || [];

        const trips = new Map(current?.trips);
        const idsToUpdate = new Set([update.id, ...combinedTripIds]);

        let updated = 0;
        // UPDATE_AND_COMBINE add combine key/property to update to persist through component lifecycle
        if (action === TripRowActionEnum.UPDATE_AND_COMBINE && tripIds !== undefined) update[action] = Array.from(idsToUpdate);
        //TODO: add un-combine blacklist keys

        // Iterate over rows only once
        for (const rowId of trips.keys()) {
          // Skip rows that are not in idsToUpdate
          if (!idsToUpdate.has(rowId)) continue;
          // Update the row
          const row = trips.get(rowId);
          const result = { ...row, ...update, id: rowId };
          trips.set(rowId, result);
          updated++;
          // Break the loop if all necessary updates have been made
          if (updated === idsToUpdate?.size) break;
        }

        return { ...current, trips };
      });
    },
    onDeleteRow: (tripIds: Set<string>, softDelete?: boolean): void => {
      set((current: TripTableState): TripTableState => {
        const trips = new Map(current.trips);
        let updated = 0;
        const deletedAt = softDelete ? new Datetime().toString() : null;
        for (const rowId of trips.keys()) {
          if (!tripIds.has(rowId)) continue;
          // if the trip is in all or deleted format, update the deletedAt property to now
          const row = trips.get(rowId);
          if (softDelete) trips.set(rowId, { ...row, deletedAt });
          else trips.delete(rowId); // otherwise remove the trip from state
          updated++;
          if (updated === tripIds.size) break;
        }
        return { ...current, selected: new Map(), trips };
      });
    },
    onSetTrips: (rows: Trip[]): void => {
      set((current) => {
        // clone trips
        const trips = new Map();
        // Directly update the existing Map with new rows
        rows.forEach((curr): void => {
          trips.set(curr.id, curr);
        });

        return {
          ...current,
          trips,
        };
      });
    },
  };
};
const useTripTableState = createComponentState(initTripTableState);

export default useTripTableState;
