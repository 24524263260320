import './styles.scss';

import {
  AcceptInputOption,
  ReportFilterEnum,
  ReportInput,
  ReportSeriesEnum,
  SortDirectionEnum,
  Trip,
  TripTableFormatEnum,
  TripTypeEnum,
} from '@/models/gen/graphql';
import { Badge, Collapse } from 'react-bootstrap';
import { Datetime, getResultFromState, handleError, onEnter, printScreen, saveFile } from '@/utils';
import Input, { InputWithIcon } from '@/components/Input';
import { TODAY, TODAY_EOD } from '@/constants';
import { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import useTripTableState, { TripSortColumnEnum } from '@/features/Trips/components/TripsTable/hook';

import AirlineIataDropdown from '@/components/AirlineDropdown';
import { AirportGroupDropdown } from '@/components/AirportDropdown';
import ClientDropdown from '@/components/ClientDropdown';
import DateRangePicker from '@/components/DateRangePicker';
import FormButton from '@/components/FormButton';
import { GetCompletionReasonsDocument } from '@/api/queries';
import HasPermission from '@/components/HasPermission';
import LocationQueryDropdown from '@/components/LocationDropdown';
import QueryFilters from '@/components/QueryFilters';
import RateValuesDropdown from '@/components/RateValuesDropdown';
import React from 'react';
import { TripTypeDropdownMulti } from '@/components/TripTypeDropdown';
import TripsTableFormatDropdown from '@/components/TripsTableFormatDropdown';
import { createComponentQueryState } from '@/state';
import { reportQueryAsCsv } from '@/api/services/reports/reportQuery';
import useDb from '@/hooks/useDb';
import useHotkeys from '@/hooks/useHotkeys';
import { useNavigate } from 'react-router-dom';
import usePopState from '@/hooks/usePopState';
import useTripSettings from '@/features/Trips/components/TripSettingsModal/hook';

export type TripsFiltersState = {
  from: string;
  to: string;
  airportCode: string[];
  payerProviderId: string;
  doLocationId: string[];
  puLocationId: string[];
  flightNumber: string;
  type: string[];
  servicerIataAirlineCode: string;
  search: string;
  rateAmount: string;
  format: TripTableFormatEnum;
};
export const initTripsFiltersState: TripsFiltersState = {
  servicerIataAirlineCode: '',
  airportCode: [],
  payerProviderId: '',
  doLocationId: [],
  flightNumber: '',
  from: TODAY,
  puLocationId: [],
  to: TODAY_EOD,
  type: [],
  search: '',
  rateAmount: '',
  format: TripTableFormatEnum.Current,
};

const useTripFilters = createComponentQueryState(initTripsFiltersState, {
  reviver: (key: string, value: any) => {
    switch (key) {
      case 'type': {
        const tripTypeEnumKeys = Object.values(TripTypeEnum);
        return (Array.isArray(value) ? value : [value]).filter((key) => tripTypeEnumKeys.includes(key));
      }
      case 'format':
        return Object.values(TripTableFormatEnum).includes(value) ? value : initTripsFiltersState.format;
      default: {
        return value;
      }
    }
  },
});

type TripFiltersProps = {
  onCreate?: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onSubmit: (filters: TripsFiltersState) => Promise<void>;
  onReset?: (filters: TripsFiltersState) => Promise<void>;
  onSearch?: (search: string) => void;
  sorting?: Array<{ column: TripSortColumnEnum; direction: SortDirectionEnum }>;
  loading?: boolean;
};
export type TripFiltersRefMethods = {
  quickFilter: (input: TripsFiltersState | ((current: TripsFiltersState) => TripsFiltersState)) => void;
};

const TripFilters = (
  { onCreate, onDelete, onEdit, onSubmit, onReset, onSearch, sorting, loading }: TripFiltersProps,
  ref: React.ForwardedRef<TripFiltersRefMethods>
): React.JSX.Element => {
  // state
  const state = useTripFilters(({ state }) => state);
  const setState = useTripFilters(({ setState }) => setState);
  const saveState = useTripFilters(({ saveState }) => saveState);
  const rehydrateState = useTripFilters(({ rehydrate }) => rehydrate);

  const setSettingsState = useTripSettings(({ setState }) => setState);

  const selected = useTripTableState(({ state }) => state.selected);

  const [lastQuery, setLastQuery] = useState(state);
  const [expanded, setExpanded] = useState(true);

  // Hotkeys
  useHotkeys('v4_trips_filters', {
    'shift+ctrl+s': {
      name: 'Focus Search Bar',
      description: 'Focuses the Search Bar',
      action: () => (document.querySelector('.TripFilters input[name="search"]') as HTMLElement)?.focus?.(),
    },
    'shift+ctrl+f': {
      name: 'Focus Flight Number',
      description: 'Focuses the Flight Number field',
      action: () => (document.querySelector('.TripFilters input[name="flightNumber"]') as HTMLElement)?.focus?.(),
    },
    'shift+enter': {
      name: 'Search Trips',
      description: 'Search Trips with the Current Filters',
      action: () => (document.querySelector('.TripFilters button[name="submitFilters"]') as HTMLElement)?.click?.(),
    },
    'shift+ctrl+r': {
      name: 'Reset Trips Search',
      description: 'Search Trips with the Default Filters',
      action: () => (document.querySelector('.TripFilters button[name="resetFilters"]') as HTMLElement)?.click?.(),
    },
  });

  const handleChange = useCallback(
    (name: string) =>
      (value: unknown): void =>
        setState((current) => ({ ...current, [name]: value })),
    [setState]
  );
  const navigate = useNavigate();

  const handleSubmit = useCallback((): void => {
    // get the latest state from closure to ensure it's always accurate
    const { state } = useTripFilters.getState();
    saveState();
    setLastQuery(state);
    onSubmit(state);
  }, [state, saveState, onSubmit]);

  // This is exposed via ref so the parent can utilize shortcut functionality
  const handleQuickFilter = useCallback(
    (input: TripsFiltersState | ((current: TripsFiltersState) => TripsFiltersState)): void => {
      const result = getResultFromState(input, state);
      setState(result);
      setLastQuery(result);
      saveState();
      onSubmit(result);
    },
    [state, saveState, onSubmit, setState]
  );

  const handleReset = useCallback((): void => {
    const from = new Datetime().startOf('day').toString();
    const to = new Datetime().endOf('day').toString();
    setState({ ...initTripsFiltersState, from, to });
    setLastQuery({ ...initTripsFiltersState, from, to });
    saveState();
    onReset({ ...initTripsFiltersState, from, to });
  }, [setState, saveState, onReset]);

  useImperativeHandle(ref, () => ({ quickFilter: handleQuickFilter }), [handleQuickFilter]);

  const onExpand = (): void => setExpanded((current: boolean): boolean => !current);
  const handleSearch = (): void => onSearch(state.search);

  const handlePopState = useCallback(() => rehydrateState().then(handleSubmit), [rehydrateState, handleSubmit]);
  usePopState(handlePopState);

  useEffect((): void => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const selectedForDelete = useMemo(
    (): Trip[] => Array.from(selected.values()).filter((trip: Trip): boolean => !trip.deletedAt),
    [selected]
  );

  const handleShowSettingsModal = (): void => setSettingsState((current) => ({ ...current, show: true }));

  const { data: getCompletionReasonsResponse } = useDb('CompletionReasons', GetCompletionReasonsDocument, {
    lazy: true,
    ttl: 360, // cache for 1 day
  });
  const completionReasons = useMemo(
    () => getCompletionReasonsResponse?.getFilters?.filters?.completionReasons || [],
    [getCompletionReasonsResponse]
  );

  const getCompletionTypes = useCallback(
    (format: TripTableFormatEnum): string[] => {
      const noShow = completionReasons.find((reason: AcceptInputOption): boolean => reason?.displayName === 'No Show')?.id;
      const taxiCalled = completionReasons
        ?.filter((reason: AcceptInputOption): boolean => ['Taxi SHG Paid', 'Taxi Crew Paid', 'Lyft'].includes(reason?.displayName))
        ?.map((reason: AcceptInputOption): string => reason?.id);
      switch (format) {
        case TripTableFormatEnum.NoShow:
          return noShow ? [noShow] : null;
        case TripTableFormatEnum.TaxiCalled:
          return taxiCalled;
        default:
          return null;
      }
    },
    [completionReasons]
  );

  const getCsv = useCallback(async (): Promise<void> => {
    try {
      const payload = formatReportInput(lastQuery, getCompletionTypes(lastQuery.format));
      const response = await reportQueryAsCsv('detailedReport')(payload);
      if (!response) return;
      const blob = new Blob([response], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      saveFile(url, `Trips-${new Datetime().dateInput}.csv`);
    } catch (err) {
      handleError(err, { notification: { title: 'Export To CSV' } });
    }
  }, [lastQuery, getCompletionTypes]);

  return (
    <QueryFilters className="TripFilters justify-content-between">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-2">
          <QueryFilters.Control>
            <DateRangePicker
              name="dates"
              isDirty={state.from !== lastQuery.from || state.to !== lastQuery.to}
              value={[state.from, state.to] as any}
              onChange={(event) => {
                const [from, to] = event.target.value?.split?.(' - ') || [null, null];
                handleChange('from')(from);
                handleChange('to')(to);
              }}
            />
          </QueryFilters.Control>
          <QueryFilters.Input>
            <AirportGroupDropdown
              name="airportCode"
              value={state.airportCode}
              onChange={(value: string[]) => {
                handleChange('airportCode')(value);
                return false;
              }}
              onEnter={handleSubmit}
              options={{ locale: { 'Select...': 'Airport' } }}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <TripsTableFormatDropdown
              name="format"
              placeholder={displayFormatPlaceholder(state.format)}
              value={state.format || initTripsFiltersState?.format}
              onChange={(val: string): void => {
                if (!val) return;
                handleChange('format')(val);
              }}
              onEnter={handleSubmit}
              options={{
                showClearButton: false,
              }}
            />
          </QueryFilters.Input>
          <QueryFilters.Input>
            <Input
              name="flightNumber"
              placeholder="Flight"
              value={state.flightNumber || ''}
              onChange={handleChange('flightNumber')}
              onKeyDown={onEnter((e: React.KeyboardEvent<HTMLInputElement>) => {
                handleChange('flightNumber')((e.target as HTMLInputElement).value);
                handleSubmit();
              })}
            />
          </QueryFilters.Input>
          <QueryFilters.Control>
            <FormButton variant="outline-secondary-subtle" onClick={onExpand}>
              {!expanded ? 'More' : 'Less'}
            </FormButton>
            <FormButton name="submitFilters" variant="success" className="border-white" disabled={loading} onClick={handleSubmit}>
              {(loading && <i className="fa fa-spinner fa-pulse" />) || 'Go'}
            </FormButton>
            <FormButton name="resetFilters" variant="outline-secondary-subtle" onClick={handleReset}>
              Reset
            </FormButton>
          </QueryFilters.Control>
          <QueryFilters.Control>
            <InputWithIcon
              icon={<i className="sv sv-magnifier fs-4" />}
              name="search"
              value={state.search || ''}
              onChange={handleChange('search')}
              onBlur={handleSearch}
              onKeyDown={onEnter(handleSearch)}
              placeholder="Search"
            />
          </QueryFilters.Control>
        </div>
        <Collapse in={expanded}>
          <div>
            <div className="d-flex gap-2">
              <QueryFilters.Input>
                <ClientDropdown
                  name="payerProviderId"
                  value={state.payerProviderId || initTripsFiltersState?.payerProviderId}
                  onChange={handleChange('payerProviderId')}
                  onEnter={handleSubmit}
                  options={{ locale: { 'Select...': 'Clients' } }}
                />
              </QueryFilters.Input>
              <HasPermission name="allowSearchTripsByRate">
                <QueryFilters.Input>
                  <RateValuesDropdown
                    name="rateAmount"
                    placeholder="Rate"
                    value={state.rateAmount}
                    onEnter={handleSubmit}
                    onChange={handleChange('rateAmount')}
                  />
                </QueryFilters.Input>
              </HasPermission>
              <QueryFilters.Input>
                <AirlineIataDropdown
                  onEnter={handleSubmit}
                  name="servicerIataAirlineCode"
                  value={state.servicerIataAirlineCode || initTripsFiltersState?.servicerIataAirlineCode}
                  onChange={handleChange('servicerIataAirlineCode')}
                />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <TripTypeDropdownMulti name="type" value={state.type} onEnter={handleSubmit} onChange={handleChange('type')} />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <LocationQueryDropdown
                  name="puLocationId"
                  value={state.puLocationId}
                  onChange={handleChange('puLocationId')}
                  onEnter={handleSubmit}
                  airports={state.airportCode?.length ? state?.airportCode : null}
                  options={{
                    showAirportPrefix: false,
                    locale: { 'Select...': 'Pickups' },
                  }}
                />
              </QueryFilters.Input>
              <QueryFilters.Input>
                <LocationQueryDropdown
                  name="doLocationId"
                  onEnter={handleSubmit}
                  value={state.doLocationId}
                  onChange={handleChange('doLocationId')}
                  airports={state.airportCode?.length ? state.airportCode : null}
                  options={{
                    showAirportPrefix: false,
                    locale: { 'Select...': 'Dropoffs' },
                  }}
                />
              </QueryFilters.Input>
            </div>
          </div>
        </Collapse>
      </div>
      <div className="d-flex justify-content-end gap-2">
        <QueryFilters.Control>
          <FormButton
            maxWidth={1560}
            icon={<i className="sv sv-plus-square {font-size:1.5rem;}" />}
            name="CREATE_TRIP"
            variant="outline-secondary-subtle"
            onClick={onCreate}
          >
            Create Trip
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            maxWidth={1560}
            icon={<i className="sv sv-download2 {font-size:1.5rem;}" />}
            variant="outline-secondary-subtle"
            onClick={(): void => navigate('/manifests?type=MANIFEST')}
          >
            Import
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            maxWidth={1560}
            icon={<i className="sv sv-layers {font-size:1.5rem;}" />}
            name="EDIT_TRIP"
            variant="outline-secondary-subtle"
            onClick={onEdit}
            disabled={!selected.size}
          >
            {selected.size > 1 ? 'Bulk Edit' : 'Edit'}
            {selected.size > 1 && (
              <Badge className="ms-2" pill bg="success">
                {selected.size}
              </Badge>
            )}
          </FormButton>
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            name="DELETE_TRIP"
            disabled={!selectedForDelete.length}
            onClick={onDelete}
            variant="outline-secondary-subtle"
            tooltip="Delete Trip(s)"
            icon={<i className="sv sv-trash2 {font-size:1.5rem;}" />}
          />
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            variant="outline-secondary-subtle"
            onClick={handleShowSettingsModal}
            icon={<i className="sv sv-cog {font-size:1.5rem;}" />}
            feedback="Filter Settings"
          />
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            variant="outline-secondary-subtle"
            onClick={printScreen}
            icon={<i className="sv sv-printer {font-size:1.5rem;}" />}
            feedback="Print"
          />
        </QueryFilters.Control>
        <QueryFilters.Control>
          <FormButton
            variant="outline-secondary-subtle"
            onClick={getCsv}
            icon={<i className="sv sv-excel {font-size:1.5rem;}" />}
            feedback="Export To CSV"
          />
        </QueryFilters.Control>
      </div>
    </QueryFilters>
  );
};

const formatReportInput = (
  {
    from,
    to,
    servicerIataAirlineCode,
    airportCode,
    payerProviderId,
    doLocationId,
    puLocationId,
    rateAmount,
    type,
    format,
  }: TripsFiltersState,
  completionTypes: string[]
): ReportInput => {
  const payload: ReportInput = { series: ReportSeriesEnum.SeriesDefault, startDatetime: from, endDatetime: to };
  const complexFilters = convertTripTableFormatEnumToComplexFilters(format);
  // conditionally apply fields from props
  if (servicerIataAirlineCode) payload.airlines = [servicerIataAirlineCode];
  if (airportCode) payload.airports = airportCode;
  if (payerProviderId) payload.clients = [payerProviderId];
  if (completionTypes) payload.completionTypes = completionTypes;
  if (complexFilters) payload.complexFilters = complexFilters;
  if (doLocationId) payload.doLocations = doLocationId;
  if (puLocationId) payload.puLocations = puLocationId;
  if (rateAmount === 'NO_RATE') payload.complexFilters = [...(payload.complexFilters || []), ReportFilterEnum.ZeroRate];
  else if (rateAmount) payload.rates = [rateAmount];
  if (type) payload.types = type;
  return payload;
};

const convertTripTableFormatEnumToComplexFilters = (format: TripTableFormatEnum): ReportFilterEnum[] => {
  switch (format) {
    case TripTableFormatEnum.Completed:
      return [ReportFilterEnum.HasCompletion];
    case TripTableFormatEnum.Unassigned:
      return [ReportFilterEnum.HasNoDriver];
    default:
      return null;
  }
};

const displayFormatPlaceholder = (format: TripTableFormatEnum): string => {
  switch (format) {
    case TripTableFormatEnum.United:
      return 'UA Cancels';
    case TripTableFormatEnum.Priority:
      return 'Priority';
    case TripTableFormatEnum.ZeroRates:
      return 'Zero Rates';
    default:
      return undefined;
  }
};

export default React.memo(React.forwardRef(TripFilters));
