import { UpdatePasswordsInput, UpdatePasswordsResponse } from '@/models/gen/graphql';
import { getCookies, logout } from '@/utils';

import { GraphApiResponse } from '@/api/core';
import Toast from '@/models/Toast';
import { UpdatePasswordsDocument } from '@/api/queries';
import { createNotification } from '@/utils/custom';
import graphApi from '@/api';
import useSession from '@/state/session';

type UpdatePasswordsGraphApiResponse = GraphApiResponse<typeof UpdatePasswordsDocument>;

const title = 'Update Passwords';

const updatePasswordsResponseSelector = (res: UpdatePasswordsGraphApiResponse): UpdatePasswordsResponse => res?.updatePasswords;

const [runUpdatePasswords] = graphApi(UpdatePasswordsDocument);

const updatePasswords = async (oldPassword: string, newPassword: string): Promise<UpdatePasswordsResponse> => {
  const userId = useSession.getState().state.user?.id || '';
  const { token } = getCookies('token');
  if (!userId) throw new Error('No userId found.');
  if (!token) logout();
  const input: UpdatePasswordsInput = {
    users: [{ newPassword, oldPassword, userId, confirmPassword: newPassword }],
  };
  const res = await runUpdatePasswords({ input });
  const data = updatePasswordsResponseSelector(res);
  if (data) createNotification('Success', Toast.Type.SUCCESS, title);
  return data;
};

export default updatePasswords;
