import Tooltip from '@/components/Tooltip';

const ActivityCell = ({ onClick }: { onClick: () => void }): React.JSX.Element => (
  <Tooltip content="Activity">
    <button onClick={onClick}>
      <i className={`sv sv-bubble-dots fs-5`} />
    </button>
  </Tooltip>
);

export default ActivityCell;
