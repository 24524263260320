import { ConnectionDetails, convertConnectionToDetails, handleError, mergeConnectionDetails, queryInput } from '../../../utils/custom';
import { FlagWithTrip, FlagWithTripConnection, FlagWithTripSearch, FlagWithTripConnectionInput } from '../../../models/gen/graphql';
import { SearchFlagDocument } from '@/api/queries';

import { GraphApiResponse } from '../../core';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { forEach } from 'cypress/types/lodash';

export type SearchFlagGraphApiResponse = GraphApiResponse<typeof SearchFlagDocument>;
export const searchFlagResponseSelector = (res: SearchFlagGraphApiResponse): ConnectionDetails<FlagWithTrip> =>
  convertConnectionToDetails(res?.searchFlags?.flagWithTripConnection as FlagWithTripConnection);

const [, runRefetchSearchFlag] = graphApi(SearchFlagDocument, {
  onError: (res: SearchFlagGraphApiResponse): void => handleError(res, { notification: { title: 'Search Flag' } }),
});

export const getFlagByTripId = async (tripId: string): Promise<FlagWithTrip[]> => {
  const input: FlagWithTripConnectionInput = {
    first: 1000,
    after: null,
    query: [{ tripId: queryInput(tripId) }],
  };

  const res = await runRefetchSearchFlag({ input });
  const conn = searchFlagResponseSelector(res);

  return conn?.rows || [];
};
export const useGetFlagForTrip = createGraphApiHook(getFlagByTripId);
