import { IngestImportResponse, RunIngestImportsInput } from '../../../models/gen/graphql';

import { GraphApiResponse } from '../../core';
import { RunIngestImportsDocument } from '../../queries';
import createGraphApiHook from '../../../hooks/createGraphApiHook';
import graphApi from '../..';
import { handleError } from '../../../utils';

type RunIngestImportsGraphApiResponse = GraphApiResponse<typeof RunIngestImportsDocument>;

const title = 'Ingest Imports';

const runIngestImportsResponseSelector = (res: RunIngestImportsGraphApiResponse): IngestImportResponse[] =>
  res?.runIngestImports?.ingests || [];

const [runRunIngestImports] = graphApi(RunIngestImportsDocument, {
  onError: (res: RunIngestImportsGraphApiResponse): void => handleError(res, { notification: { title } }),
});

const runIngestImports = async (importId: string, excludes: string[] = []): Promise<IngestImportResponse[]> => {
  const input: RunIngestImportsInput = { ingests: [{ importId, excludes }] };
  const res = await runRunIngestImports({ input });
  return runIngestImportsResponseSelector(res);
};

export const useRunIngestImports = createGraphApiHook(runIngestImports);

export default runIngestImports;
