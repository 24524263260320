import { DocumentNode, useLazyQuery } from '@apollo/client';
import HasPermission, { PermissionAuth } from '../components/HasPermission';
import { useCallback, useEffect, useState } from 'react';

import { INDEXED_DB_VERSION } from '../constants';
import Logger from '../utils/logs';
import { Validation } from '../utils/validations';
import { handleOnError } from '../components/ApolloRoot';
import useIndexedDb from './useIndexedDb';

const log = Logger.of('hooks/useDb');

/* useDb
  This is currently a work in progress. The goal is to have a fully-functional
  higher-order-function, in the form of a React Hook, that can be used in-place
  of Apollo's `useQuery`, and `useLazyQuery` hooks.

  The main reason for this is simply to have the ability to make changes to
  one file that have the potential to change the entire codebase's logic behind
  queries, instead of needing to make changes on countless files where Apollo's
  hooks are used.

  The currently used functionality that is working is the client-side caching
  by using the `useIndexedDb` hook. Although Apollo caches data already, this
  is a cache that we have more control over down to the `ttl` that is provided
  as well as the ability to decide to clear said cache at any time.
*/

// TODO: Add logic to cache paginated data.

interface UseDbOptions {
  selector?: (data: any) => any;
  lazy?: boolean;
  ttl?: number;
  variables?: { input?: any; [x: string]: any };
  fetchPolicy?: undefined; // we don't want to override fetchPolicy
  checkHasPermission?: keyof PermissionAuth;
  [x: string]: any;
}

const useDb = (key: string, query: DocumentNode, { selector = (data) => data, ...options }: UseDbOptions = {}): any => {
  options = { lazy: false, ttl: 4, ...(options || {}) };
  const [data, setData] = useState();
  const [getCachedData, setCachedData, clearCachedData] = useIndexedDb('graphql', 'cache', {}, INDEXED_DB_VERSION);
  const [getData, { called, loading }] = useLazyQuery(query, {
    ...options,
    onError: handleOnError,
    fetchPolicy: 'no-cache',
  });

  const refreshData = useCallback(async () => {
    if (key) await clearCachedData(key);
    const userHasPermission = options?.checkHasPermission ? HasPermission.check(options?.checkHasPermission) : undefined;
    if (userHasPermission === false) {
      log.warn(`User does not have permission: ${options?.checkHasPermission}`);
      return;
    }
    const { data: result } = await getData();
    const now = new Date();
    const ttl = now.setMinutes(now.getMinutes() + options.ttl); // we don't want to cache data for hours since it can change within minutes
    const parsedResult = selector(result);
    if (key && Validation.isTruthy(parsedResult)) setCachedData(key, { data: result, ttl });
    setData(result); // data should reflect what's in the cache
    return result;
    // eslint-disable-next-line
  }, [key]);
  const checkData = useCallback(async () => {
    if (!key) {
      refreshData();
    } else {
      const cache = await getCachedData(key);
      const cachedData = selector(cache?.data);
      if (Validation.isTruthy(cachedData)) {
        const { data: result, ttl } = cache || {};
        const now = new Date();
        if (now >= ttl || !result) {
          refreshData();
        } else {
          setData(result);
        }
      } else {
        refreshData();
      }
    }
    // eslint-disable-next-line
  }, [key]);

  useEffect((): void => {
    if (!query || options.lazy) return;
    checkData();
  }, [key, query, options.lazy, checkData]);

  if (options.lazy) return [checkData, { data, called, loading, refetch: refreshData }];
  return { data, called, loading, refetch: refreshData };
};

const useLazyDb = (key, query, options = {}) => {
  options = { lazy: true, ...options };
  return useDb(key, query, options);
};

useDb.lazy = useLazyDb;

export default useDb;
export { useLazyDb };
