import { Button, Col, Modal, Row } from 'react-bootstrap';

import ConfirmationButton from '@/components/ConfirmationButton';
import React from 'react';
import TippyWhen from '../TippyWhen';

type Props = {
  onHide: () => void;
  onReset: () => void;
  onUpdate: () => void;
};

const EditCompletionModalFooter = ({ onHide, onReset, onUpdate }: Props): JSX.Element => {
  return (
    <Modal.Footer className="d-block {text-transform:uppercase!;font-size:0.8rem!;py:0!;text-align:right!;line-height:0.9rem;}_.input-group-text,label">
      <Row xs={3} className="flex-nowrap text-center">
        <Col>
          <Button name="CANCEL" className="w-100" variant="secondary" onClick={onHide}>
            Cancel
          </Button>
        </Col>
        <Col>
          <ConfirmationButton
            name="RESET"
            className="w-100"
            variant="outline-dark"
            onConfirm={onReset}
            options={{ confirmation: { Body: { message: 'Are you sure you wish to undo this completion?' } } }}
          >
            Reset
          </ConfirmationButton>
        </Col>
        <Col>
          <TippyWhen isTrue={!onUpdate} options={{ content: 'No changes have been made, or fields are invalid.' }}>
            <div>
              <Button name="SUBMIT" className="w-100" variant="primary" onClick={onUpdate} disabled={!onUpdate}>
                Update
              </Button>
            </div>
          </TippyWhen>
        </Col>
      </Row>
    </Modal.Footer>
  );
};

export default EditCompletionModalFooter;
