// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PageSettings .form-switch {
  padding: 0.15em 3em;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  transform: scale(1.1);
}
.PageSettings .form-switch .form-check-input {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/features/Trips/components/TripSettingsModal/styles.scss"],"names":[],"mappings":"AACE;EACE,mBAAA;EACA,aAAA;EACA,WAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;AACI;EACE,UAAA;AACN","sourcesContent":[".PageSettings .form-switch {\n  padding: 0.15em 3em;\n  display: flex;\n  gap: 0.5rem;\n  align-items: center;\n  transform: scale(1.1);\n}\n.PageSettings .form-switch .form-check-input {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
