/* eslint-disable no-unused-vars */

import Logger, { LoggerFunctions } from './logs';
import { PageInfo, SearchTypeEnum, SortDirectionEnum, StringSearch } from '../models/gen/graphql';
import { camelCase, properCase, titleCase, unCamelCase } from './strings';
import { createSlice, current } from '@reduxjs/toolkit';
import { getProperty, stringify } from './objects';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { Toast } from '../models';
import { Validation } from './validations';
import { dedupe } from './arrays';
import { sleep } from './promises';
import { toast } from 'sonner';

/* bindMethods
  Add sub-methods to one main method so easily bundle them together.
*/
export const bindMethods = (main: Function, ...methods: Function[]): Function => {
  methods.forEach((method: Function): void => {
    const { name } = method;
    main[name] = method;
  });
  return main;
};
/* generateSlice
  Generate a Redux Slice with automatically created actions.
*/
export const generateSlice = (name: string, initialState?: any, customActions?: any): any => {
  if (!name) throw new Error('No name provided for slice generation.');
  initialState = initialState || {};
  const sliceObj = {
    name,
    initialState,
    reducers: { ...(customActions || {}) },
  };
  sliceObj.reducers.setState = (state: any, action: any): void => ({ ...(current(state) || initialState), ...(action?.payload || {}) });
  Object.keys(initialState).forEach((key: string): void => {
    const name = camelCase(`set ${key}`);
    sliceObj.reducers[name] =
      sliceObj.reducers[name] ||
      ((state: any, action: any): void => ({
        ...(current(state) || initialState),
        [key]: { ...(current(state) || initialState)[key], ...(action?.payload || {}) },
      }));
  });
  return createSlice(sliceObj);
};
/* wrappedMutation, wrapQuery, wrapLazyQuery
  A small higher-order function that returns a usable React Hook for
  wrapping Apollo's hooks with custom handlers/options/selectors.
*/
export const wrapMutation =
  (mutation: any, wrapper: (...a: any[]) => any): any =>
  (selector: any = (data: any): any => data): any => {
    const [func, { data: rawData, ...info }] = useMutation(mutation);
    const globalState = manageGlobalState();
    const wrappedFunc = async (...args: any[]): Promise<any> => {
      const res = await wrapper(func, globalState)(...args);
      const { data, errors = [] } = res || {};
      if (errors.length > 0)
        errors.forEach((err: any): void => {
          createNotification('Something went wrong.', Toast.Type.WARNING, 'Warning', 'If this problem persists, please contact support.');
          const error = new Error(err.message);
          console.warn(error);
        });
      return selector(data);
    };
    const data = selector(rawData);
    return [wrappedFunc, { data, ...info }];
  };
export const wrapQuery =
  (query: any, options: any): any =>
  (selector: any = (data: any): any => data): any => {
    const { data: rawData, ...info } = useQuery(query, options);
    if ((rawData?.errors || []).length > 0)
      rawData.errors.forEach((err: any): void => {
        createNotification('Something went wrong.', Toast.Type.WARNING, 'Warning', 'If this problem persists, please contact support.');
        const error: Error = new Error(err.message);
        console.warn(error);
      });
    const data: any = selector(rawData);
    return { data, ...info };
  };
export const wrapLazyQuery =
  (query: any, wrapper: (a?: any, b?: any) => any): any =>
  (selector: any = (data: any): any => data): any => {
    const [func, { data: rawData, ...info }] = useLazyQuery(query);
    const globalState: any = manageGlobalState();
    const wrappedFunc = async (...args: any[]): Promise<any> => {
      const res: any = await wrapper(func, globalState)(...args);
      const { data, errors = [] } = res || {};
      if (errors.length > 0)
        errors.forEach((err: any): void => {
          createNotification('Something went wrong.', Toast.Type.WARNING, 'Warning', 'If this problem persists, please contact support.');
          const error: Error = new Error(err.message);
          handleError(error);
        });
      return selector(data);
    };
    const data: any = selector(rawData);
    return [wrappedFunc, { data, ...info }];
  };
export const manageGlobalState = (): [any, (state: any) => void] => {
  const globalState: any = getLocalStorage('global');
  const setGlobalState: (state: any) => void = (value: any): void => setLocalStorage('global', getResultFromState(value, globalState));
  return [globalState, setGlobalState];
};
export const renderNotifications = (): boolean => window.dispatchEvent(new CustomEvent('renderNotifications'));
export const createNotification = (
  content: string,
  type: Toast.Type = Toast.Type.DEFAULT,
  title: string,
  info?: string,
  options?: Toast.Options & { action?: { label?: string; onClick: () => void }; duration?: number }
): void => {
  const toastOptions = {
    ...options,
    action: options?.action ? { label: 'Click Here', onClick: (): void => {}, ...(options?.action || {}) } : undefined,
    description: content,
  };
  if (options?.redirect) {
    toastOptions.action = {
      label: 'Click Here',
      onClick: (): void => {
        window.location.pathname = options?.redirect;
      },
    };
  }
  switch (type) {
    case Toast.Type.DANGER: {
      toast.error(title, { duration: 8000, ...toastOptions });
      return;
    }
    case Toast.Type.WARNING: {
      toast.warning(title, toastOptions);
      return;
    }
    case Toast.Type.SUCCESS: {
      toast.success(title, toastOptions);
      return;
    }
    case Toast.Type.INFO: {
      toast.info(title, toastOptions);
      return;
    }
    case Toast.Type.DEFAULT:
    default: {
      toast.message(title, toastOptions);
      return;
    }
  }
};
export const clearNotifications = (): void => {
  const [{ notifications = [] } = {}, setGlobalState] = manageGlobalState();
  if (!(notifications || []).length) return;
  setGlobalState((current: any): any => ({
    ...current,
    notifications: [],
  }));
  renderNotifications();
};
export const getLocalStorage = (key: string, defaultValue?: any): any => {
  let result: string = window.localStorage.getItem(key);
  try {
    result = JSON.parse(result);
  } catch (err) {
    console.warn(err.message || err);
  }
  return result || defaultValue;
};
export const setLocalStorage = (key: string, value: any): any => {
  let result = value;
  if (typeof result !== 'string') {
    try {
      result = JSON.stringify(result);
    } catch (err) {
      console.warn(err.message || err);
    }
  }
  window.localStorage.setItem(key, result);
  return value;
};
/* getResultFromState
  Using a payload, and the current state object, either return the payload, or run it if it's a function.
*/
export type FunctionOrType<T> = T extends () => any ? (current: ReturnType<T>) => ReturnType<T> : T;
export const getResultFromState = <T>(result: T | ((current: T) => T), state: T): T =>
  result instanceof Function ? result(state) : result;
type ValidationType = (obj: any) => any;
interface ValidationMethod extends ValidationType {
  keys: string[];
  validators: any;
  test: (input: any, keys: string[]) => any[];
}
export const validateObj = (queryKeys: any = {}, test?: (a: any, q: any) => any): ValidationMethod => {
  const fn: ValidationMethod = (obj: any = {}): any => {
    const result: any = {};
    Object.entries(obj)
      .filter(([key]: [string, any]): boolean => key && (Object.keys(queryKeys).includes(key) || Object.keys(queryKeys).includes('*')))
      .forEach(([key, value]: [string, any]): any => (result[key] = (queryKeys[key] || queryKeys['*'])(value)));
    Object.entries(queryKeys).forEach(
      ([key, validate]: [string, (a: any, b: any) => any]): any => (result[key] = validate(result[key], obj))
    );
    return result;
  };
  fn.validators = queryKeys;
  fn.keys = Object.keys(queryKeys);
  fn.test = (a: any): any => (test || ((_a?: any): any => ({})))(a, fn.keys);
  return fn;
};
export const parseStoredReduxState = (state: any): any => {
  const result = {};
  Object.entries(state).forEach(([key, val]: [string, any]): any => (result[key] = typeof val === 'string' ? JSON.parse(val) : val));
  return result;
};
const parseGQLErrorLevel = (level: string): string => {
  if (!level) return 'error';
  level = level.toLowerCase();
  if (level.includes('warn')) level = 'warn';
  return level;
};
export const handleGQLError = (log: LoggerFunctions, { error, title }): void => {
  const message = error?.message || error || '';
  const path = (error?.path || []).join('.');
  const extensions = error?.extensions || {};
  const code = extensions?.code || error?.code || '';
  const debug = extensions?.debug || error?.debug || '';
  const level = extensions?.level || error?.level || 'error';
  // This happens if GQL sends back an unexpected error e.g. "internal server error"
  // The backend needs to fix something most likely a panic.
  const debugMsg = `${debug} ${path?.length ? `"${path}"` : ''}`.trim();
  (log?.[parseGQLErrorLevel(level)] || log.error)('handleGQLError', `${title || ''} ${level.toUpperCase()} ${code}`, debugMsg).notify({
    info: code,
    title: title || `${titleCase(level)} ${code}`.trim(),
    message: `${properCase(message).replace(/\.?$/, '.')} (${path})` || 'Something went wrong.',
    moreDetails: debugMsg ? `Tech Support Note: ${debugMsg}` : undefined,
  });
};
export const handleNetworkError = (log: LoggerFunctions, { networkError, title }): void => {
  // Handle GQL Network Error and other HTTP Status Codes
  const statusCode = networkError?.statusCode;
  const message = networkError?.message || networkError;
  const statusMessage = [401, 403, undefined].includes(statusCode) ? '\nPlease log in & try again.' : '';
  log.error('handleNetworkError', `${title || ''} NETWORK ERROR ${statusCode}: ${message}`).notify({
    message: `${message} ${statusMessage}`.trim(),
    info: statusCode,
    title: title || 'Network Error',
  });
};
export type HandleErrorInput =
  | {
      errors?: readonly any[];
      networkError?: any;
      message?: string;
      error?: undefined;
      [x: string]: any;
    }
  | undefined;
export const handleError = (
  response: HandleErrorInput,
  options?: {
    notification?: {
      title?: string;
    };
  }
): void => {
  if (!response) return;
  const { networkError, message, errors = response.networkError?.result?.errors ?? [], ...restResponse } = response;
  const log = Logger.of('handleError');
  const title = titleCase(unCamelCase(restResponse?.graphQLErrors?.[0]?.path?.[0] || options?.notification?.title));
  const moreDetails = restResponse?.graphQLErrors?.[0]?.extensions?.debug;
  errors.forEach((err: any): void => handleGQLError(log, { error: err, title }));
  if (networkError) handleNetworkError(log, { networkError, title });
  if (message) log.error(message, restResponse).notify({ message, title, moreDetails });
};

export type QueryObject = {
  [key: string]: QueryInput;
};

export enum QueryInputType {
  OR = 'OR',
  DEFAULT = 'DEFAULT',
  ISNULL = 'ISNULL',
  ISNOTNULL = 'ISNOTNULL',
  RANGE = 'RANGE',
  INORNULL = 'INORNULL',
}
export type QueryInputSortOptions = {
  direction: SortDirectionEnum;
  index: number;
};
export type QueryInput = {
  sort: QueryInputSortOptions;
  type: QueryInputType;
  values: string[];
};
interface QueryInputExecute {
  (values: any, type?: QueryInputType, direction?: SortDirectionEnum, index?: number): any;
  date: (values: any, removeOffset?: boolean, direction?: SortDirectionEnum, index?: number) => any;
}
export const queryInput = ((): QueryInputExecute => {
  const fn = (
    values: any,
    type: QueryInputType = QueryInputType.OR,
    direction: SortDirectionEnum = null,
    index: number = null
  ): QueryInput => {
    const output = { type, values: null, sort: null };
    if (direction || index) output.sort = { direction, index };
    if (!!values || Validation.isNumber(values) || direction) {
      output.values = Array.isArray(values) ? values : Validation.isNumber(values) || Validation.isTruthy(values) ? [values] : [];
    } else if (!Validation.isTruthy(values)) return null;
    return output;
  };
  fn.date = (values: any, removeOffset: boolean = false, direction: SortDirectionEnum = null, index: number = null): QueryInput => {
    if (!values) return null;
    values = (Array.isArray(values) ? values : [values]).map((val: any, i: number): string => {
      if (!val) return '';
      let result = new Date(val);
      const offset = removeOffset ? 0 : result.getTimezoneOffset();
      if (i === 0) {
        result.setHours(0, 0, 0, 0);
      } else {
        result.setHours(23, 59, 59, 999);
      }
      result = new Date(result.getTime() - offset * 60000);
      return result.toISOString();
    });
    return queryInput(values, QueryInputType.RANGE, direction, index);
  };
  return fn;
})();

export const generateQuery = (filterKeys: any, ...filterObjects: any[]): any => {
  if (!Array.isArray(filterKeys)) throw new Error('Allowed filter keys must be provided as an Array.');
  return filterObjects
    .map((filters: any): any => {
      const result = {};
      Object.entries(filters)
        .filter(([key]: [string, any]): boolean => filterKeys.includes(key))
        .filter(([, options]: [string, any]): boolean => !!options)
        .forEach(([key, options = {}]: [string, any]): void => {
          const val = options?.values || options || [];
          const values = Array.isArray(val) ? val : [val];
          result[key] = {
            type: options?.type || 'OR',
            values,
            sort: null,
          };
          if (options?.sort?.direction || options?.sort?.index)
            result[key].sort = {
              direction: options?.sort?.direction || null,
              index: options?.sort?.index !== undefined ? options?.sort?.index : null,
            };
        });
      return result;
    })
    .filter((obj: any): boolean => !!Object.keys(obj).length);
};
export const updateInput = (input: any): any => {
  const result: any = {};
  Object.entries(input).forEach(([key, value]: [string, any]): void => {
    if (value !== undefined) {
      result[key] = Array.isArray(value) ? value : [value];
    }
  });
  return result;
};
export const mergeEdges =
  (main: string, sub: string): ((a: any, b: any) => any) =>
  (result: any, { fetchMoreResult }: any): any => ({
    ...(result || {}),
    ...(fetchMoreResult || {}),
    [main]: {
      ...(result?.[main] || {}),
      ...(fetchMoreResult?.[main] || {}),
      [sub]: {
        ...(result?.[main]?.[sub] || {}),
        ...(fetchMoreResult?.[main]?.[sub] || {}),
        edges: dedupe([...(result?.[main]?.[sub]?.edges || []), ...(fetchMoreResult?.[main]?.[sub]?.edges || [])], 'cursor'),
      },
    },
  });
export const remToPixels = (rem: number): number => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
export const pixelsToRem = (px: number): number => px / parseFloat(getComputedStyle(document.documentElement).fontSize);
export const getBulkValues = (data: any): any => {
  if (!data) return [];
  const result: any = {};
  Object.values(Array.isArray(data) ? data : [data]).forEach((record: any): void => {
    Object.entries(record || {})
      .filter(([key]: [string, any]): boolean => key !== '__typename')
      .forEach(([key, val]: [string, any]): void => {
        if (val && typeof val === 'object') {
          const nestedValues: any = getBulkValues(val);
          result[key] = result[key] || {};
          Object.entries(nestedValues).forEach(([nestedKey, nestedVal]: [string, any]): void => {
            if (Array.isArray(nestedVal) && Array.isArray(result[key][nestedKey])) {
              result[key][nestedKey] = Array.from(new Set([...(result[key][nestedKey] || []), ...nestedVal]));
            } else {
              result[key][nestedKey] = nestedVal;
            }
          });
        } else if (!result[key] || Array.isArray(result[key])) {
          result[key] = result[key] || [];
          result[key].push(val || '');
        } else {
          result[key] = val || {};
        }
      });
  });
  Object.entries(result).forEach(([key, vals]: [string, any]): void => {
    result[key] = Array.isArray(vals) ? Array.from(new Set(vals)) : vals;
  });
  return result;
};
export const diffKey = (key: string, records: any[]): any => {
  const result = [];
  records.forEach((record: any): number => result.push(record?.[key]));
  return Array.from(new Set(result)).filter((value: any): boolean => value !== undefined);
};
export const objHasValues = (obj: any = {}): boolean => Object.values(obj || {}).length > 0;
export const runQueryKeys = (queryKeys: string[] = [], input: any = {}): any => {
  const out = {};
  Object.entries(input)
    .filter(([key]: [string, any]): boolean => queryKeys.includes(key))
    .forEach(([key, val]: [string, any]): void => {
      out[key] = [val];
    });
  return out;
};
export const generateUpdateBulkPayload = (objs: any[], validate?: any): any => {
  const validatedObjects: any[] = objs.map(validate || ((o: any): any => o));
  const result: any[] = [];
  validatedObjects.forEach(({ id, ...obj }: any): void => {
    obj = updateInput(obj);
    const i = result.findIndex((res: any): boolean => stringify.compare(res.value, obj));
    if (i >= 0) {
      result[i].query[0].id[0].values.push(id);
    } else {
      const temp: StringSearch = {
        type: SearchTypeEnum.Or,
        values: [id],
      };
      result.push({
        query: [{ id: [temp] }],
        value: obj,
      });
    }
  });
  return result;
};
/**
 * @deprecated As of v1.0.0. migrating to GraphApi. We want to change how we're getting the connection details. The goal is to maintain type when we convert to our ConnectionDetails format. getConnectionDetails currently doesn't support our end goal.
 */
export const getConnectionDetails =
  (pathToConnection: string): ((data: Record<string, any>) => ConnectionDetails<any>) =>
  (data: Record<string, any>): ConnectionDetails<any> => {
    return convertConnectionToDetails(getProperty(pathToConnection, data));
  };
type Connection = {
  totalCount?: number;
  pageInfo?: PageInfo;
  edges?: Array<Edge>;
};
type Edge = {
  node?: Record<string, any>;
  cursor?: string;
};
export type ConnectionDetails<Data extends Record<string, any> = Record<string, any>> = {
  rows: Data[];
  hasNextPage: boolean;
  endCursor: string;
  totalCount: number;
};
export const convertConnectionToDetails = <ConnectionType extends Connection>(
  connection: ConnectionType
): ConnectionDetails<ConnectionType['edges'][0]['node']> => {
  const { edges, totalCount, pageInfo: { hasNextPage = false, endCursor = '0' } = {} } = connection || {};
  const rows = (edges || []).map(({ node }: ConnectionType['edges'][0]): ConnectionType['edges'][0]['node'] => node);
  return { rows, hasNextPage: hasNextPage || false, endCursor: endCursor || '0', totalCount: totalCount || 0 };
};
/**
 * This is function would be used as the merge function for createGraphApiHook.
 * It merges two connection details objects and returns the result.
 * This is what you need if you're lazy loading and your service function returns connection details.
 *
 * @param {Next} res - The next connection details object.
 * @param {Current} current - The current connection details object.
 * @return {Next} - The merged connection details object.
 */
export const mergeConnectionDetails = <Next extends ConnectionDetails, Current extends ConnectionDetails>(
  res: Next,
  current: Current
): Next => ({
  ...res,
  rows: [...(current?.rows || []), ...res.rows],
});
/**
 * @deprecated As of v1.0.0. migrating to GraphApi. We simplified how error handling works eliminating the use of this generic/custom error parser handler.
 */
export const customCreateHandler =
  (title: string, name?: string, success?: string): ((response: any) => void) =>
  (response: any): void => {
    handleError(response, { notification: { title } });
    if (!response?.errors?.length || !response?.networkError) return;
    if (response.data) return createNotification(success || 'Success', Toast.Type.SUCCESS, title, '');
  };
/**
 * @deprecated As of v1.0.0. migrating to GraphApi. We simplified how error handling works eliminating the use of this generic/custom error parser handler.
 */
export const customDeleteHandler =
  (title: string, entity: string, selector: string, success?: string): ((response: any) => void) =>
  (response: any): void => {
    handleError(response, { notification: { title } });
    if (!response?.errors?.length || !response?.networkError) return;
    return response?.data[selector]?.deleted
      ? createNotification(success || 'Success', Toast.Type.SUCCESS, title, '')
      : createNotification(`Failed to delete ${entity}`, Toast.Type.DANGER, title, '');
  };
/**
 * @deprecated As of v1.0.0. migrating to GraphApi. We simplified how error handling works eliminating the use of this generic/custom error parser handler.
 */
export const customUpdateHandler =
  (title: string, name?: string, success?: string): ((response: any) => void) =>
  (response: any): void => {
    handleError(response, { notification: { title } });
    if (!response?.errors?.length || !response?.networkError) return;
    if (response.data) return createNotification(success || 'Success', Toast.Type.SUCCESS, title, '');
  };
export const getOuterErrors = (res: any): any => {
  try {
    const result: any = [];
    if (typeof res === 'object') {
      if (res?.error || res?.errors) result.push(res?.error || res?.errors);
    } else if (Array.isArray(res)) {
      res.forEach((obj: any): number => result.push(obj?.error || obj?.errors));
    }
    return result.flat().filter((err: any): boolean => !!err);
  } catch (err) {
    console.error(err.message || err);
    return [];
  }
};
export const getInnerErrors = (res: any): any => {
  try {
    const result: any = [];
    if (typeof res === 'object') {
      const nested = Object.values(res || {})
        .filter((val: any): boolean => !!val)
        .map((val: any): any => (val?.error ? val.error : val?.errors ? val.errors : getInnerErrors(val || {})));
      nested.forEach((err: any): number => result.push(err));
    }
    return result.flat().filter((err: any): boolean => !!err);
  } catch (err) {
    console.error(err.message || err);
    return [];
  }
};
export const getErrors = (res: any): any => Array.from([...getOuterErrors(res), ...getInnerErrors(res)]).flat();
export const printScreen = (): void => {
  window.dispatchEvent(new Event('beforeprint'));
  sleep(0)
    .then(() => window.print())
    .finally(() => window.dispatchEvent(new Event('afterprint')));
};

export type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};
