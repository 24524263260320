// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatMessageBubble .ChatMessageBubble-Message {
  width: fit-content;
  padding: 1rem;
  border-radius: var(--bs-border-radius);
  white-space: pre-line;
}
.ChatMessageBubble.outgoing .ChatMessageBubble-Message {
  background-color: var(--bs-secondary-subtle);
  color: var(--bs-dark);
}
.ChatMessageBubble.incoming .ChatMessageBubble-Message {
  background-color: var(--bs-primary);
  color: var(--bs-light);
}

.ChatMessageBubble-Stats {
  font-size: 0.9rem;
  color: var(--bs-gray);
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWidget/ChatMessage/ChatMessageBubble/styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,aAAA;EACA,sCAAA;EACA,qBAAA;AAAJ;AAGI;EACE,4CAAA;EACA,qBAAA;AADN;AAKI;EACE,mCAAA;EACA,sBAAA;AAHN;;AAQA;EACE,iBAAA;EACA,qBAAA;AALF","sourcesContent":[".ChatMessageBubble .ChatMessageBubble-Message {\n  width: fit-content;\n  padding: 1rem;\n  border-radius: var(--bs-border-radius);\n  white-space: pre-line;\n}\n.ChatMessageBubble.outgoing .ChatMessageBubble-Message {\n  background-color: var(--bs-secondary-subtle);\n  color: var(--bs-dark);\n}\n.ChatMessageBubble.incoming .ChatMessageBubble-Message {\n  background-color: var(--bs-primary);\n  color: var(--bs-light);\n}\n\n.ChatMessageBubble-Stats {\n  font-size: 0.9rem;\n  color: var(--bs-gray);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
