import { FilterOption } from '@/models/gen/graphql';
import { GetKindsDocument } from '@/api/queries';
import { GraphApiResponse } from '@/api/core';
import createGraphApiHook from '@/hooks/createGraphApiHook';
import graphApi from '@/api';
import { handleError } from '@/utils';

type GetKindsGraphApiResponse = GraphApiResponse<typeof GetKindsDocument>;

const getKindsResponseSelector = (res: GetKindsGraphApiResponse): Array<FilterOption> =>
  res?.getFilters?.filters?.kind as Array<FilterOption>;

const [runGetKinds] = graphApi(GetKindsDocument, {
  onError: (res: GetKindsGraphApiResponse): void => handleError(res, { notification: { title: 'Get Kinds' } }),
});

export const getKinds = async (): Promise<Array<FilterOption>> => {
  const res = await runGetKinds();
  return getKindsResponseSelector(res);
};

export const useGetKinds = createGraphApiHook(getKinds);
