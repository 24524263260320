import './styles.scss';

import DynamicContent, { DynamicContentAs } from '@/components/DynamicContent';
import React, { useMemo } from 'react';

import ActivityView from '@/features/Trips/components/TripModal/views/activity';
import { Trip } from '@/models/gen/graphql';
import TripView from '@/features/Trips/components/TripModal/views/trip';
import { UseTripViewState } from '@/features/Trips/components/TripModal/views/trip/hook';
import { createComponentState } from '@/state';
import useSettings from '@/state/settings';

export type TripModalTab = 'trip' | 'flag' | 'communication' | 'completion' | 'activity' | 'fcr' | 'combine' | 'rate';
export type TripModalState = {
  as?: DynamicContentAs;
  show: boolean;
  data: Partial<Trip>;
  tab?: TripModalTab;
  selected?: Partial<Trip>[];
  onSubmit?: (state: any) => void;
};
export type TripModalViewProps = {
  as?: DynamicContentAs;
  data: Partial<Trip>;
  title?: string;
  mode?: 'editing' | 'creating';
  selected?: Partial<Trip>[];
  onSubmit?: (state: UseTripViewState, persist?: boolean) => void;
  onHide?: () => void;
};
const initState: TripModalState = { as: 'modal', show: false, data: null, tab: 'trip', selected: [] };
export const useTripModalState = createComponentState(initState);

const TripModal = (): JSX.Element => {
  const state = useTripModalState(({ state }) => state);
  const setState = useTripModalState(({ setState }) => setState);
  const { as, show, data, tab, onSubmit, ...rest } = state;
  const mode = data?.id ? 'editing' : 'creating';
  const [{ dynamicContentAs }] = useSettings(
    ({
      state: {
        trips: { dynamicContentAs = 'modal' },
      },
    }) => [{ dynamicContentAs } as { dynamicContentAs: DynamicContentAs }]
  );

  const onClickModalTab = (tabName: TripModalTab) => {
    setState((current) => ({ ...current, tab: tabName }));
  };

  const handleSubmit = (state: any, persist: boolean = false) => {
    onSubmit?.(state);
    if (!persist) onHide();
  };
  const onHide = (): void => {
    setState(initState);
  };

  const [View, title] = useMemo(() => {
    const DefaultView = () => <></>;
    switch (tab) {
      case 'trip':
        return [TripView, mode === 'editing' ? 'Edit Trip' : 'Create Trip'];
      case 'activity':
        return [ActivityView, 'Activity'];
      default:
        return [DefaultView];
    }
  }, [tab, mode]);

  return (
    <DynamicContent
      className={`TripModal p-3 TripModal-${dynamicContentAs || as}`}
      show={show}
      drawer
      modal
      as="modal"
      onChangeAs={(as: DynamicContentAs) => setState((current) => ({ ...current, as }))}
      userPreferenceCacheKey={'trips'}
      onHide={onHide}
      options={{
        modal: { props: { style: { top: 15 } } },
        drawer: {
          props: {
            width: 560,
            tabs:
              mode === 'editing'
                ? [
                    {
                      icon: <i className="sv sv-plane" />,
                      tooltip: 'Trip',
                      onClick: () => onClickModalTab('trip'),
                      active: tab === 'trip',
                    },
                    {
                      icon: <i className="sv sv-bubble-dots" />,
                      tooltip: 'Activity',
                      onClick: () => onClickModalTab('activity'),
                      active: tab === 'activity',
                    },
                    // {
                    //   icon: <i className="sv sv-flag-empty" />,
                    //   tooltip: 'Flags',
                    //   onClick: () => onClickModalTab('flag'),
                    //   active: tab === 'flag',
                    // },
                    // {
                    //   icon: <i className="sv sv-message2" />,
                    //   tooltip: 'Communications',
                    //   onClick: () => onClickModalTab('communication'),
                    //   active: tab === 'communication',
                    // },
                    // {
                    //   icon: <i className="sv sv-completion" />,
                    //   tooltip: 'Completion',
                    //   onClick: () => onClickModalTab('completion'),
                    //   active: tab === 'completion',
                    // },
                    // {
                    //   icon: <i className="sv sv-fcr" />,
                    //   tooltip: 'Complaints',
                    //   onClick: () => onClickModalTab('fcr'),
                    //   active: tab === 'fcr',
                    // },
                    // {
                    //   icon: <i className="sv sv-combine-icon" />,
                    //   tooltip: 'Combine',
                    //   onClick: () => onClickModalTab('combine'),
                    //   active: tab === 'combine',
                    // },
                    // {
                    //   icon: <i className="sv sv-bag-dollar" />,
                    //   tooltip: 'Rate',
                    //   onClick: () => onClickModalTab('rate'),
                    //   active: tab === 'rate',
                    // },
                  ]
                : undefined,
          },
        },
      }}
    >
      {data && (
        <View
          data={data}
          title={title}
          mode={data?.id ? 'editing' : 'creating'}
          onSubmit={handleSubmit}
          onHide={onHide}
          {...rest}
          as={dynamicContentAs || as}
        />
      )}
    </DynamicContent>
  );
};

export default TripModal;
